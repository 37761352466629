import React, { Component } from "react";
import { connect } from "react-redux";
import { compose } from "redux";
import { Link, withRouter } from "react-router-dom";
import uuid from "react-uuid";
import ApiService from "../../../Services/apiService";
import AlertService from "../../../Services/alertService";
import TranslationService from "../../../Services/translationService";
import Sections from "../../../Pages/CourseSections/sections";
import SettingsSvg from "../../../Components/Svg/settingsSvg";
import QuizSvg from "../../../Components/Svg/quizSvg";
import Auxiliary from "../../../hoc/auxiliary/auxiliary";
import InfoSvg from "../../../Components/Svg/infoSvg";
import ShowMoreText from "react-show-more-text";
import Parser from "html-react-parser";
import ActionButton from "../../../Components/ActionButton/actionButton";
import { setPureLayoutBackUrl } from "../../../Store/Actions/main";
import { setCurrentCourse } from "../../../Store/Actions/course";
import {
	addButtonSpinner,
	removeButtonSpinner,
	addPartialViewSpinner,
	removePartialViewSpinner,
} from "../../../Store/Actions/spinner";
import { TR_DELETE_MESSAGE_KEY } from "../../../Constants/translationKeys";
import {
	ERROR_KEY,
	GAMIFY_ASSIGNMENT_ENTITY_TYPE_ID,
	GAMIFY_COURSE_ENTITY_TYPE_ID,
	GAMIFY_QUIZ_ENTITY_TYPE_ID,
	SUCCESS_KEY,
	TR_DELETE_CONFIRM_MESSAGE_KEY,
	TR_NO,
	TR_YES,
} from "../../../Constants/mainKeys";
import {
	COURSES_KEY,
	SETTINGS_KEY,
	QUIZ_FORM_KEY,
	QUIZ_ATTEMPT_KEY,
	QUIZ_VIEW_KEY,
	COURSE_DASHBOARD_KEY,
	VIEW_KEY,
	PURE_KEY,
} from "../../../Constants/urlKeys";
import TopicForm from "../../../Pages/CourseTopics/topicForm";
import GamifyApiService from "../../../Services/gamifyApiService";
import GameSvg from "./../../../Components/Svg/gameSvg";
import GamificationItemsComponent from "../../Gamification/Components/Modals/GamificationItemsComponent";
import ParserService from "../../../Services/parserService";
import ParserComponent from "../../../Components/ParserComponent/ParserComponent";
import EditSvg from "./../../../Components/Svg/editSvg";
import DeleteSvg from "./../../../Components/Svg/deleteSvg";
import TestsSvg from "../../../Components/Svg/testsSvg";
import CustomTitle from "../../../Components/CustomTitle/customTitle";

class Curiculumn extends Component {
	constructor(props) {
		super(props);
		this.state = {
			courseId: +this.props.match.params.courseId,
			courseData: this.props.courseData,
			isInvalidSubmit: false,
			showAddSection: false,
			isEditMode: true,
			sections: this.props.courseData && this.props.courseData.sections,
			translationService: null,
			spinnerId: uuid(),
			courseQuizzes: [...this.props.courseQuizzes],
			assignmentCourse: [...this.props.assignmentCourse],
			isShowTopicForm: false,
			sectionId: null,
			topics: [],
			updateTopic: null,
			entityTypeId: GAMIFY_QUIZ_ENTITY_TYPE_ID,
			isLoading: false,
			gamifyData: null,
			selectedQuiz: null,
		};
	}

	componentDidMount() {
		const { courseId } = this.state;
		const { history, language } = this.props;
		if (history.location.hash) {
			history.push(`/${language}/${SETTINGS_KEY}/${COURSES_KEY}/${courseId}`);
		}
		this.setTranslations();
	}

	componentDidUpdate() {
		this.setTranslations();
		if (
			this.props.courseData.sections &&
			JSON.stringify(this.props.courseData.sections) !==
			JSON.stringify(this.state.sections)
		) {
			this.setState({ sections: this.props.courseData.sections });
		}
		if (this.state.courseQuizzes !== this.props.courseQuizzes) {
			this.setState({ courseQuizzes: this.props.courseQuizzes });
		}
		if (this.state.assignmentCourse !== this.props.assignmentCourse) {
			this.setState({ assignmentCourse: this.props.assignmentCourse });
		}
	}

	setTranslations = () => {
		if (!this.state.translationService && this.props.translations) {
			this.setState({
				translationService: new TranslationService(this.props.translations),
			});
		}
	};

	shouldComponentUpdate(nextProps, nextState) {
		if (
			nextProps.translations &&
			JSON.stringify(nextProps.translations) !==
			JSON.stringify(this.props.translations)
		) {
			this.setState({
				translationService: new TranslationService(nextProps.translations),
			});
		}
		if (
			JSON.stringify(this.props.courseData) !==
			JSON.stringify(nextProps.courseData)
		) {
			this.setState({ courseData: nextProps.courseData });
		}
		return true;
	}

	onChange = event => {
		this.setState(prevState => ({
			...prevState,
			form: { ...prevState.form, [event.target.name]: event.target.value },
		}));
	};

	showTopicForm = data => {
		if (!data) {
			return false;
		}
		this.setState({
			sectionId: data.sectionId,
			topics: data.topics,
			isShowTopicForm: data.isShow,
			updateTopic: data.updateTopic,
		});
	};

	deleteQuiz = quiz => {
		const { translationService, courseQuizzes } = this.state;
		AlertService.alertConfirm(
			`${translationService.translate(TR_DELETE_CONFIRM_MESSAGE_KEY)} ${quiz.name
			} ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		)
			.then(() => {
				ApiService.quizDelete(quiz.id)
					.then(() => {
						AlertService.alert(
							SUCCESS_KEY,
							translationService.translate(TR_DELETE_MESSAGE_KEY),
						);
						const index = courseQuizzes.indexOf(quiz);
						if (index > -1) {
							courseQuizzes.splice(index, 1);
						}
						this.setState({ courseQuizzes });
					})
					.catch(error => this.deleteFail(error));
			})
			.catch(error => this.deleteFail(error));
	};

	deleteAssigment = assignment => {
		const { translationService, assignmentCourse } = this.state;
		AlertService.alertConfirm(
			`${translationService.translate(TR_DELETE_CONFIRM_MESSAGE_KEY)} ${assignment.title
			} ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		)
			.then(() => {
				ApiService.deleteAssignment(assignment.id)
					.then(() => {
						AlertService.alert(
							SUCCESS_KEY,
							translationService.translate(TR_DELETE_MESSAGE_KEY),
						);
						let newAssigment = assignmentCourse.filter(
							el => el.id !== assignment.id,
						);
						this.props.changeAssigmentData(newAssigment);
						this.setState({ assignmentCourse: newAssigment });
					})
					.catch(error => this.deleteFail(error));
			})
			.catch(error => this.deleteFail(error));
	};

	coursePublish = () => {
		const { courseData, spinnerId, translationService } = this.state;
		if (!courseData) {
			return;
		}
		AlertService.alertConfirm(
			// `${translationService.translate("TR_COURSE_PUBLISH_CONFIRM_MSG").replace('{0}', courseData.name)}`,
			`${translationService.translate("TR_ARE_YOU_SURE_MODAL")}`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			this.props.addButtonSpinner(spinnerId);
			ApiService.coursePublishByOrgUser(courseData.id)
				.then(() => {
					this.props.removeButtonSpinner(spinnerId);
					const updatedCourseData = { ...courseData, published: true };
					this.setState({ courseData: updatedCourseData });
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_COURSE_SUCCESSFULLY_PUBLISHED"),
					);
				})
				.catch(error => this.deleteFail(error, spinnerId));
		});
	};

	courseUnPublish = () => {
		const { courseData, spinnerId, translationService } = this.state;
		if (!courseData) {
			return;
		}
		AlertService.alertConfirm(
			`${translationService
				.translate("TR_COURSE_UNPUBLISH_CONFIRM_MSG")
				.replace("{0}", courseData.name)}`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			this.props.addButtonSpinner(spinnerId);
			ApiService.courseUnPublishByOrgUser(courseData.id)
				.then(() => {
					this.props.removeButtonSpinner(spinnerId);
					const updatedCourseData = { ...courseData, published: false };
					this.setState({ courseData: updatedCourseData });
					AlertService.alert(SUCCESS_KEY, "Course successfully unpublished.");
				})
				.catch(error => this.deleteFail(error, spinnerId));
		});
	};

	topicSubmitted = () => {
		const { courseId } = this.state;
		if (!courseId) {
			return false;
		}
		this.setState({
			sectionId: null,
			isShowTopicForm: null,
			updateTopic: null,
		});
		this.props.getCourseByIdWithOrgUser(courseId);
	};

	getGamifyDataByEntity = (entityData, entityType) => {
		const { isLoading, courseId } = this.state;
		if (!entityData || !entityType || isLoading) {
			return false;
		}
		this.setState({ isLoading: true });
		const data = {
			entityId: entityData.id.toString(),
			entityTypeId: entityType,
			parentId: +courseId,
			parentTypeId: GAMIFY_COURSE_ENTITY_TYPE_ID,
		};
		GamifyApiService.getGamifyDataByEntity(data)
			.then(response => {
				if (response && response.data) {
					this.setState({
						gamifyData: response.data,
						selectedQuiz: entityData,
						entityTypeId: entityType,
					});
				}
				this.setState({ isLoading: false });
			})
			.catch(error => this.getFail(error));
	};

	closeGamifyMoadl = () => {
		this.setState({
			selectedQuiz: null,
			gamifyData: null,
			isLoading: false,
		});
	};

	cancel = () => {
		this.setState({ isShowTopicForm: false, updateTopic: null });
	};

	deleteFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && this.props.removeButtonSpinner(spinnerId);
	};

	getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && this.props.removePartialViewSpinner(spinnerId);
		this.setState({ isLoading: false });
	};

	goBack = () => {
		this.props.history.push(
			`/${this.props.language}/${SETTINGS_KEY}/${COURSES_KEY}`,
		);
	};

	render() {
		const {
			isEditMode,
			courseQuizzes,
			courseData,
			courseId,
			sections,
			translationService,
			spinnerId,
			isShowTopicForm,
			topics,
			sectionId,
			updateTopic,
			isLoading,
			gamifyData,
			selectedQuiz,
			assignmentCourse,
			entityTypeId,
		} = this.state;
		const { language, user } = this.props;

		return translationService && courseData ? (
			<Auxiliary>
				<GamificationItemsComponent
					entityData={selectedQuiz}
					gamifyData={gamifyData}
					entityTypeId={entityTypeId}
					cancel={this.closeGamifyMoadl}
				/>
				<div className="d-sm-flex d-block curriculum-title">
					<div className="w-100">
						<h2 className="section-title word-wrap-break-word">
							{courseData.name}
						</h2>
						<div className="custom-control custom-switch mt-2">
							<input
								type="checkbox"
								id="editModeSwitcher"
								className="custom-control-input"
								checked={isEditMode}
								onChange={event =>
									this.setState({ isEditMode: event.target.checked })
								}
							/>
							<label
								htmlFor="editModeSwitcher"
								className="custom-control-label">
								{translationService.translate("TR_EDIT_MODE")}
							</label>
						</div>
						<p className="mt-2">
							{" "}
							{translationService.translate("TR_CURICULUMN_INFO")}
						</p>
					</div>
				</div>
				<hr />
				{isShowTopicForm && sectionId && courseId && isEditMode ? (
					<div className="row  scale-up-hor-right">
						<div className="col-12">
							<div className="topic-form-container">
								<TopicForm
									show={true}
									courseId={courseId}
									sectionId={sectionId}
									topic={updateTopic}
									assignmentCourse={assignmentCourse}
									topics={topics}
									cancel={this.cancel}
									submitted={this.topicSubmitted}
								/>
							</div>
						</div>
					</div>
				) : (
					<Auxiliary>
						<div className="content-body pt-0">
							{courseData.description ? (
								<div className="mb-3">
									<ShowMoreText
										lines={2}
										more={translationService.translate("TR_SHOW_MORE")}
										less={translationService.translate("TR_LESS")}
										className="content-css word-break-break-word"
										anchorClass="my-anchor-css-class"
										expanded={false}
										truncatedEndingComponent={"... "}>
										<span className="course-description-block">
											<ParserComponent text={courseData.description} />
										</span>
									</ShowMoreText>
								</div>
							) : null}
							<Sections
								sections={sections ? sections : []}
								courseData={courseData}
								isEditMode={isEditMode}
								showTopicForm={this.showTopicForm}
								isBelongToUser={true}
								getCourseById={() =>
									this.props.getCourseByIdWithOrgUser(courseId)
								}
								changeSectionsData={this.props.changeSectionsData}
							/>
							{courseQuizzes && courseQuizzes.length ? (
								<Auxiliary>
										<hr className="mb-0" />
									<div className="accordion-wrapper">
										<div className="card mindalay-card">
											<div className="border-0 m-0 p-0">
												<div className="card-body p-0">
													<div className="d-flex align-items-center">
														<h5 className="mb-2 text-elipsis">
															{translationService.translate(
																"TR_COURSE_QUIZZES",
															)}
														</h5>
														<div className="info-title d-inline-block mb-1">
															<div>
																<InfoSvg />
																<span style={{ display: "none" }}>
																	{translationService.translate(
																		"TR_COURSE_QUIZ_INFO",
																	)}
																</span>
															</div>
														</div>
													</div>
													<div className="section-content-wrapper">
														{courseQuizzes.map(courseQuiz => {
															const quizAttemptViewPath = `/${language}/${PURE_KEY}/${QUIZ_ATTEMPT_KEY}/${VIEW_KEY}/${courseId}/${courseQuiz.id}`;
															const editPath = `/${language}/${COURSE_DASHBOARD_KEY}/${courseId}/${QUIZ_FORM_KEY}/${courseQuiz.id}`;
															const attachQuestionsPath = `/${language}/${COURSE_DASHBOARD_KEY}/${courseId}/${QUIZ_VIEW_KEY}/${courseQuiz.id}`;
															return (
																<div
																	key={courseQuiz.id}
																	className="section-content">
																	<div className="d-md-none d-block">
																		{isEditMode ? (
																			<div className="dropleft setting-dropdown">
																				<div
																					className="setting-dropdown-btn"
																					data-toggle="dropdown"
																					aria-haspopup="true"
																					aria-expanded="false">
																					<SettingsSvg />
																				</div>
																				<div className="dropdown-menu">
																					{user && user.isGamifyAvailable ? (
																						<Link
																							to="#"
																							className="dropdown-item"
																							onClick={event => {
																								event && event.stopPropagation();
																								this.getGamifyDataByEntity(
																									courseQuiz,
																									GAMIFY_QUIZ_ENTITY_TYPE_ID,
																								);
																							}}>
																							{isLoading ? (
																								<span className=" d-flex justify-content-between align-items-center">
																									{translationService.translate(
																										"TR_GAMIFY",
																									)}
																									<div
																										className="spinner-border text-success"
																										style={{
																											width: "15px",
																											height: "15px",
																											borderWidth: "1px",
																										}}
																									/>
																								</span>
																							) : (
																								<span className=" d-flex justify-content-between align-items-center">
																									{translationService.translate(
																										"TR_GAMIFY",
																									)}
																									<GameSvg
																										fill="#28a745"
																										width="20px"
																									/>
																								</span>
																							)}
																						</Link>
																					) : null}
																					<Link
																						to={editPath}
																						className={`dropdown-item ${isLoading
																							? "pointer-events-none"
																							: ""
																							}`}>
																						{translationService.translate(
																							"TR_EDIT",
																						)}
																					</Link>
																					<Link
																						to={attachQuestionsPath}
																						className={`dropdown-item ${isLoading
																							? "pointer-events-none"
																							: ""
																							}`}>
																						{translationService.translate(
																							"TR_ATTACH_QUESTIONS",
																						)}
																					</Link>
																					<Link
																						to="#"
																						className={`dropdown-item ${isLoading
																							? "pointer-events-none"
																							: ""
																							}`}
																						onClick={() =>
																							this.deleteQuiz(courseQuiz)
																						}>
																						{translationService.translate(
																							"TR_DELETE",
																						)}
																					</Link>
																				</div>
																			</div>
																		) : null}
																	</div>
																	<div className="d-flex justify-content-between algn-items-center w-100">
																		<div className="d-flex align-items-center">
																			<QuizSvg />
																			<Link
																				onClick={() =>
																					this.props.setPureLayoutBackUrl(
																						this.props.location.pathname,
																					)
																				}
																				to={quizAttemptViewPath}
																				className="overflow-hidden mr-4">
																				<p className="mb-0 text-elipsis">
																					{courseQuiz.name}
																				</p>
																			</Link>
																		</div>
																		<div className="d-md-block d-none">
																			{isEditMode ? (
																				<div className="d-flex">
																					<CustomTitle
																						title={translationService.translate(
																							"TR_EDIT",
																						)}>
																						<div className="course-wrapper--instructor ml-1">
																							<Link
																								to={editPath}
																								className="course-edit-button mt-0">
																								<EditSvg
																									className="mr-0"
																									style={{
																										width: "15px",
																										minWidth: "auto",
																									}}
																								/>
																							</Link>
																						</div>
																					</CustomTitle>
																					<CustomTitle
																						title={translationService.translate(
																							"TR_DELETE",
																						)}>
																						<div
																							className="course-wrapper--instructor ml-1 cursor-pointer"
																							onClick={event => {
																								this.deleteQuiz(courseQuiz);
																								event.stopPropagation();
																							}}>
																							<span className="course-delet-button mt-0">
																								<DeleteSvg
																									className="mr-0"
																									style={{
																										width: "15px",
																										minWidth: "auto",
																									}}
																								/>
																							</span>
																						</div>
																					</CustomTitle>

																					{user && user.isGamifyAvailable ? (
																						<CustomTitle
																							title={translationService.translate(
																								"TR_GAMIFY",
																							)}>
																							<div
																								className="course-wrapper--instructor ml-1"
																								onClick={event => {
																									this.getGamifyDataByEntity(
																										courseQuiz,
																										GAMIFY_QUIZ_ENTITY_TYPE_ID,
																									);
																									event &&
																										event.stopPropagation();
																								}}>
																								<span className="course-gamify-button mt-0">
																									{isLoading ? (
																										<div
																											className="spinner-border text-success"
																											style={{
																												width: "15px",
																												height: "15px",
																												borderWidth: "1px",
																											}}
																										/>
																									) : (
																										<GameSvg
																											fill="#28a745"
																											width="20px"
																										/>
																									)}
																								</span>
																							</div>
																						</CustomTitle>
																					) : null}
																					<CustomTitle
																						title={translationService.translate(
																							"TR_ATTACH_QUESTIONS",
																						)}>
																						<div className="course-wrapper--instructor ml-1 cursor-pointer">
																							<Link
																								to={attachQuestionsPath}
																								className="course-gamify-button mt-0">
																								<TestsSvg
																									className="mr-0"
																									style={{
																										width: "15px",
																										minWidth: "auto",
																									}}
																								/>
																							</Link>
																						</div>
																					</CustomTitle>
																				</div>
																			) : null}
																		</div>
																	</div>
																</div>
															);
														})}
													</div>
												</div>
											</div>
										</div>
									</div>
								</Auxiliary>
							) : null}
							{assignmentCourse && assignmentCourse.length ? (
								<Auxiliary>
									<hr className="mb-0" />
									<div className="accordion-wrapper">
										<div className="card mindalay-card">
											<div className="border-0 m-0 p-0">
												<div className="card-body p-0">
													<div className="d-flex align-items-center">
														<h5 className="mb-2 text-elipsis">
															{translationService.translate("TR_ASSIGNMENTS")}
														</h5>
														<div className="info-title d-inline-block mb-1">
															<div>
																<InfoSvg />
																<span style={{ display: "none" }}>
																	{translationService.translate(
																		"TR_COURSE_QUIZ_INFO",
																	)}
																</span>
															</div>
														</div>
													</div>
													<div className="section-content-wrapper">
														{assignmentCourse.map(item => {
															const editPath = `/${language}/${COURSE_DASHBOARD_KEY}/${courseId}/assignment-form/${item.id}`;

															return (
																<div key={item.id} className="section-content">
																	{isEditMode ? (
																		<div className="dropleft setting-dropdown d-md-none d-block">
																			<div
																				className="setting-dropdown-btn"
																				data-toggle="dropdown"
																				aria-haspopup="true"
																				aria-expanded="false">
																				<SettingsSvg />
																			</div>
																			<div className="dropdown-menu">
																				{user && user.isGamifyAvailable ? (
																					<Link
																						to="#"
																						className="dropdown-item"
																						onClick={event => {
																							event && event.stopPropagation();
																							this.getGamifyDataByEntity(
																								item,
																								GAMIFY_ASSIGNMENT_ENTITY_TYPE_ID,
																							);
																						}}>
																						{isLoading ? (
																							<span className=" d-flex justify-content-between align-items-center">
																								{translationService.translate(
																									"TR_GAMIFY",
																								)}
																								<div
																									className="spinner-border text-success"
																									style={{
																										width: "15px",
																										height: "15px",
																										borderWidth: "1px",
																									}}
																								/>
																							</span>
																						) : (
																							<span className=" d-flex justify-content-between align-items-center">
																								{translationService.translate(
																									"TR_GAMIFY",
																								)}
																								<GameSvg
																									fill="#28a745"
																									width="20px"
																								/>
																							</span>
																						)}
																					</Link>
																				) : null}
																				<Link
																					to={editPath}
																					className={`dropdown-item ${isLoading
																							? "pointer-events-none"
																							: ""
																						}`}>
																					{translationService.translate(
																						"TR_EDIT",
																					)}
																				</Link>
																				<Link
																					to="#"
																					className={`dropdown-item ${isLoading
																							? "pointer-events-none"
																							: ""
																						}`}
																					onClick={() =>
																						this.deleteAssigment(item)
																					}>
																					{translationService.translate(
																						"TR_DELETE",
																					)}
																				</Link>
																			</div>
																		</div>
																	) : null}

																	{/* {getFilPath()} */}
																	{/* <QuizSvg /> */}
																	{/* <Link
																		onClick={() =>
																			this.props.setPureLayoutBackUrl(
																				this.props.location.pathname,
																			)
																		}
																		to={quizAttemptViewPath}
																		className="overflow-hidden mr-4"> */}

																	<div className="d-flex justify-content-between align-items-center w-100">
																		<p className="mb-0 text-elipsis">
																			{item.title}
																		</p>
																		<div className="d-md-block d-none">
																			{isEditMode ? (
																				<div className="d-flex">
																					<CustomTitle
																						title={translationService.translate(
																							"TR_EDIT",
																						)}>
																						<div className="course-wrapper--instructor ml-1">
																							<Link
																								to={editPath}
																								className="course-edit-button mt-0">
																								<EditSvg
																									className="mr-0"
																									style={{
																										width: "15px",
																										minWidth: "auto",
																									}}
																								/>
																							</Link>
																						</div>
																					</CustomTitle>
																					<CustomTitle
																						title={translationService.translate(
																							"TR_DELETE",
																						)}>
																						<div
																							className="course-wrapper--instructor ml-1 cursor-pointer"
																							onClick={() => {
																								this.deleteAssigment(item);
																							}}>
																							<span className="course-delet-button mt-0">
																								<DeleteSvg
																									className="mr-0"
																									style={{
																										width: "15px",
																										minWidth: "auto",
																									}}
																								/>
																							</span>
																						</div>
																					</CustomTitle>

																					{user && user.isGamifyAvailable ? (
																						<CustomTitle
																							title={translationService.translate(
																								"TR_GAMIFY",
																							)}>
																							<div
																								className="course-wrapper--instructor ml-1 cursor-pointer"
																								onClick={() => {
																									this.getGamifyDataByEntity(
																										item,
																										GAMIFY_ASSIGNMENT_ENTITY_TYPE_ID,
																									);
																								}}>
																								<span className="course-gamify-button mt-0">
																									{isLoading ? (
																										<div
																											className="spinner-border text-success"
																											style={{
																												width: "15px",
																												height: "15px",
																												borderWidth: "1px",
																											}}
																										/>
																									) : (
																										<GameSvg
																											fill="#28a745"
																											width="20px"
																										/>
																									)}
																								</span>
																							</div>
																						</CustomTitle>
																					) : null}
																				</div>
																			) : null}
																		</div>
																	</div>
																</div>
															);
														})}
													</div>
												</div>
											</div>
										</div>
									</div>
								</Auxiliary>
							) : null}
							<div className="row">
								<div className="col-12">
									{" "}
									<hr />
								</div>
								<div className="col-12 text-right">
									{!courseData.published ? (
										<ActionButton
											spinnerId={spinnerId}
											clicked={this.coursePublish}
											type="button"
											className="mindalay--btn-default float-right"
											name={translationService.translate("TR_PUBLISH")}
										/>
									) : null}
								</div>
							</div>
						</div>
					</Auxiliary>
				)}
			</Auxiliary>
		) : null;
	}
}

const mapStateToProps = state => ({
	language: state.language.language,
	translations: state.translation.translations,
	buttonSpinners: state.spinner.buttonSpinners,
	courseData: state.course.currentCourse,
	user: state.user.user,
});

const mapDispatchToProps = {
	addButtonSpinner,
	removeButtonSpinner,
	addPartialViewSpinner,
	removePartialViewSpinner,
	setCurrentCourse,
	setPureLayoutBackUrl,
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter,
)(Curiculumn);
