import React, { useCallback, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import TranslationService from "../../../Services/translationService";
import Notification from "./SubComponents/notification";
import ProfileDropdown from "./SubComponents/profileDropdown";
import OrganizationUserDropdown from "./SubComponents/organizationUserDropdown";
import { showOrHideGeneralDashboardLeftMenu } from "../../../Store/Actions/generalLeftMenu";
import HelpSvg from "./../../../Components/Svg/helpSvg";
import Auxiliary from "../../../hoc/auxiliary/auxiliary";
import {
	getHelpData,
	setBackUrl,
	showOrHideHelpAlert,
} from "../../../Store/Actions/main";
import Loader from "react-loader-spinner";
import { HELP_KEY } from "../../../Constants/urlKeys";
import HelpService from "../../../Services/helpService";
import Dropdown from "./../../../Components/Dropdown/dropdown";
import {
	ADMIN_USER_TYPE,
	ERROR_KEY,
	LANGUAGE_KEY,
	NUMBER_KEY,
	STUDENT_USER_TYPE,
} from "../../../Constants/mainKeys";
import { setLanguage } from "../../../Store/Actions/language";
import MindalaySvg from "../../../Components/Svg/midalaySvg";
import GameSvg from "./../../../Components/Svg/gameSvg";
import MessagesSvg from './../../../Components/Svg/messagesSvg';
import GitSvg from './../../../Components/Svg/gitSvg';
import ApiService from "../../../Services/apiService";
import AlertService from "../../../Services/alertService";
import ModalComponentWithoutActionButton from "../../../Components/ModalComponent/modalComponentWithoutActionButton";
import { Collapse } from "react-bootstrap";

const OrganizationUserHeader = props => {
	const dispatch = useDispatch();
	const { translations } = useSelector(state => state.translation);
	const { unreadMessgesCount } = useSelector(state => state.signalR);
	const {
		isShowQuestionMarkSpinner,
		isShowB2BWebinarNotification,
		isShowHelpAlert,
	} = useSelector(state => state.main);
	const { showOrHideGeneralLeftMenu } = useSelector(
		state => state.generalLeftMenu,
	);
	const { language, languages } = useSelector(state => state.language);
	const { user } = useSelector(state => state.user);
	const [translationService, setTranslationService] = useState(null);
	const [showAccess, setShowAccess] = useState(false);
	const [access, setAccess] = useState([]);
	const [ids, setIds] = useState([]);


	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const showOrHideLeftMenu = () => {
		dispatch(showOrHideGeneralDashboardLeftMenu(!showOrHideGeneralLeftMenu));
	};

	const getHelpDataLocal = () => {
		if (isShowHelpAlert) {
			dispatch(showOrHideHelpAlert(false));
		} else {
			if (getHelpData(HelpService.setPageName(props.location.pathname))) {
				dispatch(getHelpData(HelpService.setPageName(props.location.pathname)));
				dispatch(showOrHideHelpAlert(true));
			}
		}
	};

	const changeLanguage = isocode => {
		if (isocode) {
			localStorage.setItem(LANGUAGE_KEY, isocode);
			setLanguageHandler(isocode);
			const newPath = props.location.pathname
				.toString()
				.replace(props.match.path.toString(), `/${isocode}`);
			props.history.push(newPath.toString());
		}
	};

	const setLanguageHandler = useCallback(language => {
		dispatch(setLanguage(language));
	}, []);

	const setUrl = () => {
		dispatch(setBackUrl(props.location.pathname));
	};

	const onChangeIds = id => {
		if (ids.includes(id)) {
			const newIds = ids.filter(el => el !== id);
			setIds(newIds);
		} else {
			setIds([...ids, id]);
		}
	};

	const open = () => {
		setShowAccess(true);
		ApiService.studentGetHierarchyOfUnits()
			.then(response => {
				if (response && response.data && response.data.length) {
					setAccess(response.data);
					setIds(collectIds(response.data))
				}
			})
			.catch(error => {
				error &&
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					);
			})
	};

	const collectIds = (tree) => {
		let ids = [];
		function traverse(node) {
			ids.push(node.id);
			if (node.children && node.children.length > 0) {
				node.children.forEach(child => traverse(child));
			}
		}

		tree.forEach(node => traverse(node));
		return ids;
	}

	const cancel = () => {
		setAccess([]);
		setShowAccess(false);
	};

	return translationService ? (
		<Auxiliary>
			<header className="relative-layout admin-dashboard-header header instructor-header-zindex white-background">
				<nav
					className={`navbar navbar-expand-md navbar-light navbar-shadow d-flex justify-content-between instructor-consultant-navbar ${props.location.pathname.includes("/help")
						? ""
						: "justify-content-md-end"
						} `}>
					{!showOrHideGeneralLeftMenu ? (
						<Link
							to={`/${language}`}
							className="navbar-brand instructor-consultant-header-logo d-none"></Link>
					) : null}
					{props.location.pathname.includes("/help") ? (
						<Link
							to={`/${language}`}
							className="burger-btn open-burger-menu nav-link m-0 p-0 d-block">
							<MindalaySvg height={30} />
						</Link>
					) : (
						<Link
							to="#"
							className="burger-btn open-burger-menu nav-link mb-2 d-flex align-items-center d-block d-md-none"
							onClick={showOrHideLeftMenu}>
							<span></span>
							{isShowB2BWebinarNotification ? (
								<div className="circle-impulse circle-impulse d-block d-md-none" />
							) : null}
							{/* <div
								className="ml-3 d-block d-md-none"
								style={{
									fontSize: 16,
									fontWeight: 400,
									transform: "none",
									color: "var(--mindalay--brand-color)",
								}}>
								(Beta)
							</div> */}
						</Link>
					)}
					<div>
						<ul className="navbar-nav align-items-left align-items-lg-center flex-wrap">
							<li className="mx-1">
								<Dropdown
									className="language-dropdown-dark"
									items={languages}
									title={language.toUpperCase()}
									selected={changeLanguage}
								/>
							</li>
							{props.location.pathname.includes("/help") ? null : (
								<li className="nav-item not-hover position-relative dropdown header-dropdown d-none d-md-block">
									<div className="nav-item nav-item-help-organization">
										<Link
											className="nav-link"
											to={`/${language}/${HELP_KEY}`}
											onClick={setUrl}>
											{translationService.translate("TR_HELP")}
										</Link>
									</div>
								</li>
							)}
							{user.isGamifyAvailable &&
								(user.userTypeId === STUDENT_USER_TYPE ||
									user.userTypeId === ADMIN_USER_TYPE ||
									(user.defaultUserTypeIds &&
										user.defaultUserTypeIds.length &&
										user.defaultUserTypeIds.includes(ADMIN_USER_TYPE))) ? (
								<Auxiliary>
									<li className="nav-item not-hover position-relative dropdown header-dropdown ">
										<Link
											to={`/${language}/gamification/dashboard`}
											className="nav-link">
											<GameSvg className="org-student-header-gamify-icon" />
										</Link>
									</li>
								</Auxiliary>
							) : null}
							{
								user.userTypeId === STUDENT_USER_TYPE ?
									<li className="nav-item not-hover"
										title={translationService.translate("TR_SHOW_MY_ACCESS")}
									>
										<Link to="#" className="nav-link" onClick={open}>
											<GitSvg />
										</Link>
									</li>
									: null
							}
							{isShowQuestionMarkSpinner ? (
								<li className="nav-item not-hover mr-2">
									<Loader type="Oval" color="#32064A" height={25} width={25} />
								</li>
							) : (
								<li className="nav-item not-hover"
									title={translationService.translate("TR_HELP")}
								>
									<Link to="#" className="nav-link" onClick={getHelpDataLocal}>
										<HelpSvg />
									</Link>
								</li>
							)}

							<li className="nav-item not-hover position-relative dropdown header-dropdown"
								title={translationService.translate("TR_NOTIFICATIONS")}
							>
								<Notification />
							</li>
							<li
								title={translationService.translate("TR_MESSAGES")}
								className="nav-item not-hover position-relative dropdown header-dropdown">
								{/* <Messages /> */}
								<Link
									to={user && user.userTypeId === ADMIN_USER_TYPE ? `/${language}/organization-admin/messages` : `/${language}/message`}
									className={`nav-link ${typeof unreadMessgesCount === NUMBER_KEY && unreadMessgesCount > 0 ? "has-number" : ""}`}
									rel={typeof unreadMessgesCount === NUMBER_KEY && unreadMessgesCount}
								>
									<MessagesSvg />
								</Link>
							</li>
							<li className="nav-item navbar-profile-container header-dropdown">
								{user && user.isOrganizationUser ? (
									<OrganizationUserDropdown />
								) : (
									<ProfileDropdown />
								)}
							</li>
						</ul>
					</div>
				</nav>
			</header>
			{showAccess &&
				!!access.length &&
				user &&
				user.userTypeId === STUDENT_USER_TYPE && (
					<ModalComponentWithoutActionButton
						title={translationService.translate("TR_ACCESS")}
						isLarge={true}
						// contentClassName="resource-modal"
						cancel={cancel}>
						<div className="row">
							<div className="col-12">
								<StudentAccess
									open={onChangeIds}
									ids={ids}
									data={access}
									translationService={translationService}
								/>
							</div>
						</div>
					</ModalComponentWithoutActionButton>
				)}
		</Auxiliary>
	) : null;
};

export default withRouter(OrganizationUserHeader);


const StudentAccess = ({
	data,
	translationService,
	ids = [],
	open,
	background = "white-background",
}) => {
	return (
		data &&
		!!data.length && (
			<div
				className="accordion-wrapper m-0"
				style={{
					position: "relative",
					zIndex: 0,
				}}>
				<div className="row" style={{ position: "relative", zIndex: 0 }}>
					{data.map((item, i) => {
						return (
							<div
								key={i}
								className={`col-12 ${item?.children ? "" : "mt-2"}`}
								style={{
									position: "relative",
									zIndex: `${0 - i}`,
								}}>
								<div className="card mindalay-card">
									<div
										className="card-header"
										style={{ zIndex: "0 !important" }}>
										<div
											className=""
											aria-controls={`section_${item.id}`}
											aria-expanded={ids.includes(item.id)}
											onClick={() => open(item.id)}>
											<div className="d-flex justify-content-between flex-wrap">
												{/* text-elipsis */}
												<h5 className=" fz-md w-auto text-initial d-flex flex-wrap">
													{item.name}

													{item.lecturerAttachmentOn && (
														<div className="ml-3">
															<p className="text-warning">
																<b
																	className="mr-2"
																	style={{
																		color: "var(--mindalay--brand-color)",
																	}}>
																	|
																</b>
																{translationService.translate("TR_SUBJECTS")}
															</p>
														</div>
													)}
													{item.divisionAttachmentOn && (
														<div className="ml-3">
															<p className="text-success">
																<b
																	className="mr-2"
																	style={{
																		color: "var(--mindalay--brand-color)",
																	}}>
																	|
																</b>
																{translationService.translate("TR_GROUPS")}
															</p>
														</div>
													)}
												</h5>
											</div>
										</div>
									</div>

									<Collapse in={ids.includes(item.id)}>
										<div
											id={`topic_${item.id}`}
											className={`${background === "white-background"
												? "white-background"
												: "light-blue-background"
												} content-block m-0`}
											style={{ padding: "0 0 0 1.25rem" }}>
											{!!item.description && (
												<div className="my-3 text-muted">
													{item.description}
												</div>
											)}
											{item.children && !!item.children.length && (
												<StudentAccess
													open={open}
													ids={ids}
													data={item.children}
													translationService={translationService}
													isOverflow={false}
													background={
														background === "white-background"
															? "light-blue-background"
															: "white-background"
													}
												/>
											)}
										</div>
									</Collapse>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		)
	);
};
