import React, { useEffect, useState } from "react";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import Footer from "../LayoutSections/Footer/footer";
import { withRouter } from "react-router";
import OrganizationUserHeader from "../LayoutSections/Header/organizationUserHeader";
import OrganizationUserLeftMenu from "../LayoutSections/LeftMenu/OrganizationUserLeftMenu";
import OrganizationUserRoutes from "./../../Routing/organizationUserRoutes";
import { useSelector } from "react-redux";
import StudentRoute from "../../Routing/studentRoute";
import {
	CURATOR_USER_TYPE,
	ERROR_KEY,
	LECTURER_USER_TYPE,
	STUDENT_USER_TYPE,
} from "../../Constants/mainKeys";
import LecturerRoute from "../../Routing/lecturerRoute";
import CuratorRoute from "../../Routing/curatorRoute";
import MarqueeComponent from "../../Components/Marquee/MarqueeComponent";
import ApiService from "../../Services/apiService";
import AlertService from "../../Services/alertService";

const OrganizationUserLayout = props => {
	const { role, user } = useSelector(state => state.user);
	const [marqueeText, setMarqueeText] = useState("");

	useEffect(() => {
		if (user && user.userTypeId === STUDENT_USER_TYPE) {
			studentGetHierarchyOfUnits();
		}
	}, [user])

	const studentGetHierarchyOfUnits = () => {
		ApiService.studentGetHierarchyOfUnits()
			.then(response => {
				if (response && response.data && Array.isArray(response.data) && response.data.length) {
					const marqueeText = getNamesForMarquee(response.data);
					setMarqueeText(marqueeText);
				} else {
					setMarqueeText("");
				}
			})
			.catch(error => {
				error &&
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					);
				setMarqueeText("");
			});
	}

	const getNamesForMarquee = (tree) => {
		if (!Array.isArray(tree) || !tree.length) {
			return "";
		}

		let result = [];
		const traverse = (node) => {
			if (!node || typeof node.name !== 'string' || node.lecturerAttachmentOn) return;
			result.push(`<span>${node.name}</span>`);
			if (Array.isArray(node.children) && node.children.length > 0) {
				traverse(node.children[0]);
			}
		};
		traverse(tree[0]);
		return result.join(' <span class="arrow mx-3"><i style="font-size: 13px;" class="fa-solid fa-arrow-right"></i></span> ');
	};

	if (
		user &&
		user.defaultUserTypeIds &&
		user.defaultUserTypeIds.includes(user.userTypeId)
	) {
		return (
			<Auxiliary>
				<OrganizationUserHeader />
				{props.location.pathname.includes("/help") ? null : (
					<OrganizationUserLeftMenu />
				)}
				<section
					className={`${props.location.pathname.includes("/help")
						? ""
						: "instructior-dashboard-body--large"
						} `}>
					<div className="top-section wrapper pb-5">
						<OrganizationUserRoutes />
					</div>
					<Footer />
				</section>
			</Auxiliary>
		);
	}

	if (user && user.userTypeId === STUDENT_USER_TYPE) {
		return (
			<Auxiliary>
				<OrganizationUserHeader />
				{props.location.pathname.includes("/help") ? null : (
					<OrganizationUserLeftMenu />
				)}
				<section
					className={`${props.location.pathname.includes("/help")
						? ""
						: "instructior-dashboard-body--large"
						} `}>
					<div className="top-section wrapper pb-5">
						{
							marqueeText ?
								<MarqueeComponent text={marqueeText} />
								: null
						}
						<StudentRoute />
					</div>
					<Footer />
				</section>
			</Auxiliary>
		);
	}

	if (user && user.userTypeId === LECTURER_USER_TYPE) {
		return (
			<Auxiliary>
				<OrganizationUserHeader />
				{props.location.pathname.includes("/help") ? null : (
					<OrganizationUserLeftMenu />
				)}
				<section
					className={`${props.location.pathname.includes("/help")
						? ""
						: "instructior-dashboard-body--large"
						} `}>
					<div className="top-section wrapper pb-5">
						<LecturerRoute />
					</div>
					<Footer />
				</section>
			</Auxiliary>
		);
	}

	if (user && user.userTypeId === CURATOR_USER_TYPE) {
		return (
			<Auxiliary>
				<OrganizationUserHeader />
				{props.location.pathname.includes("/help") ? null : (
					<OrganizationUserLeftMenu />
				)}
				<section
					className={`${props.location.pathname.includes("/help")
						? ""
						: "instructior-dashboard-body--large"
						} `}>
					<div className="top-section wrapper pb-5">
						<CuratorRoute />
					</div>
					<Footer />
				</section>
			</Auxiliary>
		);
	}
	return (
		<Auxiliary>
			<OrganizationUserHeader />
			{props.location.pathname.includes("/help") ? null : (
				<OrganizationUserLeftMenu />
			)}
			<section
				className={`${props.location.pathname.includes("/help")
					? ""
					: "instructior-dashboard-body--large"
					} `}>
				<div className="top-section wrapper pb-5">
					<OrganizationUserRoutes />
				</div>
				<Footer />
			</section>
		</Auxiliary>
	);
};

export default withRouter(OrganizationUserLayout);
