import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import uuid from "react-uuid";
import TranslationService from "../../Services/translationService";
import ArrowBackSvg from "../../Components/Svg/arrowBackSvg";
import ApiService from "../../Services/apiService";
import AlertService from "../../Services/alertService";
import { ERROR_KEY } from "../../Constants/mainKeys";
import {
	addPartialViewSpinner,
	removePartialViewSpinner,
} from "../../Store/Actions/spinner";
import filePng from "./../../assets/icons/file.png";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import NoDataImage from "../../assets/images/illustrations/nodata.svg";

const spinnerId = uuid();

const LectureAssignments = props => {

	const dispatch = useDispatch();
	const { studentId, courseId } = props.match.params;
	const { language } = useSelector(state => state.language);
	const { user } = useSelector(state => state.user);
	const { translations } = useSelector(state => state.translation);
	const [translationService, setTranslationService] = useState(null);
	const [assignments, setAssignments] = useState([]);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);
	useEffect(() => {
		getLecturerAssignments(courseId);
	}, [courseId]);

	const getLecturerAssignments = courseId => {
		if (!courseId) return;
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getLecturerAssignments(courseId)
			.then(response => {
				setAssignments(response.data);
			})
			.catch(error => {
				error &&
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					);
			})
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};
	const getFilPath = item => {
		if (item.files && item.files.length) {
			const imgpath = item.files.find(el => el.mimeType.includes("image"));
			if (imgpath) {
				return <img src={imgpath.filePath} alt="/" />;
			} else return <img src={filePng} alt="/" />;
		} else {
			return null;
		}
	};

	return translationService ? (
		<div className="background content">
			<h2 className="content-title">
				<Link
					to={`/${language}/student/courses/${studentId}`}
					title={translationService.translate("TR_BACK")}>
					<ArrowBackSvg />
				</Link>
				{translationService.translate("TR_STUDENT_ASSIGNMENTS")}
			</h2>
			{assignments && assignments.length ? (
				<Auxiliary>
					<hr />
					<div className="content-body pt-0">
						<div className="row">
							<div className="col-12">
								<div className="mt-4">
									<div className="course-pass-media-file-container">
										<div className="row">
											{assignments.map(item => (
												<div key={item.id} className="col-lg-4 col-12 mt-3">
													<div className="course-pass-media-file-wrapper box-shadow-4 cursor-default h-100">
														<div className="d-flex align-items-center">
															{/* {getFilPath(item)} */}
															<div className="ml-3 overflow-hidden">
																<h4 className="my-2">{item.title}</h4>
																{/* <p title={item.description}>
																	{item.description}
																</p> */}
															</div>
														</div>
														<hr />
														<div className="mt-3">
															<Link
																to={`/${language}/student/courses/${studentId}/${courseId}/assignment/${item.id}`}
																className="mindalay--btn-default w-100 mindalay--btn-link">
																{/* Go to assignment */}
																{translationService.translate("TR_VIEW")}
															</Link>
														</div>
													</div>
												</div>
											))}
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Auxiliary>
			) : (
				<div className="no-data-container">
					<div className="no-data-wrapper">
						<p>{translationService.translate("TR_NO_DATA")}</p>
						<img src={NoDataImage} alt="/" />
					</div>
				</div>
			)}
		</div>
	) : null;
};

export default withRouter(LectureAssignments);
