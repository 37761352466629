import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import uuid from "react-uuid";
import {
	ERROR_KEY,
	SUCCESS_KEY,
	TR_NO,
	TR_YES,
	VALID_IMAGE_TYPES_KEY,
	WARNING_KEY,
} from "../../Constants/mainKeys";
import AlertService from "../../Services/alertService";
import TranslationService from "../../Services/translationService";
import {
	addModalSpinner,
	addPartialViewSpinner,
	removeModalSpinner,
	removePartialViewSpinner,
} from "../../Store/Actions/spinner";
import ApiService from "./../../Services/apiService";
import NoDataImage from "../../assets/images/illustrations/nodata.svg";
import moment from "moment";
import MainService from "../../Services/mainService";
import ModalComponent from "./../../Components/ModalComponent/modalComponent";
import InputCheckBox from "../../Components/Inputs/inputCheckBox";
import UploadButton from "./../../Components/UploadButton/uploadButton";
import CloseSvg from "../../Components/Svg/closeSvg";
import ModalComponentWithoutActionButton from "../../Components/ModalComponent/modalComponentWithoutActionButton";
import GeneratePDF from "./Components/generatePDF";
import { PDFViewer } from "@react-pdf/renderer";
import _InfoSvg from "./../../Components/Svg/_infoSvg";
import PageInfoBlock from "../../Components/PageInfoBlock/PageInfoBlock";

const Paymentpackagehistory = () => {
	const dispatch = useDispatch();
	const mainService = new MainService();
	const translations = useSelector(state => state.translation.translations);
	const buttonSpinners = useSelector(state => state.spinner.buttonSpinners);
	const [translationService, setTranslationService] = useState(null);
	const [paymentPackages, setPaymentPackages] = useState([]);
	const [isShowUpdateModal, setIsShowUpdateModal] = useState(false);
	const [isShowUploadModal, setIsShowUploadModal] = useState(false);
	const [isShowInfoModal, setIsShowInfoModal] = useState(false);
	const [isShowPDF, setIsShowPDF] = useState(true);
	const [PDFData, setPDFData] = useState(null);
	const [image, setImage] = useState(null);
	const [uploadedImage, setUploadedImage] = useState(null);
	const [currentPackage, setCurrentPackage] = useState(null);
	const [recurringPayment, setRecurringPayment] = useState(false);
	const [addButtonIsDisabled, setAddButtonIsDisabled] = useState(false);
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(false);
	const [invoice, setInvoice] = useState([]);
	const [isShowValidImageErrorMsg, setIsShowValidImageErrorMsg] =
		useState(false);

	useEffect(() => {
		getPaymentPackageHistory();
	}, []);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const getPaymentPackageHistory = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getPaymentPackageHistory()
			.then(response => {
				if (response.data?.responseModels) {
					setPaymentPackages(response.data.responseModels);
				}
				if (response.data && response.data.invoice) {
					setInvoice(response.data.invoice);
				}
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const showOrHideUpdateModal = currentPackage => {
		setCurrentPackage(currentPackage);
		setIsShowUpdateModal(true);
		setRecurringPayment(currentPackage.recurringPayment);
	};

	const showOrHideUploadModal = currentPackage => {
		setCurrentPackage(currentPackage);
		setIsShowUploadModal(true);
	};

	const showOrHideInfoModal = currentPackage => {
		setCurrentPackage(currentPackage);
		setIsShowInfoModal(true);
	};

	const updatePackage = currentPackage => {
		if (currentPackage.recurringPayment === recurringPayment) {
			cancel();
		} else {
			const spinnerId = uuid();
			dispatch(addModalSpinner(spinnerId));
			setAddButtonIsDisabled(true);
			ApiService.updatePaymentPackage(currentPackage.id)
				.then(() => {
					const paymentPackagesCopy = [...paymentPackages];
					paymentPackagesCopy.filter(paymentpackage => {
						if (paymentpackage.isActive) {
							paymentpackage.recurringPayment =
								!currentPackage.recurringPayment;
						}
					});
					setAddButtonIsDisabled(false);
					setPaymentPackages(paymentPackagesCopy);
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_PACKAGE_SUCCESSFULLY_UPDATED"),
					);
					dispatch(removeModalSpinner(spinnerId));
					cancel();
				})
				.catch(error => getFail(error, spinnerId));
		}
	};

	const removeImg = () => {
		setImage(null);
		setUploadedImage(null);
	};

	const uploadImage = event => {
		const file = event.target.files[0];
		mainService
			.readFile(file, VALID_IMAGE_TYPES_KEY)
			.then(image => {
				if (!image) {
					return;
				}
				setIsShowValidImageErrorMsg(false);
				setUploadedImage(image);
				setImage(file);
			})
			.catch(error => {
				setIsShowValidImageErrorMsg(true);
			});
	};

	const sendCheque = currentPackage => {
		const spinnerId = uuid();
		if (!image) {
			setIsInvalidSubmit(true);
		} else {
			if (!currentPackage) {
				return;
			}
			dispatch(addModalSpinner(spinnerId));
			setAddButtonIsDisabled(true);
			const formData = new FormData();
			formData.append("cheque", image);
			formData.append("organizationPaymentPackageId", currentPackage.id);
			formData.append("organizationId", currentPackage.organizationId);
			if (currentPackage.organizationPaymentId)
				formData.append("id", currentPackage.organizationPaymentId);
			(currentPackage.organizationPaymentId
				? ApiService.updatePaymentReceipt(formData)
				: ApiService.sendPaymentReceipt(formData)
			)
				.then(() => {
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_DATA_SAVED"),
					);
					dispatch(removeModalSpinner(spinnerId));
					getPaymentPackageHistory();
					cancel();
				})
				.catch(error => getFail(error, spinnerId));
		}
	};

	const activatePackage = currentPackage => {
		const spinnerId = uuid();
		AlertService.alertConfirm(
			`${translationService.translate("TR_ACTIVATE_CONFIRM_MESSAGE")} "${
				currentPackage.packageName
			}" ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			dispatch(addPartialViewSpinner(spinnerId));
			ApiService.activatePackage(currentPackage.id)
				.then(() => {
					dispatch(removePartialViewSpinner(spinnerId));
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_SAVED"),
					);
					getPaymentPackageHistory();
				})
				.catch(error => getFail(error, spinnerId));
		});
	};

	const getPDFData = currentPackage => {
		setPDFData(currentPackage);
		setIsShowPDF(true);
	};

	const changeInStatusRecurringPayment = item => {
		const spinnerId = uuid();
		AlertService.alertConfirm(
			translationService.translate("TR_ARE_YOU_SURE_MODAL"),
			!item.recurringPayment
				? translationService.translate("TR_ACTIVATE_THIS_RECURRING_PAYMENT")
				: translationService.translate("TR_DEACTIVATE_THIS_RECURRING_PAYMENT"),

			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			dispatch(addPartialViewSpinner(spinnerId));
			ApiService.updatePaymentPackage(item.id)
				.then(() => {
					dispatch(removePartialViewSpinner(spinnerId));
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_SAVED"),
					);
					setPaymentPackages(packages =>
						packages.map(el => {
							if (el.id === item.id) {
								return {
									...el,
									recurringPayment: !el.recurringPayment,
								};
							}
							return el;
						}),
					);
				})
				.catch(error => getFail(error, spinnerId));
		});
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removePartialViewSpinner(spinnerId));
		spinnerId && dispatch(removeModalSpinner(spinnerId));
		cancel();
	};

	const cancel = () => {
		setCurrentPackage(null);
		setIsShowUpdateModal(false);
		setAddButtonIsDisabled(false);
		setIsShowUploadModal(false);
		setImage(null);
		setUploadedImage(null);
		setIsInvalidSubmit(false);
		setIsShowInfoModal(false);
		setIsShowPDF(false);
		setPDFData(null);
		setIsShowValidImageErrorMsg(false);
	};

	return translationService ? (
		<div className="container mt-4">
			<div className="row">
				<div className="col-12">
					<h2 className="section-title">
						{translationService.translate("TR_PAYMENT_PACKAGE_HISTORY")}
					</h2>
					<PageInfoBlock
						pageTitle={translationService.translate(
							"TR_PAYMENT_PACKAGE_HISTORY",
						)}
						pageName="admin_payment_package_history"
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />
			{isShowPDF && PDFData ? (
				<div className="pdf-container zoom-image-container">
					<div className="close-svg" onClick={cancel}>
						<CloseSvg />
					</div>
					<div className="pdf-container-item">
						<PDFViewer>
							<GeneratePDF
								PDFData={PDFData}
								translationService={translationService}
								invoice={invoice}
							/>
						</PDFViewer>
					</div>
				</div>
			) : null}
			{isShowUpdateModal && currentPackage ? (
				<ModalComponent
					title={currentPackage.packageName ? currentPackage.packageName : ""}
					contentClassName="resource-modal"
					addButtonIsDisabled={addButtonIsDisabled}
					actionButtonTitle={translationService.translate("TR_UPDATE")}
					cancel={cancel}
					onSubmit={() => updatePackage(currentPackage)}>
					<div className="row w-100">
						<div className="col-12">
							<div className="m-4 mt-3">
								<InputCheckBox
									id="recurringPayment"
									name="recurringPayment"
									checked={recurringPayment}
									infoText={translationService.translate("TR_REPETITIVE_INFO")}
									labelValue={translationService.translate("TR_REPETITIVE")}
									onChange={event => setRecurringPayment(event.target.checked)}
								/>
							</div>
						</div>
					</div>
				</ModalComponent>
			) : null}
			{isShowUploadModal && currentPackage ? (
				<ModalComponent
					isLarge={true}
					title={currentPackage.packageName ? currentPackage.packageName : ""}
					contentClassName="resource-modal"
					addButtonIsDisabled={addButtonIsDisabled}
					actionButtonTitle={translationService.translate("TR_SEND")}
					cancel={cancel}
					onSubmit={() => sendCheque(currentPackage)}>
					<div className="row w-100">
						<div
							className={`col-12 col-lg-6 col-md-8 p-2 m-3 ${
								isInvalidSubmit && !image ? "error-bordered" : ""
							}`}>
							<div className="course-media-wrapper">
								<p className="my-2">
									{translationService.translate("TR_UPLOAD_PAYMENT_RECEIPT")} *
								</p>
								{
									<div className="upload-file-content">
										{
											<div
												className="img"
												style={{
													backgroundImage: `url(${
														uploadedImage ? uploadedImage : ""
													})`,
												}}
												alt="course-image"></div>
										}
										{uploadedImage ? (
											<div onClick={() => removeImg()}>
												<CloseSvg />
											</div>
										) : null}
									</div>
								}
							</div>
							<div className="course-media-wrapper">
								<div className="form-group position-relative">
									<UploadButton
										textInputClasses="pl-100"
										text={translationService.translate("TR_UPLOAD_FILE")}
										clicked={uploadImage}
									/>
								</div>
							</div>
							{isShowValidImageErrorMsg && (
								<small className="red-color">
									{translationService.translate("TR_VALID_IMAGE_ERROR_MSG")}
								</small>
							)}
						</div>
					</div>
				</ModalComponent>
			) : null}
			{isShowInfoModal && currentPackage ? (
				<ModalComponentWithoutActionButton
					title={currentPackage.packageName ? currentPackage.packageName : ""}
					isLarge={true}
					contentClassName="resource-modal"
					cancel={cancel}>
					<div className="mx-4 mt-3 mb-3">
						<div className="course-media-wrapper">
							{currentPackage.chequePath ? (
								<div className="upload-file-content">
									<div
										className="img"
										style={{
											backgroundImage: `url(${currentPackage.chequePath})`,
										}}
										alt="course-image"></div>
								</div>
							) : null}
						</div>
						{currentPackage.rejectionMessage ? (
							<p className="word-wrap-break-word mt-3">
								{currentPackage.rejectionMessage}
							</p>
						) : null}
					</div>
				</ModalComponentWithoutActionButton>
			) : null}
			{paymentPackages && paymentPackages.length ? (
				<div className="row">
					<div className="col-12">
						<div className="mindayal-admin-table">
							<table id="table" className="table table-responsive">
								<thead>
									<tr>
										<th className="text-nowrap">
											{translationService.translate("TR_PACKAGE_NAME")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_START_DATE")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_END_DATE")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_MONTH_COUNT")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_PRICE")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_IS_ACTIVE")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_IS_PAID")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_RECURRING_PAYMENT")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_ACTION")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_STATUS")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_INVOICE")}
										</th>
									</tr>
								</thead>
								<tbody>
									{paymentPackages.map((paymentPackage, index) => {
										return (
											<tr key={index} className={`cursor-default`}>
												<td>
													<div>{paymentPackage.packageName}</div>
												</td>

												<td>
													<div>
														{paymentPackage.startDate
															? moment(
																	MainService.convertUTCDateToLocalDate(
																		new Date(paymentPackage.startDate),
																	),
															  ).format("ll (HH:mm)")
															: null}
													</div>
												</td>
												<td>
													<div>
														{paymentPackage.endDate
															? moment(
																	MainService.convertUTCDateToLocalDate(
																		new Date(paymentPackage.endDate),
																	),
															  ).format("ll (HH:mm)")
															: null}
													</div>
												</td>
												<td>
													<div>{paymentPackage.monthCount}</div>
												</td>
												<td>
													<div>{paymentPackage.totalPrice}</div>
												</td>
												<td>
													{paymentPackage.isActive ? (
														<div className="payment-modal-status-icon-block">
															<i className="fas fa-check transaction-successful fs-30"></i>
														</div>
													) : (
														<div className="payment-modal-status-icon-block">
															<i className="fas fa-times transaction-unsuccessful fs-30"></i>
														</div>
													)}
												</td>
												<td>
													{paymentPackage.isPaid ? (
														<div className="payment-modal-status-icon-block">
															<i className="fas fa-check transaction-successful fs-30"></i>
														</div>
													) : (
														<div className="payment-modal-status-icon-block">
															<i className="fas fa-times transaction-unsuccessful fs-30"></i>
														</div>
													)}
												</td>
												<td>
													{/* switcher petq e lini Gigona asel Haykoi het miasin 22.10.2024 */}
													{/* "pe-none disabled paymentPackage.disabled" */}
													{paymentPackage.waitingForBankPaymentConfirmation ? null : (
														<div className="custom-control custom-switch mt-2 d-flex justify-content-center">
															<input
																type="checkbox"
																id={`editModeSwitcher_${index}`}
																className="custom-control-input"
																checked={paymentPackage.recurringPayment}
																disabled={
																	currentPackage || paymentPackage.disabled
																}
																onChange={() =>
																	changeInStatusRecurringPayment(paymentPackage)
																}
															/>
															<label
																htmlFor={`editModeSwitcher_${index}`}
																className={`custom-control-label ${
																	currentPackage || paymentPackage.disabled
																		? "cursor-default"
																		: "cursor-pointer"
																} `}>
																{" "}
															</label>
														</div>
													)}
												</td>
												<td>
													{/* !paymentPackage.isPaid && // </div> //   </button> //     {translationService.translate("TR_UPDATE")} //   > //     onClick={() => showOrHideUpdateModal(paymentPackage)} //     className="mindalay--btn mindalay--btn-small mindalay--btn-default mr-1" //   <button // <div className="d-flex">
													  paymentPackage.isForSale &&
													  !paymentPackage.inProcess &&
													  !paymentPackage.canceled ? (
														<div className="d-flex">
															<button
																className="mindalay--btn mindalay--btn-small mindalay--btn-default"
																onClick={() =>
																	showOrHideUploadModal(paymentPackage)
																}>
																{translationService.translate(
																	"TR_CONFIRM_PAYMENT",
																)}
															</button>
														</div>
													)  */}
													{paymentPackage.waitingForBankPaymentConfirmation ? (
														<div className="d-flex">
															<button
																className="mindalay--btn mindalay--btn-small mindalay--btn-default"
																disabled={paymentPackage.disabled}
																onClick={() =>
																	showOrHideUploadModal(paymentPackage)
																}>
																{translationService.translate(
																	"TR_CONFIRM_PAYMENT",
																)}
															</button>
														</div>
													) : null}
													{paymentPackage.isActive &&
													paymentPackage.isForSale ? null : paymentPackage.isPaid &&
													  !paymentPackage.isActive &&
													  !paymentPackage.startDate &&
													  !paymentPackage.endDate ? (
														<div className="d-flex align-items-center">
															<button
																disabled={paymentPackage.disabled}
																className="mindalay--btn mindalay--btn-small mindalay--btn-default w-100"
																onClick={() => activatePackage(paymentPackage)}>
																{translationService.translate(
																	"TR_ACTIVATE_PACKAGE",
																)}
															</button>
														</div>
													) : null}
												</td>
												<td>
													{paymentPackage.rejectionMessage ? (
														<div
															key={paymentPackage.id}
															className="payment-modal-status-icon-block">
															<p
																className={`red-color  ${
																	paymentPackage.disabled
																		? "disabled"
																		: "cursor-pointer"
																}`}
																onClick={() =>
																	showOrHideInfoModal(paymentPackage)
																}>
																{translationService.translate("TR_DECLINED")}
															</p>
														</div>
													) : paymentPackage.inProcess ? (
														<div className="payment-modal-status-icon-block">
															<p
																className={`blue-color text-nowrap ${
																	paymentPackage.disabled
																		? "disabled"
																		: "cursor-pointer"
																}`}
																onClick={() =>
																	paymentPackage.chequePath
																		? showOrHideInfoModal(paymentPackage)
																		: null
																}>
																{translationService.translate("TR_IN_PROCESS")}
															</p>
														</div>
													) : paymentPackage.canceled ? (
														<div
															key={paymentPackage.id}
															className="payment-modal-status-icon-block">
															<p className="red-color">
																{translationService.translate(
																	"TR_PAYMENT_CANCELED",
																)}
															</p>
														</div>
													) : null}
												</td>
												<td>
													<div className="d-flex align-items-center">
														<button
															className="mindalay--btn mindalay--btn-small mindalay--btn-default w-100"
															onClick={() => getPDFData(paymentPackage)}
															disabled={currentPackage}>
															{translationService.translate("TR_INVOICE")}
														</button>
													</div>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			) : (
				<div className="no-data-container">
					<div className="no-data-wrapper">
						<p>{translationService.translate("TR_NO_DATA")}</p>
						<img src={NoDataImage} alt="/" />
					</div>
				</div>
			)}
		</div>
	) : null;
};

export default Paymentpackagehistory;
