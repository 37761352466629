import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TranslationService from "../../../Services/translationService";
import { withRouter } from "react-router";
import _InfoSvg from "../../../Components/Svg/_infoSvg";
import PageInfoBlock from "../../../Components/PageInfoBlock/PageInfoBlock";
import { Card } from "react-bootstrap";
import { Link } from "react-router-dom";
import ArrowBackSvg from "../../../Components/Svg/arrowBackSvg";
import GamifyApiService from "../../../Services/gamifyApiService";
import AlertService from "../../../Services/alertService";
import {
	addButtonSpinner,
	addPartialViewSpinner,
	removeButtonSpinner,
	removePartialViewSpinner,
} from "../../../Store/Actions/spinner";
import uuid from "react-uuid";
import { ERROR_KEY, STUDENT_USER_TYPE } from "../../../Constants/mainKeys";
import NoDataImage from "../../../assets/images/illustrations/nodata.svg";
import MainService from "../../../Services/mainService";
import moment from "moment";

const LeaderBoard = props => {
	const dispatch = useDispatch();
	const { studentId } = props.match.params;
	const { translations } = useSelector(state => state.translation);
	const { language } = useSelector(state => state.language);
	const { user } = useSelector(state => state.user);
	const [translationService, setTranslationService] = useState(null);
	const [leaderBoards, setLeaderBoards] = useState([]);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getLeaderBoards();
		return () => {
			setLeaderBoards([]);
		};
	}, []);

	const getLeaderBoards = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		GamifyApiService.getLeaderBoards(
			studentId
				? studentId
				: user.userTypeId === STUDENT_USER_TYPE
				? user.studentId
				: null,
		)
			.then(response => {
				if (response && response.data) {
					setLeaderBoards(response.data);
				}
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removePartialViewSpinner(spinnerId));
		spinnerId && dispatch(removeButtonSpinner(spinnerId));
	};

	return translationService ? (
		<div className="container">
			<div className="row mt-4">
				<div className="col-12">
					<div className="content-title p-0">
						<div className="d-flex align-items-center">
							<Link
								to={`#`}
								title={translationService.translate("TR_BACK")}
								onClick={() => window.history.back()}>
								<ArrowBackSvg />
							</Link>
							<h2 className="content-title p-0">
								{translationService.translate("TR_LEADERBOARDS")}
							</h2>
						</div>
					</div>
					<PageInfoBlock
						pageName="student_leaderboard"
						pageTitle="Leader board"
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />
			<div className="row align-self-stretch">
				{leaderBoards && leaderBoards.length ? (
					leaderBoards.map((item, index) => {
						return (
							<div key={index} className="col-12 col-md-6 col-lg-3 p-2">
								<Card className="w-100 h-100">
									<Card.Body className="p-2">
										<div className="d-flex flex-column justify-content-between h-100 p-2">
											<div>
												<h5>{item.name}</h5>
												<hr />
												<p className="max-line-3">{item.description}</p>
												<div>
													<p className="d-flex justify-content-between flex-wrap mt-2">
														<b className="mr-1">
															{translationService.translate("TR_TYPE")}
														</b>
														<b>
															{translationService.translate(
																MainService.getType(item.type),
															)
																? translationService.translate(
																		MainService.getType(item.type),
																  )
																: MainService.getType(item.type)}
														</b>
													</p>
													{item.startDate ? (
														<p className="d-flex justify-content-between mt-1">
															<b>
																{translationService.translate("TR_START_DATE")}
															</b>
															<b>
																{moment(new Date(item.startDate)).format("ll")}
															</b>
														</p>
													) : null}
													{item.endDate ? (
														<p className="d-flex justify-content-between mt-1">
															<b>
																{translationService.translate("TR_END_DATE")}
															</b>
															<b>
																{moment(new Date(item.endDate)).format("ll")}
															</b>
														</p>
													) : null}
												</div>
											</div>
											<div className="mt-3">
												<Link
													className="mindalay--btn-link mindalay--btn-dark mt-1 w-100 py-2"
													to={`/${language}/gamification/leaderboard/${item.id}`}>
													{translationService.translate("TR_VIEW")}
												</Link>
											</div>
										</div>
									</Card.Body>
								</Card>
							</div>
						);
					})
				) : studentId || user.userTypeId === STUDENT_USER_TYPE ? (
					<div className="no-data-container mt-3">
						<div className="no-data-wrapper">
							<p>{translationService.translate("TR_NO_DATA")}</p>
							<img src={NoDataImage} alt="/" />
						</div>
					</div>
				) : (
					<div className="col-12">
						<div className="row justify-content-center">
							<div className="col-12 col-md-8 col-lg-6">
								<div className="gamification-nodata">
									<p>
										Lorem Ipsum is simply dummy text of the printing and
										typesetting industry. Lorem Ipsum has been the industry's.
									</p>
								</div>
							</div>
						</div>
					</div>
				)}
			</div>
		</div>
	) : null;
};

export default withRouter(LeaderBoard);
