import React from "react";

function CreateChildSvg({ className = "", style = {} }) {
	return (
		<svg
			fill="#000000"
			width="800px"
			height="800px"
			viewBox="0 0 32 32"
			className={className}
			style={style}
			id="icon"
			xmlns="http://www.w3.org/2000/svg">
			<path d="M28,12a2,2,0,0,0,2-2V4a2,2,0,0,0-2-2H4A2,2,0,0,0,2,4v6a2,2,0,0,0,2,2H15v4H9a2,2,0,0,0-2,2v4H4a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2V24a2,2,0,0,0-2-2H9V18H23v4H20a2,2,0,0,0-2,2v4a2,2,0,0,0,2,2h8a2,2,0,0,0,2-2V24a2,2,0,0,0-2-2H25V18a2,2,0,0,0-2-2H17V12ZM12,28H4V24h8Zm16,0H20V24h8ZM4,4H28v6H4Z" />
			<rect
				id="_Transparent_Rectangle_"
				// dataName="&lt;Transparent Rectangle&gt;"
				style={{ fill: "none" }}
				width="32"
				height="32"
			/>
		</svg>
	);
}

export default CreateChildSvg;
