import React, { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import TranslationService from '../../Services/translationService';
import ModalSpinner from '../Spinners/modalSpinner';
import Modal from 'react-bootstrap/Modal';
import { useDispatch } from 'react-redux';
import { logout, userUpdateSuccess } from '../../Store/Actions/user';
import { setUserType } from './../../Store/Actions/user';
import { ALL_VALID_USER_TYPES } from '../../Constants/mainKeys';

export default function ChooseUserTypeModal(props) {

  const dispatch = useDispatch();

  const [translationService, setTranslationService] = useState(null);
  const translations = useSelector(state => state.translation.translations);
  const user = useSelector(state => state.user.user);
  const modalSpinners = useSelector(state => state.spinner.modalSpinners);
  const globalConnection = useSelector(state => state.signalR.globalConnection);
  const [isShowModal, setIsShowModal] = useState(true);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  const changeUserType = (userTypeId) => {
    const userData = { ...user };
    if (userTypeId === 2){
      userData.isAdmin = true;
    }
    userData.userTypeId = userTypeId;
    updateUserStorageData(userData);
    dispatch(setUserType(userTypeId));
    setIsShowModal(false);
  }

  const updateUserStorageData = (userData) => dispatch(userUpdateSuccess(userData))

  const logoutHandler = useCallback(() => {
    if (globalConnection && globalConnection.state === "Connected") {
      globalConnection.stop();
    }
    dispatch(logout());
    setIsShowModal(false);
  }, [globalConnection]);

  const cancel = () => {
    if (ALL_VALID_USER_TYPES.includes(user.userTypeId)) {
      setIsShowModal(false);
    } else { return; }
  }

  return (translationService && user && user.defaultUserTypeIds && isShowModal && !ALL_VALID_USER_TYPES.includes(user.userTypeId) ?
    <Modal
      size={"lg"}
      show={true}
      onHide={cancel}
    >
      <Modal.Header>
        {translationService.translate("TR_CHOOSE_USER_TYPE_MODAL_TITLE")} ?
      </Modal.Header>
      <Modal.Body>
        <div className='d-flex'>
          {
            user.defaultUserTypeIds.map(defaultUserTypeId => {
              return (() => {
                switch (defaultUserTypeId) {
                  case 2:
                    return <button key={defaultUserTypeId} type="button" className="mindalay--btn-dark m-1 flex-1" disabled={modalSpinners.length} onClick={() => changeUserType(2)}>
                      {translationService.translate("TR_ADMIN")}
                    </button>
                  case 3:
                    return <button key={defaultUserTypeId} type="button" className="mindalay--btn-dark m-1 flex-1" disabled={modalSpinners.length} onClick={() => changeUserType(3)}>
                      {translationService.translate("TR_CURATOR")}
                    </button>
                  case 5:
                    return <button key={defaultUserTypeId} type="button" className="mindalay--btn-dark m-1 flex-1" disabled={modalSpinners.length} onClick={() => changeUserType(5)}>
                      {translationService.translate("TR_LECTURER")}
                    </button>
                  default:
                    break
                }
              })()
            })
          }
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button type="button" className="mindalay--btn-red mindalay--btn-small m-1" disabled={modalSpinners.length} onClick={logoutHandler}>
            {translationService.translate("TR_LOG_OUT")}
          </button>
        </div>
      </Modal.Footer>
      <ModalSpinner spinners={modalSpinners} />
    </Modal> : null
  )
}
