import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	BOOLEAN_KEY,
	ERROR_KEY,
	NUMBER_KEY,
	STRING_KEY,
} from "../../../Constants/mainKeys";
import TranslationService from "../../../Services/translationService";
import InputRadio from "../../../Components/Inputs/inputRadio";
import RichTextEditor from "../Components/RichTextEditor";
import {
	addButtonSpinner,
	addPartialViewSpinner,
	removeButtonSpinner,
	removePartialViewSpinner,
} from "./../../../Store/Actions/spinner";
import AlertService from "../../../Services/alertService";
import ApiService from "../../../Services/apiService";
import uuid from "react-uuid";
import SelectOption from "../../../Components/SelectOption/selectOption";
import Input from "../../../Components/Inputs/input";
import { withRouter } from "react-router-dom";
import { SUCCESS_KEY } from "../../../Constants/requestKeys";
import {
	TR_CREATE_MESSAGE_KEY,
	TR_UPDATE_MESSAGE_KEY,
} from "../../../Constants/translationKeys";
import SubmitButton from "../../../Components/SubmitButton/submitButton";
import ParserService from "../../../Services/parserService";
import { Tab, Tabs } from "react-bootstrap";
import ActionButton from "../../../Components/ActionButton/actionButton";

const buttonSpinnerId = uuid();

const TrueFalseAnswer = props => {
	const dispatch = useDispatch();
	const nameMaxLength = 200;
	const markMaxCount = 1000;

	const translations = useSelector(state => state.translation.translations);

	const { questionId } = props.match.params;
	const { questionType } = props.match.params;

	const [name, setName] = useState("");
	const [mark, setMark] = useState(1);
	const [tabKey, setTabKey] = useState("short");
	const [text, setText] = useState(!questionId ? "" : null);
	const [feedback, setFeedback] = useState(!questionId ? "" : null);
	const [questionsGroups, setQuestionsGroups] = useState([]);
	const [questionsGroupId, setQuestionsGroupId] = useState(null);
	const [trueRespFeedback, setTrueRespFeedback] = useState(
		!questionId ? "" : null,
	);
	const [falseRespFeedback, setFalseRespFeedback] = useState(
		!questionId ? "" : null,
	);
	const [correctAnswer, setCorrectAnswer] = useState(true);

	const [translationService, setTranslationService] = useState(null);
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		if (questionId) {
			getQuestionById(questionId);
		}
		getQuestionGroups();
	}, []);

	const getQuestionById = questionId => {
		const spinnerId = uuid();
		setPartialViewSpinner(spinnerId);
		ApiService.getQuestionById(+questionId)
			.then(response => {
				ParserService.parseHTMLData(response.data).then(newData => {
					const questionData = { ...newData };
					questionData.text && setText(questionData.text);
					questionData.name && setName(questionData.name);
					if (questionData.isSimpleForm) {
						setTabKey("short");
					} else {
						setTabKey("long");
					}
					questionData.questionsGroupId &&
						setQuestionsGroupId(questionData.questionsGroupId);
					setFeedback(questionData.feedback ? questionData.feedback : "");
					setMark(questionData.mark ? questionData.mark : 1);
					questionData.trueRespFeedback &&
						setTrueRespFeedback(questionData.trueRespFeedback);
					questionData.falseRespFeedback &&
						setFalseRespFeedback(questionData.falseRespFeedback);
					setCorrectAnswer(questionData.correctAnswer);
				});

				extractPartialViewSpinner(spinnerId);
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getQuestionGroups = () => {
		const spinnerId = uuid();
		setPartialViewSpinner(spinnerId);
		ApiService.getQuestionsGroups()
			.then(response => {
				const questionsGroups = [...response.data];
				setQuestionsGroups(questionsGroups);
				extractPartialViewSpinner(spinnerId);
			})
			.catch(error => getFail(error, spinnerId));
	};

	const onSelectOptionChange = (event, cb) => {
		if (
			event.target.value === "" ||
			typeof +event.target.value === NUMBER_KEY
		) {
			cb(event.target.value);
		}
	};

	const onRadioChange = (event, cb) => {
		if (typeof JSON.parse(event.target.value) === BOOLEAN_KEY) {
			cb(JSON.parse(event.target.value));
		}
	};

	const onNumberChange = (event, cb, maxLength) => {
		if (event.target.value.includes("e") || event.target.value.includes(".")) {
			return false;
		}
		if (
			event.target.value === "" ||
			(typeof +event.target.value === NUMBER_KEY &&
				+event.target.value >= 0 &&
				+event.target.value <= maxLength)
		) {
			cb(event.target.value);
		}
	};

	const onEditorChange = (event, cb) => {
		const newRichText = event.editor.getData();
		cb(newRichText);
	};

	const onTextChange = (event, cb, maxLength = null) => {
		if (maxLength && maxLength < event.target.value.length) {
			return false;
		}
		cb(event.target.value);
	};

	const scrollToInvalidFieldPosition = () => {
		setTimeout(() => {
			let firstInvalidControl =
				document.querySelector(".select-fail") ||
				document.querySelector(".is-invalid") ||
				document.querySelector(".error-bordered") ||
				document.querySelector(".error-bordered-2") ||
				document.querySelector(".fail");
			firstInvalidControl &&
				window.scrollTo({
					top:
						firstInvalidControl?.getBoundingClientRect().top +
						document.documentElement.scrollTop -
						100,
					behavior: "smooth",
				});
		}, 300);
	};

	const onSubmit = event => {
		event && event.preventDefault();

		if (!text.trim().length || !questionsGroupId) {
			setIsInvalidSubmit(true);
			scrollToInvalidFieldPosition();
			return false;
		}

		const form = {
			correctAnswer,
			falseRespFeedback: tabKey === "long" ? falseRespFeedback : "",
			feedback: tabKey === "long" ? feedback : "",
			mark: +mark,
			name,
			questionType: +questionType,
			questionsGroupId: +questionsGroupId,
			text,
			trueRespFeedback: tabKey === "long" ? trueRespFeedback : "",
		};

		if (questionId) form.id = +questionId;
		dispatch(addButtonSpinner(buttonSpinnerId));
		(questionId
			? ApiService.questionUpdate(form)
			: ApiService.questionCreate(form)
		)
			.then(() => {
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate(
						`${questionId ? TR_UPDATE_MESSAGE_KEY : TR_CREATE_MESSAGE_KEY}`,
					),
				);
				props.goBack();
			})
			.catch(error => getFail(error))
			.finally(() => {
				dispatch(removeButtonSpinner(buttonSpinnerId));
			});
	};

	const setButtonSpinner = useCallback(spinner => {
		dispatch(addButtonSpinner(spinner));
	}, []);

	const extractButtonSpinner = useCallback(spinner => {
		dispatch(removeButtonSpinner(spinner));
	}, []);

	const setPartialViewSpinner = useCallback(spinner => {
		dispatch(addPartialViewSpinner(spinner));
	}, []);

	const extractPartialViewSpinner = useCallback(spinner => {
		dispatch(removePartialViewSpinner(spinner));
	}, []);

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && extractPartialViewSpinner(spinnerId);
		spinnerId && extractButtonSpinner(spinnerId);
	};

	return translationService ? (
		<form onSubmit={onSubmit}>
			<div className="tab-title-group tab-content">
				<Tabs
					activeKey={tabKey}
					onSelect={key => {
						if (key !== tabKey) {
							setTabKey(key);
						}
					}}>
					<Tab
						eventKey="short"
						// title={translationService.translate("")}
						title={"Simple form"}>
						<div className="row mt-5">
							<div className="col-lg-4 col-md-6 col-12">
								<SelectOption
									value={questionsGroupId}
									name="questionsGroupId"
									label={`${translationService.translate(
										"TR_QUESTIONS_GROUP",
									)} *`}
									defaultValue=" "
									isInvalidSubmit={isInvalidSubmit}
									items={questionsGroups}
									changed={event =>
										onSelectOptionChange(event, setQuestionsGroupId)
									}
								/>
							</div>
							<div className="col-lg-4 col-md-6 col-12">
								<Input
									id="name"
									type="text"
									name="name"
									inputClassName="pr--5"
									value={name}
									fieldLength={nameMaxLength}
									labelValue={translationService.translate("TR_NAME")}
									onChange={event =>
										onTextChange(event, setName, nameMaxLength)
									}
								/>
							</div>
							<div className="col-lg-4 col-md-6 col-12">
								<Input
									type="number"
									id="mark"
									name="mark"
									value={mark}
									isInvalidSubmit={isInvalidSubmit}
									labelValue={`${translationService.translate(
										"TR_DEFAULT_MARK",
									)} *`}
									onChange={event =>
										onNumberChange(event, setMark, markMaxCount)
									}
								/>
							</div>
							{typeof text === STRING_KEY && tabKey === "short" ? (
								<div className="col-12 mb-3">
									<RichTextEditor
										value={text}
										labelValue={`${translationService.translate(
											"TR_QUESTION",
										)} *`}
										isInvalidSubmit={isInvalidSubmit}
										onEditorChange={event => onEditorChange(event, setText)}
									/>
								</div>
							) : null}
							<div className="col-12">
								<div className="d-flex mb-3">
									<InputRadio
										blockClassName="custom-radio custom-control"
										type="radio"
										id="trueVal"
										name="correctAnswer"
										value="true"
										checked={correctAnswer === true}
										radioClassName="mr-1 custom-control-input"
										onChange={event => onRadioChange(event, setCorrectAnswer)}
										labelClassName={`${isInvalidSubmit && correctAnswer === "" ? "fail" : ""
											} custom-control-label mr-4 cursor-pointer`}
										labelValue={translationService.translate("TR_TRUE")}
									/>
									<InputRadio
										blockClassName="custom-radio custom-control"
										type="radio"
										id="falseVal"
										name="correctAnswer"
										value="false"
										checked={correctAnswer === false}
										radioClassName="custom-control-input mr-1"
										onChange={event => onRadioChange(event, setCorrectAnswer)}
										labelClassName={`${isInvalidSubmit && correctAnswer === "" ? "fail" : ""
											} custom-control-label mr-1 cursor-pointer`}
										labelValue={translationService.translate("TR_FALSE")}
									/>
								</div>
							</div>
							<div className="col-12">
								<hr />
							</div>

							<div className="col-12 mt-4 text-right">
								<ActionButton
									type="submit"
									spinnerId={buttonSpinnerId}
									className="mindalay--btn-default position-relative"
									name={translationService.translate(
										!questionId ? "TR_CREATE" : "TR_UPDATE",
									)}
								/>
							</div>
						</div>
					</Tab>
					<Tab
						eventKey="long"
						title={"Advanced form"}
					// title={translationService.translate("TR_GENERAL_INFO")}
					>
						<div className="row">
							<div className="col-lg-4 col-md-6 col-12">
								<SelectOption
									value={questionsGroupId}
									name="questionsGroupId"
									label={`${translationService.translate(
										"TR_QUESTIONS_GROUP",
									)} *`}
									defaultValue=" "
									isInvalidSubmit={isInvalidSubmit}
									items={questionsGroups}
									changed={event =>
										onSelectOptionChange(event, setQuestionsGroupId)
									}
								/>
							</div>
							<div className="col-lg-4 col-md-6 col-12">
								<Input
									id="name"
									type="text"
									name="name"
									inputClassName="pr--5"
									value={name}
									fieldLength={nameMaxLength}
									labelValue={translationService.translate("TR_NAME")}
									onChange={event =>
										onTextChange(event, setName, nameMaxLength)
									}
								/>
							</div>
							<div className="col-lg-4 col-md-6 col-12">
								<Input
									type="number"
									id="mark"
									name="mark"
									value={mark}
									isInvalidSubmit={isInvalidSubmit}
									labelValue={`${translationService.translate(
										"TR_DEFAULT_MARK",
									)} *`}
									onChange={event =>
										onNumberChange(event, setMark, markMaxCount)
									}
								/>
							</div>
							{typeof text === STRING_KEY && tabKey === "long" ? (
								<div className="col-12 mb-3">
									<RichTextEditor
										value={text}
										labelValue={`${translationService.translate(
											"TR_QUESTION",
										)} *`}
										isInvalidSubmit={isInvalidSubmit}
										onEditorChange={event => onEditorChange(event, setText)}
									/>
								</div>
							) : null}
							{typeof feedback === STRING_KEY && tabKey === "long" ? (
								<div className="col-12 mb-3">
									<RichTextEditor
										value={feedback}
										labelValue={`${translationService.translate(
											"TR_GENERAL_FEEDBACK",
										)}`}
										onEditorChange={event => onEditorChange(event, setFeedback)}
									/>
								</div>
							) : null}

							<div className="col-12">
								<hr />
							</div>

							<div className="col-12">
								<div className="d-flex mb-3">
									<InputRadio
										blockClassName="custom-radio custom-control"
										type="radio"
										id="trueVal"
										name="correctAnswer"
										value="true"
										checked={correctAnswer === true}
										radioClassName="mr-1 custom-control-input"
										onChange={event => onRadioChange(event, setCorrectAnswer)}
										labelClassName={`${isInvalidSubmit && correctAnswer === "" ? "fail" : ""
											} custom-control-label mr-4 cursor-pointer`}
										labelValue={translationService.translate("TR_TRUE")}
									/>
									<InputRadio
										blockClassName="custom-radio custom-control"
										type="radio"
										id="falseVal"
										name="correctAnswer"
										value="false"
										checked={correctAnswer === false}
										radioClassName="custom-control-input mr-1"
										onChange={event => onRadioChange(event, setCorrectAnswer)}
										labelClassName={`${isInvalidSubmit && correctAnswer === "" ? "fail" : ""
											} custom-control-label mr-1 cursor-pointer`}
										labelValue={translationService.translate("TR_FALSE")}
									/>
								</div>
							</div>
							{typeof trueRespFeedback === STRING_KEY && tabKey === "long" ? (
								<div className="col-12">
									<RichTextEditor
										value={trueRespFeedback}
										blockClassName="my-3"
										labelValue={translationService.translate(
											"TR_TRUE_RESP_FEEDBACK",
										)}
										onEditorChange={event =>
											onEditorChange(event, setTrueRespFeedback)
										}
									/>
								</div>
							) : null}
							{typeof falseRespFeedback === STRING_KEY && tabKey === "long" ? (
								<div className="col-12">
									<RichTextEditor
										value={falseRespFeedback}
										blockClassName="my-3"
										labelValue={translationService.translate(
											"TR_FALSE_RESPONSE_FEEDBACK",
										)}
										onEditorChange={event =>
											onEditorChange(event, setFalseRespFeedback)
										}
									/>
								</div>
							) : null}
							<div className="col-12 mt-4 text-right">
								<ActionButton
									type="submit"
									spinnerId={buttonSpinnerId}
									className="mindalay--btn-default position-relative"
									name={translationService.translate(
										!questionId ? "TR_CREATE" : "TR_UPDATE",
									)}
								/>
							</div>
						</div>
					</Tab>
				</Tabs>
			</div>
		</form>
	) : null;
};

export default withRouter(TrueFalseAnswer);
