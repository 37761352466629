// import React, { Component } from "react";
// import { compose } from "redux";
// import { connect } from "react-redux";
// import { Link, withRouter } from "react-router-dom";
// import uuid from "react-uuid";
// import MainService from "../../Services/mainService";
// import AlertService from "../../Services/alertService";
// import ApiService from "../../Services/apiService";
// import TranslationService from "../../Services/translationService";
// import SelectOption from "../../Components/SelectOption/selectOption";
// import MatchingAnswer from "./Answers/matchingAnswer";
// import MultiChoiseAnswer from "./Answers/multiChoiseAnswer";
// import ShortAnswer from "./Answers/shortAnswer";
// import TrueFalseAnswer from "./Answers/trueFalseAnswer";
// import Input from "../../Components/Inputs/input";
// import SubmitButton from "../../Components/SubmitButton/submitButton";
// import ArrowBackSvg from "../../Components/Svg/arrowBackSvg";
// import Textarea from "../../Components/Inputs/textArea";
// import { addButtonSpinner, removeButtonSpinner, addPartialViewSpinner, removePartialViewSpinner } from "../../Store/Actions/spinner";
// import { COURSES_KEY, INSTRUCTOR_KEY, QUESTION_BANK_KEY, QUESTION_KEY } from "../../Constants/urlKeys";
// import { TR_CREATE_MESSAGE_KEY, TR_UPDATE_MESSAGE_KEY } from "../../Constants/translationKeys";
// import {
//   ERROR_KEY,
//   SUCCESS_KEY,
//   QUESTION_TYPES_ARR_KEY,
//   NUMBER_KEY,
//   STRING_KEY
// } from "../../Constants/mainKeys";
// import RichTextEditor from "./Components/RichTextEditor";

// class QuestionForm extends Component {

//   mainService = new MainService();
//   urlSearchParams = new URLSearchParams(this.props.location.search);
//   answers = null;

//   state = {
//     form: {
//       questionsGroupId: this.props.match.params.questionsGroupId,
//       questionType: this.urlSearchParams.get("type"),
//       name: '',
//       text: '',
//       mark: 1,
//       feedback: '',
//     },
//     questionId: this.props.match.params.questionId,
//     isInvalidSubmit: false,
//     spinnerId: uuid(),
//     questionsGroups: [],
//     questionTypes: QUESTION_TYPES_ARR_KEY,
//     translationService: null,
//     failedFields: [],
//     nameMaxLength: 200,
//     questionMaxLength: 2000,
//     generalFeedBackMaxLength: 1000,
//   };

//   componentDidMount() {
//     if (!this.state.form.questionType) { return; }
//     this.setTranslations();
//     this.getQuestionGroups();
//     this.state.questionId && this.getQuestionById(this.state.questionId);
//   }

//   componentDidUpdate() {
//     this.setTranslations();
//   }

//   shouldComponentUpdate(nextProps, nextState) {
//     if (nextProps.translations
//       && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
//       this.setState({ translationService: new TranslationService(nextProps.translations) });
//     }
//     return true;
//   }

//   setTranslations = () => {
//     if (!this.state.translationService && this.props.translations) {
//       this.setState({ translationService: new TranslationService(this.props.translations) });
//     }
//   }

//   getQuestionById = questionId => {
//     const spinnerId = uuid();
//     this.props.addPartialViewSpinner(spinnerId);
//     ApiService.getQuestionById(questionId).then(response => {
//       const questionData = { ...response.data };

//       this.setState(prevState => ({
//         ...prevState,
//         form: {
//           ...prevState.form,
//           questionType: questionData.questionType.toString(),
//           name: questionData.name,
//           text: questionData.text,
//           mark: questionData.mark,
//           feedback: questionData.feedback || '',
//           questionsGroupId: questionData.questionsGroupId,
//         }
//       }));
//       questionData && setTimeout(() => {
//         this.answers = {
//           answers: questionData.questionAnswers && [...questionData.questionAnswers],
//           hints: questionData.questionHints && [...questionData.questionHints],
//           correctAnswer: questionData.correctAnswer,
//           correctRespFeedback: questionData.correctRespFeedback,
//           choiceNumbering: questionData.choiceNumbering,
//           falseRespFeedback: questionData.falseRespFeedback,
//           inCorrectRespFeedback: questionData.inCorrectRespFeedback,
//           isCaseSensitive: questionData.isCaseSensitive,
//           isMultiAnswer: questionData.isMultiAnswer,
//           isShuffle: questionData.isShuffle,
//           partCorrectRespFeedback: questionData.partCorrectRespFeedback,
//           penaltyForIncorrectAnswer: questionData.penaltyForIncorrectAnswer,
//           trueRespFeedback: questionData.trueRespFeedback,
//         }
//       }, 300);

//       this.props.removePartialViewSpinner(spinnerId);
//     }).catch(e => this.getFail(e, spinnerId));
//   }

//   getQuestionGroups = () => {
//     const spinnerId = uuid();
//     this.props.addPartialViewSpinner(spinnerId);
//     ApiService.getQuestionsGroups().then(response => {
//       const questionsGroups = [...response.data];
//       if (questionsGroups && questionsGroups.length) {
//         this.setState(prevState => ({
//           ...prevState,
//           questionsGroups,
//           form: {
//             ...prevState.form,
//             questionsGroupId: questionsGroups[0].id
//           }
//         }));
//       } else {
//         this.setState({ questionsGroups: [] });
//       }
//       this.props.removePartialViewSpinner(spinnerId);
//     }).catch(e => this.getFail(e, spinnerId));
//   }

//   onTextChange = (event, maxLength = null) => {
//     if (maxLength && maxLength < event.target.value.length) { return; }
//     if (typeof event.target.value === STRING_KEY) {
//       const failedFields = this.removeFailedFields(event.target.name);
//       this.setState(prevState => ({
//         ...prevState,
//         failedFields,
//         form: {
//           ...prevState.form,
//           [event.target.name]: event.target.value
//         }
//       }));
//     }
//   };

//   onNumberChange = event => {
//     if (+event.target.value.charAt(0) === 0) {
//       event.target.value = event.target.value.substring(1);
//     }
//     if (event.target.value.includes(".") || event.target.value.includes("e")) { return false; }
//     const failedFields = this.removeFailedFields(event.target.name);
//     this.setState(prevState => ({
//       ...prevState,
//       failedFields,
//       form: {
//         ...prevState.form,
//         [event.target.name]: event.target.value
//       }
//     }));
//   };

//   onSelectOptionChange = event => {
//     if (event.target.value === '' || (typeof +event.target.value === NUMBER_KEY && Number(event.target.value) > 0)) {
//       const failedFields = this.removeFailedFields(event.target.name);
//       this.setState(prevState => ({
//         ...prevState,
//         failedFields,
//         form: {
//           ...prevState.form,
//           [event.target.name]: event.target.value
//         }
//       }));
//     }
//   };

//   onQuestionTypeChange = event => {
//     if (typeof +event.target.value === NUMBER_KEY) {
//       const failedFields = this.removeFailedFields(event.target.name);
//       this.setState(prevState => ({
//         ...prevState,
//         failedFields,
//         form: {
//           ...prevState.form,
//           [event.target.name]: event.target.value
//         }
//       }));
//     }
//   };

//   onEditorChange = (event, name) => {
//     const newRichText = event.editor.getData();
//     this.setState(prevState => ({
//       ...prevState,
//       form: {
//         ...prevState.form,
//         [name]: newRichText
//       }
//     }))
//   }

//   scrollToInvalidFieldPosition = () => {
//     setTimeout(() => {
//       let firstInvalidControl =
//         document.querySelector(".select-fail") ||
//         document.querySelector(".is-invalid") ||
//         document.querySelector(".error-bordered") ||
//         document.querySelector(".error-bordered-2") ||
//         document.querySelector(".fail");
//       firstInvalidControl && window.scrollTo({
//         top: firstInvalidControl?.getBoundingClientRect().top + document.documentElement.scrollTop - 100,
//         behavior: "smooth"
//       });
//     }, 300);
//   }

//   onSubmit = event => {
//     event.preventDefault();
//     const { translationService, questionId, spinnerId } = this.state;
//     const form = { ...this.state.form };
//     const answersData = { ...this.answers };
//     if (
//       !form.questionsGroupId
//       || !form.text
//       || !form.text.trim()
//       || form.questionType === ''
//       || !form.mark
//     ) {
//       this.setState({ isInvalidSubmit: true });
//       this.scrollToInvalidFieldPosition();
//     } else {
//       form.questionsGroupId = +form.questionsGroupId;
//       form.questionType = +form.questionType;
//       form.mark = +form.mark;

//       if (questionId) { form.id = +questionId; }

//       if (answersData) {
//         if (answersData.isGradeError) { return; }
//         if (answersData.answers && answersData.answers.length) {
//           const filledAnswers = answersData.answers.filter(data => {
//             if (data.question) {
//               return data.text && data.text.trim() && data.question && data.question.trim();
//             } else {
//               return data.text && data.text.trim();
//             }
//           });
//           if ((form.questionType === 1 || form.questionType === 0) && filledAnswers.length < 2) {
//             this.setState({ isInvalidSubmit: true });
//             this.scrollToInvalidFieldPosition();
//             return false;
//           }
//           if (form.questionType === 2 && filledAnswers.length < 1) {
//             this.setState({ isInvalidSubmit: true });
//             this.scrollToInvalidFieldPosition();
//             return false;
//           }
//           const answers = [...answersData.answers];
//           form.answers = answers.filter(answer => {
//             for (let i in answer) {
//               answer[i] === null && delete answer[i];
//               if (i === 'markPercent') {
//                 answer[i] = +answer[i];
//               }
//             }
//             if (answer.text) { return answer; }
//           });
//         }

//         else if (form.questionType !== 3) {
//           this.setState({ isInvalidSubmit: true });
//           this.scrollToInvalidFieldPosition();
//           return false;
//         } else if (form.questionType === 3 && answersData.correctAnswer === '') {
//           this.setState({ isInvalidSubmit: true });
//           this.scrollToInvalidFieldPosition();
//           return false;
//         }
//         if (answersData.hints && answersData.hints.length) {
//           const hints = [...answersData.hints];
//           form.hints = hints.filter(hint => hint.text && hint.text.trim() && hint);
//         }
//         if (answersData.isCaseSensitive || answersData.isCaseSensitive === false) { form.isCaseSensitive = Boolean(JSON.parse(answersData.isCaseSensitive)); }
//         if (answersData.isMultiAnswer) { form.isMultiAnswer = Boolean(JSON.parse(answersData.isMultiAnswer)); }
//         if (answersData.isShuffle === true || answersData.isShuffle === false) { form.isShuffle = answersData.isShuffle; }
//         if (answersData.correctAnswer === true || answersData.correctAnswer === false) { form.correctAnswer = answersData.correctAnswer; }
//         if (answersData.choiceNumbering) { form.choiceNumbering = +answersData.choiceNumbering; }
//         if (answersData.trueRespFeedback) { form.trueRespFeedback = answersData.trueRespFeedback; }
//         if (answersData.falseRespFeedback) { form.falseRespFeedback = answersData.falseRespFeedback; }
//         if (answersData.correctRespFeedback) { form.correctRespFeedback = answersData.correctRespFeedback; }
//         if (answersData.partCorrectRespFeedback) { form.partCorrectRespFeedback = answersData.partCorrectRespFeedback; }
//         if (answersData.inCorrectRespFeedback) { form.inCorrectRespFeedback = answersData.inCorrectRespFeedback; }
//         if (answersData.penaltyForIncorrectAnswer || +answersData.penaltyForIncorrectAnswer === 0) { form.penaltyForIncorrectAnswer = +answersData.penaltyForIncorrectAnswer; }
//       }
//       this.props.addButtonSpinner(spinnerId);
//       (questionId
//         ? ApiService.questionUpdate(form)
//         : ApiService.questionCreate(form)).then(() => {
//           AlertService.alert(SUCCESS_KEY, translationService.translate(`${questionId ? TR_UPDATE_MESSAGE_KEY : TR_CREATE_MESSAGE_KEY}`));
//           this.props.removeButtonSpinner(spinnerId);
//           this.goBack();
//         }).catch(e => this.submitFail(spinnerId, e));
//     }
//   }

//   submitFail = (spinnerId, errors) => {
//     this.props.removeButtonSpinner(spinnerId);
//     const failedFields = errors;
//     this.setState({ failedFields });
//     errors && AlertService.alert((AlertService.checkMessageType(errors.respcode) || ERROR_KEY), errors);
//   }

//   removeFailedFields = name => {
//     const failedFields = this.state.failedFields && { ...this.state.failedFields };
//     const fieldName = name.replace(name[0], name[0].toUpperCase());
//     failedFields && delete failedFields[fieldName];
//     return failedFields;
//   };

//   getFail = (error, spinnerId) => {
//     spinnerId && this.props.removePageSpinner(spinnerId);
//     error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
//   }

//   goBack = () => {
//     const { language, user } = this.props;
//     if (user.isOrganizationUser) {
//       this.props.history.push(`/${language}/${QUESTION_KEY}`);
//     } else {
//       this.props.history.push(`/${language}/${INSTRUCTOR_KEY}/${COURSES_KEY}/${QUESTION_BANK_KEY}`);
//     }
//   }

//   render() {
//     const { user } = this.props;

//     const {
//       isInvalidSubmit,
//       spinnerId,
//       translationService,
//       questionId,
//       failedFields,
//       questionsGroups,
//       questionTypes,
//       nameMaxLength,
//       questionMaxLength,
//       generalFeedBackMaxLength,
//     } = this.state;

//     const {
//       questionsGroupId,
//       questionType,
//       name,
//       text,
//       mark,
//       feedback,
//     } = this.state.form;

//     return translationService ? <div className={user.isOrganizationUser ? "m-section" : ""}>
//       <div className="container">
//         <h2 className="content-title p-0">
//           <Link to="#" title={translationService.translate("TR_BACK")} onClick={this.goBack}><ArrowBackSvg /></Link>
//           {translationService.translate(questionTypes[questionType])}
//         </h2>
//         <hr />
//         <form onSubmit={this.onSubmit}>
//           <div className="row">
//             <div className="col-lg-4 col-md-6 col-12">
//               <SelectOption
//                 value={questionsGroupId}
//                 name="questionsGroupId"
//                 label={`${translationService.translate("TR_QUESTIONS_GROUP")} *`}
//                 defaultValue=" "
//                 isInvalidSubmit={isInvalidSubmit}
//                 items={questionsGroups}
//                 changed={this.onSelectOptionChange}
//                 failedFields={failedFields}
//               />
//             </div>
//             <div className="col-lg-4 col-md-6 col-12">
//               <Input
//                 id="name"
//                 type="text"
//                 name="name"
//                 inputClassName="pr--5"
//                 value={name}
//                 fieldLength={nameMaxLength}
//                 labelValue={translationService.translate("TR_NAME")}
//                 onChange={event => this.onTextChange(event, nameMaxLength)}
//                 failedFields={failedFields}
//               />
//             </div>
//             <div className="col-lg-4 col-md-6 col-12">
//               <Input
//                 type="number"
//                 id="mark"
//                 name="mark"
//                 value={mark}
//                 isInvalidSubmit={isInvalidSubmit}
//                 labelValue={`${translationService.translate("TR_DEFAULT_MARK")} *`}
//                 onChange={this.onNumberChange}
//                 failedFields={failedFields}
//               />
//             </div>
//             <div className="col-12 mb-3">
//               {/* <Textarea
//                 id="text"
//                 name="text"
//                 rows="2"
//                 textAreaClassName="pr--5"
//                 value={text}
//                 max={questionMaxLength}
//                 isInvalidSubmit={isInvalidSubmit}
//                 isInvalidField={isInvalidSubmit && !text.trim() ? true : false}
//                 labelValue={`${translationService.translate("TR_QUESTION")} *`}
//                 onChange={event => this.onTextChange(event, questionMaxLength)}
//                 failedFields={failedFields}
//               /> */}
//               <RichTextEditor
//                 value={text}
//                 labelValue={`${translationService.translate("TR_QUESTION")} *`}
//                 isInvalidSubmit={isInvalidSubmit}
//                 onEditorChange={(event) => this.onEditorChange(event, "text")}
//               />

//             </div>
//             {
//               questionType !== "1" ? <div className="col-12">
//                 {/* <Textarea
//                   rows="2"
//                   id="feedback"
//                   name="feedback"
//                   textAreaClassName="pr--5"
//                   value={feedback}
//                   max={generalFeedBackMaxLength}
//                   labelValue={translationService.translate("TR_GENERAL_FEEDBACK")}
//                   onChange={event => this.onTextChange(event, generalFeedBackMaxLength)}
//                   failedFields={failedFields}
//                 /> */}
//                 <RichTextEditor
//                   value={feedback}
//                   labelValue={`${translationService.translate("TR_GENERAL_FEEDBACK")}`}
//                   onEditorChange={(event) => this.onEditorChange(event, "feedback")}
//                 />
//               </div> : null
//             }
//             <div className="col-12"><hr /></div>
//             {
//               (() => {
//                 switch (questionType) {
//                   case "0":
//                     return <MultiChoiseAnswer
//                       changed={event => this.answers = event}
//                       answersData={this.answers}
//                       isInvalidSubmit={isInvalidSubmit}
//                       failedFields={failedFields}
//                     />;
//                   case "1":
//                     return <MatchingAnswer
//                       changed={event => this.answers = event}
//                       answersData={this.answers}
//                       isInvalidSubmit={isInvalidSubmit}
//                       failedFields={failedFields}
//                     />;
//                   case "2":
//                     return <ShortAnswer
//                       changed={event => this.answers = event}
//                       answersData={this.answers}
//                       isInvalidSubmit={isInvalidSubmit}
//                       failedFields={failedFields}
//                     />;
//                   case "3":
//                     return <TrueFalseAnswer
//                       changed={event => this.answers = event}
//                       answersData={this.answers}
//                       isInvalidSubmit={isInvalidSubmit}
//                     />;
//                   default:
//                     break
//                 }
//               })()
//             }
//             <div className="col-12 mt-4 text-right">
//               <SubmitButton
//                 id={questionId}
//                 spinnerId={spinnerId}
//               />
//             </div>
//           </div>
//         </form>
//       </div>
//     </div>
//       : null;
//   }
// }

// const mapStateToProps = state => ({
//   pageSpinners: state.spinner.pageSpinners,
//   buttonSpinners: state.spinner.buttonSpinners,
//   translations: state.translation.translations,
//   language: state.language.language,
//   languages: state.language.languages,
//   user: state.user.user
// });

// const mapDispatchToProps = {
//   addButtonSpinner,
//   removeButtonSpinner,
//   addPartialViewSpinner,
//   removePartialViewSpinner
// };

// export default compose(
//   connect(mapStateToProps, mapDispatchToProps),
//   withRouter
// )(QuestionForm);

import React, { useEffect, useState } from "react";
import { compose } from "redux";
import { connect, useSelector } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import TranslationService from "../../Services/translationService";
import MatchingAnswer from "./Answers/matchingAnswer";
import MultiChoiseAnswer from "./Answers/multiChoiseAnswer";
import ShortAnswer from "./Answers/shortAnswer";
import TrueFalseAnswer from "./Answers/trueFalseAnswer";
import ArrowBackSvg from "../../Components/Svg/arrowBackSvg";
import {
	addButtonSpinner,
	removeButtonSpinner,
	addPartialViewSpinner,
	removePartialViewSpinner,
} from "../../Store/Actions/spinner";
import {
	COURSES_KEY,
	INSTRUCTOR_KEY,
	QUESTION_BANK_KEY,
	QUESTION_KEY,
} from "../../Constants/urlKeys";
import { QUESTION_TYPES_ARR_KEY } from "../../Constants/mainKeys";
import PageInfoBlock from "../../Components/PageInfoBlock/PageInfoBlock";

const QuestionForm = props => {
	const questionTypes = QUESTION_TYPES_ARR_KEY;
	const { questionType } = props.match.params;

	const language = useSelector(state => state.language.language);
	const translations = useSelector(state => state.translation.translations);
	const user = useSelector(state => state.user.user);
	const [translationService, setTranslationService] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const goBack = () => {
		if (user.isOrganizationUser) {
			props.history.push(`/${language}/${QUESTION_KEY}`);
		} else {
			props.history.push(
				`/${language}/${INSTRUCTOR_KEY}/${COURSES_KEY}/${QUESTION_BANK_KEY}`,
			);
		}
	};

	return translationService && questionType ? (
		<div className={user.isOrganizationUser ? "m-section" : ""}>
			<div className="container">
				<div className="row">
					<div className="col-12 position-relative">
						<div style={{ position: "relative" }}>
							<h2 className="content-title p-0">
								<Link
									to="#"
									title={translationService.translate("TR_BACK")}
									onClick={goBack}>
									<ArrowBackSvg />
								</Link>
								{translationService.translate(questionTypes[questionType])}
							</h2>
							<div>
								<PageInfoBlock
									pageTitle={translationService.translate(
										"TR_PAYMENT_PACKAGE_HISTORY",
									)}
									infoText={translationService.translate("TR_FILE_COPY_TEXT")}
									translationService={translationService}
								/>
							</div>
						</div>

						<hr />
						{(() => {
							switch (questionType) {
								case "0":
									return <MultiChoiseAnswer goBack={goBack} />;
								case "1":
									return <MatchingAnswer goBack={goBack} />;
								case "2":
									return <ShortAnswer goBack={goBack} />;
								case "3":
									return <TrueFalseAnswer goBack={goBack} />;
								default:
									break;
							}
						})()}
					</div>
				</div>
			</div>
		</div>
	) : null;
};

const mapStateToProps = state => ({
	pageSpinners: state.spinner.pageSpinners,
	buttonSpinners: state.spinner.buttonSpinners,
	translations: state.translation.translations,
	languages: state.language.languages,
});

const mapDispatchToProps = {
	addButtonSpinner,
	removeButtonSpinner,
	addPartialViewSpinner,
	removePartialViewSpinner,
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter,
)(QuestionForm);
