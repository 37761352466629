import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SelectOption from "../../../Components/SelectOption/selectOption";
import TranslationService from "../../../Services/translationService";
import InputCheckBox from "../../../Components/Inputs/inputCheckBox";
import {
	MULTI_ANSWER_TYPES,
	CHOISE_NUMBERING_TYPES_ARR_KEY,
	BOOLEAN_KEY,
	STRING_KEY,
	NUMBER_KEY,
	ERROR_KEY,
} from "../../../Constants/mainKeys";
import RichTextEditor from "../Components/RichTextEditor";
import { withRouter } from "react-router-dom";
import { useCallback } from "react";
import {
	addButtonSpinner,
	addPartialViewSpinner,
	removeButtonSpinner,
	removePartialViewSpinner,
} from "../../../Store/Actions/spinner";
import AlertService from "../../../Services/alertService";
import ApiService from "../../../Services/apiService";
import uuid from "react-uuid";
import { useEffect } from "react";
import SubmitButton from "../../../Components/SubmitButton/submitButton";
import Input from "../../../Components/Inputs/input";
import { SUCCESS_KEY } from "../../../Constants/requestKeys";
import {
	TR_CREATE_MESSAGE_KEY,
	TR_UPDATE_MESSAGE_KEY,
} from "../../../Constants/translationKeys";
import ParserService from "../../../Services/parserService";
import { Tab, Tabs } from "react-bootstrap";
import CustomTitle from "../../../Components/CustomTitle/customTitle";
import ActionButton from "../../../Components/ActionButton/actionButton";
const buttonSpinnerId = uuid();

const MultiChoiseAnswer = props => {
	const dispatch = useDispatch();
	const [tabKey, setTabKey] = useState("short");

	const nameMaxLength = 200;
	const markMaxCount = 1000;

	const POSITIVE_GRADES_ARR_KEY = [
		0, 100, 90, 83.33, 80, 75, 70, 66.66, 60, 50, 40, 33.33, 30, 25, 20, 16.66,
		14.28, 12.5, 11.11, 10, 5,
	];
	const GRADES_ARR_KEY = [
		0, 100, 90, 83.33, 80, 75, 70, 66.66, 60, 50, 40, 33.33, 30, 25, 20, 16.66,
		14.28, 12.5, 11.11, 10, 5, -5, -10, -11.11, -12.5, -14.28, -16.66, -20, -25,
		-30, -33.33, -40, -50, -60, -66.66, -70, -75, -80, -83.33, -90, -100,
	];

	const answerTypes = MULTI_ANSWER_TYPES;
	const choiseNumberingTypes = CHOISE_NUMBERING_TYPES_ARR_KEY;
	const translations = useSelector(state => state.translation.translations);
	const { questionId, questionType } = props.match.params;

	const [name, setName] = useState("");
	const [mark, setMark] = useState(1);
	const [text, setText] = useState(!questionId ? "" : null);
	const [feedback, setFeedback] = useState(!questionId ? "" : null);
	const [questionsGroups, setQuestionsGroups] = useState([]);
	const [questionsGroupId, setQuestionsGroupId] = useState(null);
	const [answers, setAnswers] = useState([]);
	const [hints, setHints] = useState([]);
	const [isShuffle, setIsShuffle] = useState(true);
	const [choiceNumbering, setChoiceNumbering] = useState(0);
	const [penaltyForIncorrectAnswer, setPenaltyForIncorrectAnswer] = useState(0);
	const [isMultiAnswer, setIsMultiAnswer] = useState(false);
	const [grades, setGrades] = useState(POSITIVE_GRADES_ARR_KEY);

	const [correctRespFeedback, setCorrectRespFeedback] = useState(
		!questionId ? "" : null,
	);
	const [partCorrectRespFeedback, setPartCorrectRespFeedback] = useState(
		!questionId ? "" : null,
	);
	const [inCorrectRespFeedback, setInCorrectRespFeedback] = useState(
		!questionId ? "" : null,
	);

	const [translationService, setTranslationService] = useState(null);
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(null);
	const [isGradeError, setIsGradeError] = useState(false);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		if (questionId) {
			getQuestionById(questionId);
		} else {
			addAnswers();
			addHints(3);
		}
		getQuestionGroups();
	}, []);

	const getQuestionGroups = () => {
		const spinnerId = uuid();
		setPartialViewSpinner(spinnerId);
		ApiService.getQuestionsGroups()
			.then(response => {
				const questionsGroups = [...response.data];
				setQuestionsGroups(questionsGroups);
				extractPartialViewSpinner(spinnerId);
			})
			.catch(error => getFail(error, spinnerId));
	};

	const getQuestionById = questionId => {
		const spinnerId = uuid();
		setPartialViewSpinner(spinnerId);
		ApiService.getQuestionById(+questionId)
			.then(response => {
				ParserService.parseHTMLData(response.data).then(newData => {
					const questionData = { ...newData };
					let isGradeError = hasGradeError(
						questionData.answers ? Object.values(questionData.answers) : [],
						questionData.isMultiAnswer,
					);
					if (
						!questionData.questionAnswers ||
						(questionData.questionAnswers &&
							!questionData.questionAnswers.length)
					) {
						addAnswers();
					} else {
						setAnswers(questionData.questionAnswers);
					}
					if (
						!questionData.questionHints ||
						(questionData.questionHints && !questionData.questionHints.length)
					) {
						addHints(3);
					} else {
						setHints(questionData.questionHints);
					}
					if (questionData.isSimpleForm) {
						setTabKey("short");
					} else {
						setTabKey("long");
					}
					setIsGradeError(isGradeError);
					setIsShuffle(questionData.isShuffle ? true : false);
					setChoiceNumbering(questionData.choiceNumbering || 0);
					setMark(questionData.mark ? questionData.mark : 1);
					setIsMultiAnswer(+questionData.isMultiAnswer);
					questionData.answers && setAnswers(questionData.answers);
					questionData.hints && setHints(questionData.hints);
					questionData.text && setText(questionData.text);
					questionData.name && setName(questionData.name);
					setFeedback(questionData.feedback ? questionData.feedback : "");
					questionData.questionsGroupId &&
						setQuestionsGroupId(questionData.questionsGroupId);
					questionData.penaltyForIncorrectAnswer &&
						setPenaltyForIncorrectAnswer(
							questionData.penaltyForIncorrectAnswer,
						);
					setCorrectRespFeedback(
						questionData.correctRespFeedback
							? questionData.correctRespFeedback
							: "",
					);
					setInCorrectRespFeedback(
						questionData.inCorrectRespFeedback
							? questionData.inCorrectRespFeedback
							: "",
					);
					setPartCorrectRespFeedback(
						questionData.partCorrectRespFeedback
							? questionData.partCorrectRespFeedback
							: "",
					);
				});

				extractPartialViewSpinner(spinnerId);
			})
			.catch(error => getFail(error, spinnerId));
	};

	const addAnswers = () => {
		const answersCopy = [...answers];
		for (let i = 1; i <= 3; i++) {
			answersCopy.push({
				text: "",
				markPercent: 0,
				feedback: "",
			});
		}
		setAnswers(answersCopy);
	};

	const addHints = hintsCount => {
		const hintsCopy = [...hints];
		for (let i = 0; i < hintsCount; i++) {
			hintsCopy.push({
				text: "",
			});
		}
		setHints(hintsCopy);
	};

	const onCheckboxChange = (event, cb) => {
		if (typeof event.target.checked === BOOLEAN_KEY) {
			cb(event.target.checked);
		}
	};

	const onCheckboxChangeAnswers = (event, index) => {
		const newAnswers = [...answers].map((el, i) => {
			if (i === index) {
				if (event.target.checked) {
					return {
						...el,
						markPercent: 100,
					};
				} else {
					return {
						...el,
						markPercent: 0,
					};
				}
			}
			return el;
		});
		const countInMarkPercent = newAnswers.filter(el => el.markPercent).length;

		setAnswers(
			newAnswers.map(item => {
				if (item.markPercent) {
					let percent = 100 / countInMarkPercent;
					// Convert percent to a fixed-point number, ensuring it's a number and has two decimals if needed.
					return {
						...item,
						markPercent: percent % 1 === 0 ? percent : +percent.toFixed(2),
					};
				}
				return item;
			}),
		);
	};

	const onSelectOptionChange = (event, cb) => {
		var answersCopy = [...answers];
		if (event.target.name === "isMultiAnswer") {
			answersCopy.forEach(answer => (answer.markPercent = 0));
			if (+event.target.value === 0) {
				setGrades(POSITIVE_GRADES_ARR_KEY);
				setAnswers(answersCopy);
			} else {
				setGrades(GRADES_ARR_KEY);
				setAnswers(answersCopy);
			}
		}
		if (
			event.target.value === "" ||
			typeof +event.target.value === NUMBER_KEY
		) {
			cb(event.target.value);
		}
	};

	const onNumberChange = (event, cb, maxLength = null) => {
		if (event.target.value.includes("e") || event.target.value.includes(".")) {
			return false;
		}
		if (
			event.target.value === "" ||
			(typeof +event.target.value === NUMBER_KEY &&
				+event.target.value >= 0 &&
				+event.target.value <= maxLength)
		) {
			cb(event.target.value);
		}
	};

	const onEditorChange = (event, cb) => {
		const newRichText = event.editor.getData();
		cb(newRichText);
	};

	const onAnswerChange = (event, answerIndex, type, maxLength = null) => {
		if (
			maxLength &&
			type === STRING_KEY &&
			maxLength < event.target.value.length
		) {
			return;
		}
		if (
			(typeof event.target.value === type && type !== NUMBER_KEY) ||
			(type === NUMBER_KEY && typeof +event.target.value === type)
		) {
			const answer = { ...answers[answerIndex] };
			answer[event.target.name] = +event.target.value;
			const answersCopy = [...answers];
			answersCopy[answerIndex] = answer;
			const isGradeError = hasGradeError(answersCopy);
			setIsGradeError(isGradeError);
			setAnswers(answersCopy);
		}
	};

	const onEditorHineChange = (event, hintIndex, name) => {
		const newRichText = event.editor.getData();
		const hint = { ...hints[hintIndex] };
		hint[name] = newRichText;
		const hintsCopy = [...hints];
		hintsCopy[hintIndex] = hint;
		setHints(hintsCopy);
	};

	const onEditorAnswerChange = (event, answerIndex, name) => {
		const newRichText = event.editor.getData();
		const answer = { ...answers[answerIndex] };
		answer[name] = newRichText;
		const answersCopy = [...answers];
		answersCopy[answerIndex] = answer;
		const isGradeError = hasGradeError(answersCopy);
		setIsGradeError(isGradeError);
		setAnswers(answersCopy);
	};

	const onTextChange = (event, cb, maxLength = null) => {
		if (maxLength && maxLength < event.target.value.length) {
			return false;
		}
		cb(event.target.value);
	};

	const hasGradeError = (answers, isMultiAnswerItem = false) => {
		if (isMultiAnswer || isMultiAnswerItem) {
			const grades = answers.map(data =>
				data.markPercent >= 0 ? +data.markPercent : 0,
			);
			const gradesSum = grades.reduce((a, b) => a + b, 0);
			return gradesSum > 100;
		} else {
			return false;
		}
	};

	const scrollToInvalidFieldPosition = () => {
		setTimeout(() => {
			let firstInvalidControl =
				document.querySelector(".select-fail") ||
				document.querySelector(".is-invalid") ||
				document.querySelector(".error-bordered") ||
				document.querySelector(".error-bordered-2") ||
				document.querySelector(".fail");
			firstInvalidControl &&
				window.scrollTo({
					top:
						firstInvalidControl?.getBoundingClientRect().top +
						document.documentElement.scrollTop -
						100,
					behavior: "smooth",
				});
		}, 300);
	};

	const onSubmit = event => {
		event && event.preventDefault();
		const filledAnswers = answers.filter(data => {
			if (data.text) {
				return data.text && data.text.trim();
			}
		});

		if (
			!text.trim().length ||
			!questionsGroupId ||
			!filledAnswers.length ||
			(filledAnswers.length && filledAnswers.length < 2)
		) {
			setIsInvalidSubmit(true);
			scrollToInvalidFieldPosition();
			return false;
		}

		let form = {
			answers: answers.filter(
				answer => answer && answer.text && answer.text.trim().length,
			),
			choiceNumbering,
			correctRespFeedback,
			feedback,
			hints: hints.filter(hint => hint && hint.text && hint.text.trim().length),
			inCorrectRespFeedback,
			isMultiAnswer: !!+isMultiAnswer,
			isShuffle,
			mark,
			name,
			partCorrectRespFeedback,
			penaltyForIncorrectAnswer: 0,
			questionType: +questionType,
			questionsGroupId: +questionsGroupId,
			text,
		};
		if (tabKey === "short") {
			form = {
				answers: answers
					.filter(answer => answer && answer.text && answer.text.trim().length)
					.map(el => ({ ...el, feedback: "" })),
				choiceNumbering: 0,
				correctRespFeedback: "",
				feedback: "",
				hints: [],
				inCorrectRespFeedback: "",
				isMultiAnswer: !!+isMultiAnswer,
				isShuffle,
				mark,
				name,
				partCorrectRespFeedback: "",
				penaltyForIncorrectAnswer: +penaltyForIncorrectAnswer,
				questionType: +questionType,
				questionsGroupId: +questionsGroupId,
				text,
			};
		}
		if (questionId) form.id = +questionId;
		dispatch(addButtonSpinner(buttonSpinnerId));
		(questionId
			? ApiService.questionUpdate(form)
			: ApiService.questionCreate(form)
		)
			.then(() => {
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate(
						`${questionId ? TR_UPDATE_MESSAGE_KEY : TR_CREATE_MESSAGE_KEY}`,
					),
				);
				props.goBack();
			})
			.catch(error => {
				getFail(error);
			})
			.finally(() => {
				dispatch(removeButtonSpinner(buttonSpinnerId));
			});
	};

	const setButtonSpinner = useCallback(spinner => {
		dispatch(addButtonSpinner(spinner));
	}, []);

	const extractButtonSpinner = useCallback(spinner => {
		dispatch(removeButtonSpinner(spinner));
	}, []);

	const setPartialViewSpinner = useCallback(spinner => {
		dispatch(addPartialViewSpinner(spinner));
	}, []);

	const extractPartialViewSpinner = useCallback(spinner => {
		dispatch(removePartialViewSpinner(spinner));
	}, []);

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && extractPartialViewSpinner(spinnerId);
		spinnerId && extractButtonSpinner(spinnerId);
	};

	const filledAnswers = answers.filter(data => data.text);

	return translationService ? (
		<form onSubmit={onSubmit}>
			<div className="tab-title-group tab-content">
				<Tabs
					activeKey={tabKey}
					onSelect={key => {
						if (key !== tabKey) {
							setTabKey(key);
						}
					}}>
					<Tab
						eventKey="short"
						// title={translationService.translate("")}
						title={"Simple form"}>
						<div className="row mt-5">
							<div className="col-lg-4 col-md-6 col-12">
								<SelectOption
									value={questionsGroupId}
									name="questionsGroupId"
									label={`${translationService.translate(
										"TR_QUESTIONS_GROUP",
									)} *`}
									defaultValue=" "
									isInvalidSubmit={isInvalidSubmit}
									items={questionsGroups}
									changed={event =>
										onSelectOptionChange(event, setQuestionsGroupId)
									}
								/>
							</div>
							<div className="col-lg-4 col-md-6 col-12">
								<Input
									id="name"
									type="text"
									name="name"
									inputClassName="pr--5"
									value={name}
									fieldLength={nameMaxLength}
									labelValue={translationService.translate("TR_NAME")}
									onChange={event =>
										onTextChange(event, setName, nameMaxLength)
									}
								/>
							</div>
							<div className="col-lg-4 col-md-6 col-12">
								<Input
									type="number"
									id="mark"
									name="mark"
									value={mark}
									isInvalidSubmit={isInvalidSubmit}
									labelValue={`${translationService.translate(
										"TR_DEFAULT_MARK",
									)} *`}
									onChange={event =>
										onNumberChange(event, setMark, markMaxCount)
									}
								/>
							</div>
							{typeof text === STRING_KEY && tabKey === "short" ? (
								<div className="col-12 mb-3">
									<RichTextEditor
										value={text}
										labelValue={`${translationService.translate(
											"TR_QUESTION",
										)} *`}
										isInvalidSubmit={isInvalidSubmit}
										onEditorChange={event => onEditorChange(event, setText)}
									/>
								</div>
							) : null}

							<div className="col-md-6 col-12">
								<SelectOption
									name="isMultiAnswer"
									value={isMultiAnswer}
									label={translationService.translate(
										"TR_ONE_OR_MULTI_ANSWERS",
									)}
									items={answerTypes}
									changed={event =>
										onSelectOptionChange(event, setIsMultiAnswer)
									}
								/>
							</div>

							<div className="col-md-6 col-12 d-flex align-items-center">
								<InputCheckBox
									id="isShuffle"
									name="isShuffle"
									checked={isShuffle}
									labelValue={translationService.translate("TR_SHUFFLE")}
									blockClassName="mb-3"
									onChange={event => onCheckboxChange(event, setIsShuffle)}
								/>
							</div>
							<div className="col-12">
								<hr />
							</div>
							<div className="col-12">
								<div className="content-sub-title d-flex align-items-center justify-content-between">
									<h3>{translationService.translate("TR_ANSWERS")}</h3>
								</div>
								{isInvalidSubmit && filledAnswers.length < 2 ? (
									<div className="mb-2">
										<small className="fail form-text text-muted">
											{translationService
												.translate("TR_QUESTION_ANSWERS_COUNT_REQUIRED_MSG")
												.replace("{0}", "2")}
										</small>
									</div>
								) : null}
								{answers &&
									answers.map((answer, index) => {
										const { text, feedback, markPercent } = answer;
										return (
											<div
												key={index}
												className={`p-2 mt-3 light-gray-background ${isInvalidSubmit && !text && index < 2
														? "is-invalid error-bordered"
														: ""
													}`}>
												<div className="col-12">
													<label>
														{translationService.translate("TR_ANSWER")}
													</label>
													<InputCheckBox
														id={`answer-${index}`}
														checked={!!markPercent}
														labelValue={`${translationService.translate(
															"TR_TRUE_ANSWER",
														)}`}
														blockClassName="mb-3"
														onChange={event =>
															onCheckboxChangeAnswers(event, index)
														}
													/>
													{tabKey === "short" ? (
														<RichTextEditor
															value={text}
															blockClassName={` mb-3`}
															onEditorChange={event =>
																onEditorAnswerChange(event, index, "text")
															}
														/>
													) : null}
												</div>
											</div>
										);
									})}
								<div className="text-right mt-4">
									<button
										type="button"
										className="mindalay--btn-secondary mindalay--btn-small"
										onClick={addAnswers}>
										<i className="fas fa-plus"></i>
										{translationService.translate("TR_ADD_ANSWERS")} (3)
									</button>
								</div>
							</div>

							<div className="col-12 mt-4 text-right">
								<ActionButton
									type="submit"
									spinnerId={buttonSpinnerId}
									className="mindalay--btn-default position-relative"
									name={translationService.translate(
										!questionId ? "TR_CREATE" : "TR_UPDATE",
									)}
								/>
							</div>
						</div>
					</Tab>
					<Tab
						eventKey="long"
						title={"Advanced form"}
					// title={translationService.translate("TR_GENERAL_INFO")}
					>
						<div className="row mt-5">
							<div className="col-lg-4 col-md-6 col-12">
								<SelectOption
									value={questionsGroupId}
									name="questionsGroupId"
									label={`${translationService.translate(
										"TR_QUESTIONS_GROUP",
									)} *`}
									defaultValue=" "
									isInvalidSubmit={isInvalidSubmit}
									items={questionsGroups}
									changed={event =>
										onSelectOptionChange(event, setQuestionsGroupId)
									}
								/>
							</div>
							<div className="col-lg-4 col-md-6 col-12">
								<Input
									id="name"
									type="text"
									name="name"
									inputClassName="pr--5"
									value={name}
									fieldLength={nameMaxLength}
									labelValue={translationService.translate("TR_NAME")}
									onChange={event =>
										onTextChange(event, setName, nameMaxLength)
									}
								/>
							</div>
							<div className="col-lg-4 col-md-6 col-12">
								<Input
									type="number"
									id="mark"
									name="mark"
									value={mark}
									isInvalidSubmit={isInvalidSubmit}
									labelValue={`${translationService.translate(
										"TR_DEFAULT_MARK",
									)} *`}
									onChange={event =>
										onNumberChange(event, setMark, markMaxCount)
									}
								/>
							</div>
							{typeof text === STRING_KEY && tabKey === "long" ? (
								<div className="col-12 mb-3">
									<RichTextEditor
										value={text}
										labelValue={`${translationService.translate(
											"TR_QUESTION",
										)} *`}
										isInvalidSubmit={isInvalidSubmit}
										onEditorChange={event => onEditorChange(event, setText)}
									/>
								</div>
							) : null}
							{typeof feedback === STRING_KEY && tabKey === "long" ? (
								<div className="col-12 mb-3">
									<RichTextEditor
										value={feedback}
										labelValue={`${translationService.translate(
											"TR_GENERAL_FEEDBACK",
										)}`}
										onEditorChange={event => onEditorChange(event, setFeedback)}
									/>
								</div>
							) : null}
							<div className="col-md-6 col-12">
								<SelectOption
									name="isMultiAnswer"
									value={isMultiAnswer}
									label={translationService.translate(
										"TR_ONE_OR_MULTI_ANSWERS",
									)}
									items={answerTypes}
									changed={event =>
										onSelectOptionChange(event, setIsMultiAnswer)
									}
								/>
							</div>
							<div className="col-md-6 col-12">
								<SelectOption
									name="choiceNumbering"
									value={choiceNumbering}
									label={translationService.translate("TR_CHOICE_NUMBERING")}
									items={choiseNumberingTypes}
									changed={event =>
										onSelectOptionChange(event, setChoiceNumbering)
									}
								/>
							</div>
							<div className="col-12">
								<InputCheckBox
									id="isShuffle"
									name="isShuffle"
									checked={isShuffle}
									labelValue={translationService.translate("TR_SHUFFLE")}
									blockClassName="mb-3"
									onChange={event => onCheckboxChange(event, setIsShuffle)}
								/>
							</div>
							<div className="col-12">
								<hr />
							</div>
							<div className="col-12">
								<div className="content-sub-title d-flex align-items-center justify-content-between">
									<h3>{translationService.translate("TR_ANSWERS")}</h3>
								</div>
								{isInvalidSubmit && filledAnswers.length < 2 ? (
									<div className="mb-2">
										<small className="fail form-text text-muted">
											{translationService
												.translate("TR_QUESTION_ANSWERS_COUNT_REQUIRED_MSG")
												.replace("{0}", "2")}
										</small>
									</div>
								) : null}
								{answers &&
									answers.map((answer, index) => {
										const { text, feedback, markPercent } = answer;
										return (
											<div
												key={index}
												className={`p-2 mt-3 light-gray-background ${isInvalidSubmit && !text && index < 2
														? "is-invalid error-bordered"
														: ""
													}`}>
												<div className="col-12">
													{tabKey === "long" && (
														<RichTextEditor
															value={text}
															blockClassName={` mb-3`}
															labelValue={`${translationService.translate(
																"TR_ANSWER",
															)} *`}
															onEditorChange={event =>
																onEditorAnswerChange(event, index, "text")
															}
														/>
													)}
												</div>
												<div className="col-lg-6 col-md-6 col-12">
													<SelectOption
														returnItem={true}
														hasPercentSign={true}
														error={isGradeError}
														name="markPercent"
														label={translationService.translate(
															"TR_GRADE_PERCENT",
														)}
														value={markPercent}
														items={grades}
														changed={event =>
															onAnswerChange(event, index, NUMBER_KEY)
														}
													/>
												</div>
												<div className="col-12">
													{tabKey === "long" && (
														<RichTextEditor
															value={feedback}
															labelValue={`${translationService.translate(
																"TR_FEEDBACK",
															)}`}
															onEditorChange={event =>
																onEditorAnswerChange(event, index, "feedback")
															}
														/>
													)}
												</div>
											</div>
										);
									})}
								<div className="text-right mt-4">
									<button
										type="button"
										className="mindalay--btn-secondary mindalay--btn-small"
										onClick={addAnswers}>
										<i className="fas fa-plus"></i>
										{translationService.translate("TR_ADD_ANSWERS")} (3)
									</button>
								</div>
							</div>
							<div className="col-12">
								<hr />
							</div>
							<div className="col-md-6 col-12">
								<SelectOption
									returnItem={true}
									hasPercentSign={true}
									label={translationService.translate("TR_PENALTY_PERCENT")}
									name="penaltyForIncorrectAnswer"
									value={penaltyForIncorrectAnswer}
									items={grades}
									changed={event =>
										onSelectOptionChange(event, setPenaltyForIncorrectAnswer)
									}
								/>
							</div>
							<div className="col-12">
								<div className="content-sub-title d-flex align-items-center justify-content-between">
									<h3>{translationService.translate("TR_HINTS")}</h3>
								</div>
							</div>
							<div id="hint--multiple-choice-question" className="col-12">
								<div className="row">
									{hints &&
										hints.map((hint, index) => {
											const { text } = hint;
											return (
												<div key={index} className="col-12">
													{tabKey === "long" && (
														<RichTextEditor
															value={text}
															blockClassName="my-2"
															labelValue={`${translationService.translate(
																"TR_HINT",
															)} ${index + 1}`}
															onEditorChange={event =>
																onEditorHineChange(event, index, "text")
															}
														/>
													)}
												</div>
											);
										})}
								</div>
							</div>
							<div className="col-12">
								<div className="text-right mt-2">
									<button
										type="button"
										className="mindalay--btn-secondary mindalay--btn-small"
										onClick={() => addHints(1)}>
										<i className="fas fa-plus"></i>
										{translationService.translate("TR_ADD_HINT")}
									</button>
								</div>
							</div>
							<div className="col-12">
								<hr />
							</div>
							<div className="col-12">
								<div className="content-sub-title d-flex align-items-center justify-content-between">
									<h3>
										{translationService.translate("TR_COMBINED_FEEDBACK")}
									</h3>
								</div>
							</div>
							{typeof correctRespFeedback === STRING_KEY &&
								tabKey === "long" ? (
								<div className="col-12">
									<RichTextEditor
										value={correctRespFeedback}
										blockClassName="my-3"
										labelValue={translationService.translate(
											"TR_CORRECT_RESP_FEEDBACK",
										)}
										onEditorChange={event =>
											onEditorChange(event, setCorrectRespFeedback)
										}
									/>
								</div>
							) : null}
							{typeof partCorrectRespFeedback === STRING_KEY &&
								tabKey === "long" ? (
								<div className="col-12">
									<RichTextEditor
										value={partCorrectRespFeedback}
										blockClassName="my-3"
										labelValue={translationService.translate(
											"TR_PART_CORRECT_RESP_FEEDBACK",
										)}
										onEditorChange={event =>
											onEditorChange(event, setPartCorrectRespFeedback)
										}
									/>
								</div>
							) : null}
							{typeof inCorrectRespFeedback === STRING_KEY &&
								tabKey === "long" ? (
								<div className="col-12">
									<RichTextEditor
										value={inCorrectRespFeedback}
										blockClassName="my-3"
										labelValue={translationService.translate(
											"TR_INCORRECT_RESP_FEEDBACK",
										)}
										onEditorChange={event =>
											onEditorChange(event, setInCorrectRespFeedback)
										}
									/>
								</div>
							) : null}
							<div className="col-12 mt-4 text-right">
								<ActionButton
									type="submit"
									spinnerId={buttonSpinnerId}
									className="mindalay--btn-default position-relative"
									name={translationService.translate(
										!questionId ? "TR_CREATE" : "TR_UPDATE",
									)}
								/>
								{/* <SubmitButton id={questionId} spinnerId={buttonSpinnerId} /> */}
							</div>
						</div>
					</Tab>
				</Tabs>
			</div>
		</form>
	) : null;
};

export default withRouter(MultiChoiseAnswer);
