import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TranslationService from "./../../Services/translationService";
import NoDataImage from "../../assets/images/illustrations/nodata.svg";
import AlertService from "../../Services/alertService";
import {
	ERROR_KEY,
	PAYMENT_RECEIPT_TYPES,
	SUCCESS_KEY,
	TR_NO,
	TR_YES,
} from "../../Constants/mainKeys";
import {
	removePartialViewSpinner,
	addPartialViewSpinner,
	removeModalSpinner,
	removePageSpinner,
} from "../../Store/Actions/spinner";
import uuid from "react-uuid";
import ApiService from "../../Services/apiService";
import CloseSvg from "../../Components/Svg/closeSvg";
import ModalComponent from "../../Components/ModalComponent/modalComponent";
import Textarea from "./../../Components/Inputs/textArea";
import { addModalSpinner } from "./../../Store/Actions/spinner";
import ReactPaginate from "react-paginate";
import ReactSelectOption from "../../Components/SelectOption/reactSelectOption";
import { Link, withRouter } from "react-router-dom/cjs/react-router-dom";
import ContractSvg from "../../Components/Svg/contractSvg";
import DollarSvg from "../../Components/Svg/dollarSvg";
import EditSvg from "../../Components/Svg/editSvg";
import CustomTitle from "../../Components/CustomTitle/customTitle";
import ModalComponentWithoutActionButton from "../../Components/ModalComponent/modalComponentWithoutActionButton";
import InputCheckBox from "../../Components/Inputs/inputCheckBox";
import Input from "../../Components/Inputs/input";

const useDebounce = (value, delay) => {
	const [debouncedValue, setDebouncedValue] = useState(value);
	useEffect(() => {
		const handler = setTimeout(() => {
			setDebouncedValue(value);
		}, delay);

		return () => {
			clearTimeout(handler);
		};
	}, [value, delay]);

	return debouncedValue;
};

const Organizations = () => {
	const dispatch = useDispatch();
	const translations = useSelector(state => state.translation.translations);
	const [translationService, setTranslationService] = useState(null);
	const [organizations, setOrganizations] = useState([]);
	const [declineMessage, setDeclineMessage] = useState("");
	const [receiptType, setReceiptType] = useState(0);
	const [showOrganizationDataModal, setShowOrganizationDataModal] =
		useState(false);
	const [organizationData, setOrganizationData] = useState(null);
	const [showOrganizationPaymentsModal, setShowOrganizationPaymentsModal] =
		useState(false);
	const { language } = useSelector(state => state.language);
	const [paymentSystems, setPaymentSystems] = useState([]);
	const [organizationPaymentSystems, setOrganizationPaymentSystems] = useState(
		[],
	);
	const [groups, setGroups] = useState([]);
	const [values, setValues] = useState([]);

	const [pagination, setPagination] = useState(null);
	const [activePageNumber, setActivePageNumber] = useState(0);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getOrganizations();
		getPaymentSystemPackages();
	}, []);

	const onCheckboxChange = (event, item) => {
		if (!item || !organizationData) return false;
		const spinnerId = uuid();
		dispatch(addModalSpinner(spinnerId));
		if (event.target.checked) {
			ApiService.createPaymentSystemsOrganization(item.id, organizationData.id)
				.then(response => {
					if (response && response.data) {
						AlertService.alert(
							SUCCESS_KEY,
							translationService.translate("TR_DATA_SAVED"),
						);
						setOrganizationPaymentSystems(systems => [
							...systems,
							response.data,
						]);
					}
				})
				.catch(error => {
					error &&
						AlertService.alert(
							AlertService.checkMessageType(error.respcode) || ERROR_KEY,
							error,
						);
				})
				.finally(() => {
					dispatch(removeModalSpinner(spinnerId));
				});
		} else {
			ApiService.deletePaymentSystemsOrganization(item.id, organizationData.id)
				.then(response => {
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_DATA_SAVED"),
					);
					if (response && response.status === 200) {
						setOrganizationPaymentSystems(systems =>
							systems.filter(el => el.id !== item.id),
						);
					}
				})
				.catch(error => {
					error &&
						AlertService.alert(
							AlertService.checkMessageType(error.respcode) || ERROR_KEY,
							error,
						);
				})
				.finally(() => {
					dispatch(removePageSpinner(spinnerId));
				});
		}
		// setPaymentSystems(arr =>
		// 	arr.map(el => {
		// 		if (el.id === item.id) {
		// 			return {
		// 				...el,
		// 				active: event.target.checked,
		// 			};
		// 		}
		// 		return el;
		// 	}),
		// );
	};

	const getPaymentSystemPackages = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getPaymentSystemPackages()
			.then(response => {
				if (response && response.data && response.data.length) {
					setPaymentSystems(response.data);
				}
			})
			.catch(error => {
				error &&
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					);
			})
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const getOrganizations = (receiptType, currentPage) => {
		const spinnerId = uuid();
		setOrganizations([]);
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getOrganizations()
			.then(response => {
				if (response && response.data) {
					setOrganizations(response.data);
				}
				dispatch(removePartialViewSpinner(spinnerId));
			})
			.catch(error => getFail(error, spinnerId));
	};

	// const acceptPaymentReceipt = paymentReceipt => {
	// 	if (!paymentReceipt) {
	// 		return;
	// 	}
	// 	const spinnerId = uuid();
	// 	AlertService.alertConfirm(
	// 		`${translationService.translate("TR_ACCEPT_CONFIRM_MESSAGE")} "${
	// 			paymentReceipt.paymentPackageName
	// 		}" ?`,
	// 		"",
	// 		translationService.translate(TR_YES),
	// 		translationService.translate(TR_NO),
	// 	).then(() => {
	// 		dispatch(addPartialViewSpinner(spinnerId));
	// 		ApiService.acceptPaymentReceipt(paymentReceipt.id)
	// 			.then(() => {
	// 				AlertService.alert(
	// 					SUCCESS_KEY,
	// 					translationService.translate("TR_DATA_SAVED"),
	// 				);
	// 				dispatch(removePartialViewSpinner(spinnerId));
	// 				getOrganizations(receiptType, 1);
	// 			})
	// 			.catch(error => getFail(error, spinnerId));
	// 	});
	// };

	const cancel = () => {
		setOrganizationData(null);
		setShowOrganizationPaymentsModal(false);
	};

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && dispatch(removePartialViewSpinner(spinnerId));
		spinnerId && dispatch(removeModalSpinner(spinnerId));
	};

	const onClose = () => {
		setOrganizationData(null);
		setShowOrganizationDataModal(false);
	};

	const openPayment = item => {
		if (!item) return false;
		const spinerId = uuid();
		setOrganizationData(item);
		setShowOrganizationPaymentsModal(true);
		dispatch(addModalSpinner(spinerId));
		ApiService.getPaymentSystemByOrganization(item.id)
			.then(response => {
				if (response && response.data && response.data.length) {
					setOrganizationPaymentSystems(response.data);
				}
			})
			.catch(error => {
				cancel();
				error &&
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					);
			})
			.finally(() => {
				dispatch(removeModalSpinner(spinerId));
			});
	};

	const handlePageClick = event => {
		// getPaymentHistory(event.selected + 1, entityType);
		setActivePageNumber(event.selected);
	};

	return translationService ? (
		<div className="container">
			{showOrganizationPaymentsModal && (
				<ModalComponentWithoutActionButton
					isLarge={true}
					title={translationService.translate("TR_PAYMENT_METHODS")}
					cancel={cancel}>
					<div className="row">
						<div className="col-12">
							{paymentSystems && paymentSystems.length ? (
								<div className="payments-logos-container d-flex flex-wrap">
									{paymentSystems.map((item, id) => (
										<div
											key={id}
											className={`payment-logo-block border rounded ${
												organizationPaymentSystems &&
												organizationPaymentSystems.length &&
												organizationPaymentSystems.some(el => el.id === item.id)
													? "active-payment-system"
													: ""
											}`}>
											<InputCheckBox
												id={`lecturerAttachmentOn-${id}`}
												name="lecturerAttachmentOn"
												blockClassName="mb-0"
												disabled={paymentSystems.length <= 1}
												checked={
													organizationPaymentSystems &&
													organizationPaymentSystems.length &&
													organizationPaymentSystems.some(
														el => el.id === item.id,
													)
												}
												labelValue={<img src={item.logo} alt="/" />}
												onChange={event => {
													onCheckboxChange(event, item);
												}}
											/>
										</div>
									))}
								</div>
							) : null}
						</div>
					</div>
				</ModalComponentWithoutActionButton>
			)}
			{showOrganizationDataModal && !!organizationData && (
				<ModalComponent
					cancel={onClose}
					cancelButtonTitle="TR_CLOSE"
					title={organizationData?.name}>
					{organizationData ? (
						<div className="row">
							<div className="col-12">
								{/* <div className="d-flex justify-content-between  border-bottom">
									<b className="text-capitalize">{`${translationService.translate(
										"TR_ORGANIZATION_NAME",
									)}:`}</b>
									<span>{}</span>
								</div> */}
								<div className="d-flex justify-content-between pb-2 border-bottom">
									<b className="text-capitalize">{`${translationService.translate(
										"TR_ORGANIZATION_EMAIL",
									)}:`}</b>
									<span>{organizationData?.email}</span>
								</div>
								<div className="d-flex justify-content-between py-2 border-bottom">
									<b className="text-capitalize">{`${translationService.translate(
										"TR_USER_NAME",
									)}:`}</b>
									<span>{organizationData?.organizationAdminName}</span>
								</div>
							</div>
						</div>
					) : (
						<div className="no-data-container mt-3">
							<div className="no-data-wrapper">
								<img src={NoDataImage} alt="/" height={150} />
							</div>
						</div>
					)}
				</ModalComponent>
			)}
			<div className="row">
				<div className="col-12">
					<h2 className="content-title p-0">
						{translationService.translate("TR_ORGANIZATIONS")}
					</h2>
					<hr />
				</div>
			</div>
			<div className="row mb-4 mt-2">
				<div className="col-lg-6 col-md-12 mt-2">
					<Input
						type="text"
						id="searchText"
						name="searchText"
						// value={searchText}
						placeholder={`${translationService.translate("TR_SEARCH")}`}
						labelClassName="mt-2"
						// onChange={event => setSearchText(event.target.value)}
					/>
				</div>
				<div className="col-lg-6 col-md-12 mt-2">
					<ReactSelectOption
						value={receiptType}
						selectedValue={(() => {
							const selectedValue = {
								...PAYMENT_RECEIPT_TYPES.find(
									data => data.value === receiptType,
								),
							};
							if (selectedValue) {
								selectedValue.label = translationService.translate(
									selectedValue.label,
								);
								selectedValue.value = selectedValue.value;
							}
							return selectedValue;
						})()}
						items={PAYMENT_RECEIPT_TYPES.map(data => ({
							label: translationService.translate(data.label),
							value: data.value,
						}))}
						// onChange={item => filterByReceiptType(item)}
					/>
				</div>
			</div>
			{organizations && organizations.length ? (
				<div className="row">
					<div className="col-12">
						<div className="mindayal-admin-table table-responsive">
							<table id="table" className="table">
								<thead>
									<tr>
										<th className="text-nowrap">
											{translationService.translate("TR_NAME")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_ADMINISTRATOR")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_ACTIVE")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_ACTIONS")}
										</th>
									</tr>
								</thead>
								<tbody>
									{organizations.map((item, index) => {
										return (
											<tr key={index} className="cursor-default">
												<td>
													<div>{item.name}</div>
												</td>
												<td>
													<div>{item.organizationAdminName}</div>
												</td>
												<td>
													{item.isActive ? (
														<div className="payment-modal-status-icon-block">
															<i className="fas fa-check transaction-successful fs-30"></i>
														</div>
													) : (
														<div className="payment-modal-status-icon-block">
															<i className="fas fa-times transaction-unsuccessful fs-30"></i>
														</div>
													)}
												</td>

												<td className="d-flex align-items-center">
													<div className="table-action-btn-group d-flex align-items-center">
														<CustomTitle
															title={translationService.translate("TR_EDIT")}>
															<Link
																to={`/${language}/mindalay-admin/organization-contract-form/${item.id}`}
																// title={translationService.translate("TR_EDIT")}
																onClick={event => {
																	event.stopPropagation();
																}}
																className="table-action-btn edit-btn">
																<ContractSvg />
															</Link>
														</CustomTitle>
														{item.isContractExist && (
															<CustomTitle
																title={translationService.translate(
																	"TR_PAYMENT_METHODS",
																)}>
																<Link
																	to={`#`}
																	// title={translationService.translate(
																	// 	"TR_PAYMENT_METHODS",
																	// )}
																	onClick={event => {
																		event.stopPropagation();
																		openPayment(item);
																	}}
																	className="table-action-btn add-btn">
																	<DollarSvg />
																</Link>
															</CustomTitle>
														)}
													</div>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
						{pagination ? (
							<div className="row mt-4">
								<div className="col-12">
									<div>
										<ReactPaginate
											nextLabel={translationService.translate("TR_NEXT")}
											onPageChange={handlePageClick}
											pageRangeDisplayed={3}
											marginPagesDisplayed={2}
											pageCount={pagination.TotalPages}
											previousLabel={translationService.translate(
												"TR_PREVIOUS",
											)}
											pageClassName="page-item"
											pageLinkClassName="page-link"
											previousClassName="page-item"
											previousLinkClassName="page-link"
											nextClassName="page-item"
											nextLinkClassName="page-link"
											breakLabel="..."
											breakClassName="page-item"
											breakLinkClassName="page-link"
											containerClassName="pagination"
											activeClassName="active"
											renderOnZeroPageCount={null}
											forcePage={activePageNumber}
										/>
									</div>
								</div>
							</div>
						) : null}
					</div>
				</div>
			) : (
				<div className="no-data-container">
					<div className="no-data-wrapper">
						<p>{translationService.translate("TR_NO_DATA")}</p>
						<img src={NoDataImage} alt="/" />
					</div>
				</div>
			)}
		</div>
	) : null;
};

export default withRouter(Organizations);
