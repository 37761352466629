import React, { Component, useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import TranslationService from "../../Services/translationService";
import {
	addPartialViewSpinner,
	removePartialViewSpinner,
} from "../../Store/Actions/spinner";
import uuid from "react-uuid";
import ApiService from "../../Services/apiService";
import moment from "moment";
import MainService from "../../Services/mainService";
import { withRouter } from "react-router-dom";
import AlertService from "../../Services/alertService";
import NoDataImage from "../../assets/images/illustrations/nodata.svg";

import {
	ERROR_KEY,
	PAYMENT_SISTEM_CANCELED_STATUSES,
	PAYMENT_SISTEM_PENDING_STATUSES,
	PAYMENT_SISTEM_SUCCESS_STATUSES,
	PAYMENT_TYPES_ORG_STUDENT,
	STUDENT_USER_TYPE,
} from "../../Constants/mainKeys";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import ReactPaginate from "react-paginate";
import _InfoSvg from "./../../Components/Svg/_infoSvg";
import PageInfoBlock from "../../Components/PageInfoBlock/PageInfoBlock";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";

const Overview = props => {
	const paymentTypes = PAYMENT_TYPES_ORG_STUDENT;
	const location = useLocation();
	const dispatch = useDispatch();

	const language = useSelector(state => state.language.language);
	const user = useSelector(state => state.user.user);
	const translations = useSelector(state => state.translation.translations);
	const [translationService, setTranslationService] = useState(null);
	const [currentPaymentHistory, setCurrentPaymentHistory] = useState([]);
	const [pagination, setPagination] = useState(null);
	const [activePageNumber, setActivePageNumber] = useState(0);
	const [paymentType, setPaymentType] = useState(7);
	const queryParams = new URLSearchParams(location.search);
	const history = useHistory();

	// useEffect(() => {
	// 	if (queryParams.get("status") === "false" && queryParams.get("payId")) {
	// 		const spinnerId = uuid();
	// 		dispatch(addPartialViewSpinner(spinnerId));
	// 		ApiService.newPayCancel(queryParams.get("payId"))
	// 			.then(response => {})
	// 			.catch(error => {
	// 				error &&
	// 					AlertService.alert(
	// 						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
	// 						error,
	// 					);
	// 			})
	// 			.finally(() => {
	// 				dispatch(removePartialViewSpinner(spinnerId));
	// 			});
	// 	}
	// }, [queryParams.get("payId"), queryParams.get("status")]);

	useEffect(() => {
		getPaymentHistory({
			currentPage: 1,
		});
	}, []);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const getPaymentHistory = ({ data = {}, currentPage }) => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.newPayHistory(data, currentPage, 10)
			.then(response => {
				if (response && response.pagination)
					setPagination(JSON.parse(response.pagination));
				setCurrentPaymentHistory(response.data);
			})
			.catch(error => {
				error &&
					AlertService.alert(
						AlertService.checkMessageType(error.respcode) || ERROR_KEY,
						error,
					);
			})
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const handlePageClick = event => {
		getPaymentHistory({
			currentPage: event.selected + 1,
		});
		setActivePageNumber(event.selected);
	};

	const filterPaymentHistory = selectedCategory => {
		const spinnerId = uuid();
		getPaymentHistory({
			currentPage: 1,
		});
		setActivePageNumber(0);
		setPaymentType(selectedCategory.value);
	};

	return translationService ? (
		<div className="container mt-4">
			<div
				className={`${user.userTypeId === STUDENT_USER_TYPE ? "m-section" : ""
					}`}>
				<div className="row">
					<div className="col-12">
						<div className="row align-items-end">
							<div className="col-12">
								<h2 className="section-title">
									{translationService.translate("TR_PAYMENT_HISTORY")}
								</h2>
								<PageInfoBlock
									pageTitle={translationService.translate("TR_PAYMENT_HISTORY")}
									pageName="admin_payment_history"
									translationService={translationService}
								/>
							</div>
						</div>
						<hr />
					</div>
					{/* <div className="col-lg-4 col-sm-6 col-12">
						<div className="form-group m-0">
							<label htmlFor="#">
								{translationService.translate("TR_TRANSACTION_TYPES")}
							</label>
							<ReactSelectOption
								value={paymentType}
								placeholder={translationService.translate("TR_CHOOSE")}
								selectedValue={(() => {
									const selectedValue = {
										...paymentTypes.find(data => data.value === paymentType),
									};
									if (Object.keys(selectedValue).length) {
										selectedValue.label = translationService.translate(
											selectedValue.label,
										)
											? translationService.translate(selectedValue.label)
											: selectedValue.label;
										return selectedValue;
									} else {
										return {
											label: translationService.translate("TR_CHOOSE"),
											value: 0,
										};
									}
								})()}
								items={paymentTypes.map(data => ({
									label: translationService.translate(data.label)
										? translationService.translate(data.label)
										: data.label,
									value: data.value,
								}))}
								onChange={item => filterPaymentHistory(item)}
							/>
						</div>
					</div> */}
					<div className="col-12">
						{currentPaymentHistory && currentPaymentHistory.length ? (
							<div className="row">
								<div className="col-12">
									<div className="mindayal-admin-table mt-3 table-responsive">
										<table className="table">
											<thead>
												<tr>
													<th className="text-nowrap">
														{translationService.translate("TR_PRICE")}
													</th>
													<th className="text-nowrap">
														{translationService.translate(
															"TR_TRANSACTION_DATE",
														)}
													</th>
													<th className="text-nowrap">
														{translationService.translate("TR_PROVIDER")}
													</th>
													<th className="text-nowrap">
														{translationService.translate("TR_ENTITY_NAME")}
													</th>

													<th className="text-nowrap">
														{translationService.translate("TR_STATUS")}
													</th>
												</tr>
											</thead>
											<tbody>
												{currentPaymentHistory.map((item, index) => {
													return (
														<tr key={index} className="cursor-default">
															<td>{item.amountWithCurrency}</td>
															<td>
																{moment(
																	MainService.convertUTCDateToLocalDate(
																		new Date(item.createDate),
																	),
																).format("ll (HH:mm)")}
															</td>
															<td>
																<div>
																	<span
																		title={item.statusName}
																		className={`payment-status ${PAYMENT_SISTEM_PENDING_STATUSES.includes(
																			item.paymentStatus,
																		)
																			? "orange-border orange-color"
																			: PAYMENT_SISTEM_SUCCESS_STATUSES.includes(
																				item.paymentStatus,
																			)
																				? "green-border green-color"
																				: PAYMENT_SISTEM_CANCELED_STATUSES.includes(
																					item.paymentStatus,
																				)
																					? "red-border red-color"
																					: ""
																			}`}>
																		{translationService.translate(
																			item.statusName,
																		)
																			? translationService.translate(
																				item.statusName,
																			)
																			: item.statusName}
																	</span>
																</div>
															</td>
															{paymentType !== 1 ? (
																<td>
																	{item?.entityTypeName ||
																		item?.paymentProviderName}
																</td>
															) : null}

															<td>
																<div>
																	<span
																		title={item.statusName}
																		className={`payment-status ${PAYMENT_SISTEM_PENDING_STATUSES.includes(
																			item.paymentStatus,
																		)
																			? "orange-border orange-color"
																			: PAYMENT_SISTEM_SUCCESS_STATUSES.includes(
																				item.paymentStatus,
																			)
																				? "green-border green-color"
																				: PAYMENT_SISTEM_CANCELED_STATUSES.includes(
																					item.paymentStatus,
																				)
																					? "red-border red-color"
																					: ""
																			}`}>
																		{translationService.translate(
																			item.statusName,
																		)
																			? translationService.translate(
																				item.statusName,
																			)
																			: item.statusName}
																	</span>
																</div>
															</td>
														</tr>
													);
												})}
											</tbody>
										</table>
									</div>
								</div>
							</div>
						) : (
							<Auxiliary>
								<div className="no-data-container mt-3">
									<div className="no-data-wrapper">
										<p>
											{translationService.translate(
												"TR_THERE_IS_NO_TRANSACTIONS_OF_THIS_TYPE",
											)}
										</p>
										<img src={NoDataImage} alt="/" />
									</div>
								</div>
							</Auxiliary>
						)}
					</div>
					{pagination && pagination.TotalPages > 1 ? (
						<div className="col-12 mt-4">
							<div className="d-flex justify-content-end w-100">
								<ReactPaginate
									nextLabel={translationService.translate("TR_NEXT")}
									onPageChange={handlePageClick}
									pageRangeDisplayed={3}
									marginPagesDisplayed={2}
									pageCount={pagination.TotalPages}
									previousLabel={translationService.translate("TR_PREVIOUS")}
									pageClassName="page-item"
									pageLinkClassName="page-link"
									previousClassName="page-item"
									previousLinkClassName="page-link"
									nextClassName="page-item"
									nextLinkClassName="page-link"
									breakLabel="..."
									breakClassName="page-item"
									breakLinkClassName="page-link"
									containerClassName="pagination"
									activeClassName="active"
									renderOnZeroPageCount={null}
									forcePage={activePageNumber}
								/>
							</div>
						</div>
					) : null}
				</div>
			</div>
		</div>
	) : null;
};
export default withRouter(Overview);
