import React, { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import TranslationService from "../../../Services/translationService";
import SelectOption from "../../../Components/SelectOption/selectOption";
import InputCheckBox from "../../../Components/Inputs/inputCheckBox";
import {
	BOOLEAN_KEY,
	ERROR_KEY,
	NUMBER_KEY,
	STRING_KEY,
} from "../../../Constants/mainKeys";
import RichTextEditor from "../Components/RichTextEditor";
import ApiService from "../../../Services/apiService";
import {
	addButtonSpinner,
	addPartialViewSpinner,
	removeButtonSpinner,
	removePartialViewSpinner,
} from "../../../Store/Actions/spinner";
import AlertService from "../../../Services/alertService";
import uuid from "react-uuid";
import { withRouter } from "react-router-dom";
import Input from "../../../Components/Inputs/input";
import SubmitButton from "../../../Components/SubmitButton/submitButton";
import {
	TR_CREATE_MESSAGE_KEY,
	TR_UPDATE_MESSAGE_KEY,
} from "../../../Constants/translationKeys";
import { SUCCESS_KEY } from "../../../Constants/requestKeys";
import ParserService from "../../../Services/parserService";
import { Tab, Tabs } from "react-bootstrap";

const buttonSpinnerId = uuid();

const MatchingAnswer = props => {
	const nameMaxLength = 200;
	const markMaxCount = 1000;

	const dispatch = useDispatch();
	const { questionId } = props.match.params;
	const { questionType } = props.match.params;
	const grades = [
		0, 100, 90, 83.33, 80, 75, 70, 66.66, 60, 50, 40, 33.33, 30, 25, 20, 16.66,
		14.28, 12.5, 11.11, 10, 5,
	];

	const translations = useSelector(state => state.translation.translations);
	const user = useSelector(state => state.user.user);
	const language = useSelector(state => state.language.language);
	const [tabKey, setTabKey] = useState("short");
	const [name, setName] = useState("");
	const [mark, setMark] = useState(1);
	const [text, setText] = useState(!questionId ? "" : null);
	const [questionsGroups, setQuestionsGroups] = useState([]);
	const [questionsGroupId, setQuestionsGroupId] = useState(null);

	const [answers, setAnswers] = useState([]);
	const [hints, setHints] = useState([]);
	const [isShuffle, setIsShuffle] = useState(true);
	const [correctRespFeedback, setCorrectRespFeedback] = useState(
		!questionId ? "" : null,
	);
	const [partCorrectRespFeedback, setPartCorrectRespFeedback] = useState(
		!questionId ? "" : null,
	);
	const [inCorrectRespFeedback, setInCorrectRespFeedback] = useState(
		!questionId ? "" : null,
	);
	const [penaltyForIncorrectAnswer, setPenaltyForIncorrectAnswer] = useState(0);

	const [translationService, setTranslationService] = useState(null);
	const [isInvalidSubmit, setIsInvalidSubmit] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		if (questionId) {
			getQuestionById(questionId);
		} else {
			addAnswers();
			addHints(3);
		}
		getQuestionGroups();
	}, []);

	const getQuestionById = questionId => {
		const spinnerId = uuid();
		setPartialViewSpinner(spinnerId);
		ApiService.getQuestionById(+questionId)
			.then(response => {
				ParserService.parseHTMLData(response.data).then(newData => {
					const questionData = { ...newData };
					if (
						!questionData.questionAnswers ||
						(questionData.questionAnswers &&
							!questionData.questionAnswers.length)
					) {
						addAnswers();
					} else {
						setAnswers(questionData.questionAnswers);
					}
					if (
						!questionData.questionHints ||
						(questionData.questionHints && !questionData.questionHints.length)
					) {
						addHints(3);
					} else {
						setHints(questionData.questionHints);
					}
					if (questionData.isSimpleForm) {
						setTabKey("short");
					} else {
						setTabKey("long");
					}
					questionData.name && setName(questionData.name);
					questionData.text && setText(questionData.text);
					questionData.questionsGroupId &&
						setQuestionsGroupId(questionData.questionsGroupId);
					questionData.mark && setMark(questionData.mark);
					questionData.partCorrectRespFeedback &&
						setPartCorrectRespFeedback(questionData.partCorrectRespFeedback);
					questionData.penaltyForIncorrectAnswer &&
						setPenaltyForIncorrectAnswer(
							questionData.penaltyForIncorrectAnswer,
						);
					setIsShuffle(questionData.isShuffle ? true : false);
					questionData.correctRespFeedback &&
						setCorrectRespFeedback(questionData.correctRespFeedback);
					questionData.inCorrectRespFeedback &&
						setInCorrectRespFeedback(questionData.inCorrectRespFeedback);
				});

				extractPartialViewSpinner(spinnerId);
			})
			.catch(e => getFail(e, spinnerId));
	};

	const onSelectOptionChange = (event, cb) => {
		if (
			event.target.value === "" ||
			typeof +event.target.value === NUMBER_KEY
		) {
			cb(event.target.value);
		}
	};

	const getQuestionGroups = () => {
		const spinnerId = uuid();
		setPartialViewSpinner(spinnerId);
		ApiService.getQuestionsGroups()
			.then(response => {
				const questionsGroups = [...response.data];
				setQuestionsGroups(questionsGroups);
				extractPartialViewSpinner(spinnerId);
			})
			.catch(error => getFail(error, spinnerId));
	};

	const addAnswers = () => {
		const answersCopy = [...answers];
		for (let i = 1; i <= 3; i++) {
			answersCopy.push({
				text: "",
				question: "",
			});
		}
		setAnswers(answersCopy);
	};

	const addHints = hintsCount => {
		const hintsCopy = [...hints];
		for (let i = 0; i < hintsCount; i++) {
			hintsCopy.push({
				text: "",
			});
		}
		setHints(hintsCopy);
	};

	const onCheckboxChange = (event, cb) => {
		if (typeof event.target.checked === BOOLEAN_KEY) {
			cb(event.target.checked);
		}
	};

	const onNumberChange = (event, cb, maxLength) => {
		if (event.target.value.includes("e") || event.target.value.includes(".")) {
			return false;
		}
		if (
			event.target.value === "" ||
			(typeof +event.target.value === NUMBER_KEY &&
				+event.target.value >= 0 &&
				+event.target.value <= maxLength)
		) {
			cb(event.target.value);
		}
	};

	const onEditorChange = (event, cb) => {
		const newRichText = event.editor.getData();
		cb(newRichText);
	};

	const onEditorHineChange = (event, hintIndex, name) => {
		const newRichText = event.editor.getData();
		const hint = { ...hints[hintIndex] };
		hint[name] = newRichText;
		const hintsCopy = [...hints];
		hintsCopy[hintIndex] = hint;
		setHints(hintsCopy);
	};

	const onEditorAnswerChange = (event, answerIndex, name) => {
		const newRichText = event.editor.getData();
		const answer = { ...answers[answerIndex] };
		answer[name] = newRichText;
		const answersCopy = [...answers];
		answersCopy[answerIndex] = answer;
		setAnswers(answersCopy);
	};

	const onTextChange = (event, cb, maxLength = null) => {
		if (maxLength && maxLength < event.target.value.length) {
			return false;
		}
		cb(event.target.value);
	};

	const scrollToInvalidFieldPosition = () => {
		setTimeout(() => {
			let firstInvalidControl =
				document.querySelector(".select-fail") ||
				document.querySelector(".is-invalid") ||
				document.querySelector(".error-bordered") ||
				document.querySelector(".error-bordered-2") ||
				document.querySelector(".fail");
			firstInvalidControl &&
				window.scrollTo({
					top:
						firstInvalidControl?.getBoundingClientRect().top +
						document.documentElement.scrollTop -
						100,
					behavior: "smooth",
				});
		}, 300);
	};

	const onSubmit = event => {
		event && event.preventDefault();
		const filledAnswers = answers.filter(data => {
			if (data.question) {
				return (
					data.text && data.text.trim() && data.question && data.question.trim()
				);
			}
		});
		if (
			!text.trim().length ||
			!questionsGroupId ||
			!filledAnswers.length ||
			(filledAnswers.length && filledAnswers.length < 2)
		) {
			setIsInvalidSubmit(true);
			scrollToInvalidFieldPosition();
			return false;
		}
		const form = {
			name,
			text,
			mark: tabKey === "short" ? 1 : +mark,
			questionsGroupId: +questionsGroupId,
			answers: answers.filter(
				answer =>
					answer &&
					answer.text &&
					answer.question &&
					answer.text.trim().length &&
					answer.question.trim().length,
			),
			correctRespFeedback: tabKey === "short" ? "" : correctRespFeedback,
			hints:
				tabKey === "short"
					? []
					: hints.filter(hint => hint && hint.text && hint.text.trim().length),
			inCorrectRespFeedback: tabKey === "short" ? "" : inCorrectRespFeedback,
			isShuffle,
			partCorrectRespFeedback:
				tabKey === "short" ? "" : partCorrectRespFeedback,
			penaltyForIncorrectAnswer:
				tabKey === "short" ? 0 : penaltyForIncorrectAnswer,
			questionType: +questionType,
		};
		if (questionId) form.id = +questionId;
		dispatch(addButtonSpinner(buttonSpinnerId));
		(questionId
			? ApiService.questionUpdate(form)
			: ApiService.questionCreate(form)
		)
			.then(() => {
				AlertService.alert(
					SUCCESS_KEY,
					translationService.translate(
						`${questionId ? TR_UPDATE_MESSAGE_KEY : TR_CREATE_MESSAGE_KEY}`,
					),
				);
				props.goBack();
			})
			.catch(error => getFail(error))
			.finally(() => {
				dispatch(removeButtonSpinner(buttonSpinnerId));
			});
	};

	const setButtonSpinner = useCallback(spinner => {
		dispatch(addButtonSpinner(spinner));
	}, []);

	const extractButtonSpinner = useCallback(spinner => {
		dispatch(removeButtonSpinner(spinner));
	}, []);

	const setPartialViewSpinner = useCallback(spinner => {
		dispatch(addPartialViewSpinner(spinner));
	}, []);

	const extractPartialViewSpinner = useCallback(spinner => {
		dispatch(removePartialViewSpinner(spinner));
	}, []);

	const getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && extractPartialViewSpinner(spinnerId);
		spinnerId && extractButtonSpinner(spinnerId);
	};

	const filledAnswers = answers.filter(data => data.text);

	return translationService && questionType ? (
		<form onSubmit={onSubmit}>
			<div className="tab-title-group tab-content">
				<Tabs
					activeKey={tabKey}
					onSelect={key => {
						if (key !== tabKey) {
							setTabKey(key);
						}
					}}>
					<Tab
						eventKey="short"
						// title={translationService.translate("")}
						title={"Simple form"}>
						<div className="row mt-5">
							<div className="col-lg-4 col-md-6 col-12">
								<SelectOption
									value={questionsGroupId}
									name="questionsGroupId"
									label={`${translationService.translate(
										"TR_QUESTIONS_GROUP",
									)} *`}
									defaultValue=" "
									isInvalidSubmit={isInvalidSubmit}
									items={questionsGroups}
									changed={event =>
										onSelectOptionChange(event, setQuestionsGroupId)
									}
								/>
							</div>
							<div className="col-lg-4 col-md-6 col-12">
								<Input
									id="name"
									type="text"
									name="name"
									inputClassName="pr--5"
									value={name}
									fieldLength={nameMaxLength}
									labelValue={translationService.translate("TR_NAME")}
									onChange={event =>
										onTextChange(event, setName, nameMaxLength)
									}
								/>
							</div>
							<div className="col-lg-4 col-md-6 col-12">
								<Input
									type="number"
									id="mark"
									name="mark"
									value={mark}
									isInvalidSubmit={isInvalidSubmit}
									labelValue={`${translationService.translate(
										"TR_DEFAULT_MARK",
									)} *`}
									onChange={event =>
										onNumberChange(event, setMark, markMaxCount)
									}
								/>
							</div>
							{typeof text === STRING_KEY && tabKey === "short" ? (
								<div className="col-12 mb-3">
									<RichTextEditor
										value={text}
										labelValue={`${translationService.translate(
											"TR_QUESTION",
										)} *`}
										isInvalidSubmit={isInvalidSubmit}
										onEditorChange={event => onEditorChange(event, setText)}
									/>
								</div>
							) : null}

							<div className="col-12">
								<hr />
							</div>
							<div className="col-12">
								<InputCheckBox
									id="isShuffle"
									name="isShuffle"
									checked={isShuffle}
									labelValue={translationService.translate("TR_SHUFFLE")}
									onChange={event => onCheckboxChange(event, setIsShuffle)}
								/>
							</div>
							<div className="col-12">
								<div className="content-sub-title d-flex align-items-center justify-content-between">
									<h3>{translationService.translate("TR_ANSWERS")}</h3>
								</div>
								{isInvalidSubmit && filledAnswers.length < 2 ? (
									<div className="mb-2">
										<small className="fail">
											{translationService
												.translate("TR_QUESTION_ANSWERS_COUNT_REQUIRED_MSG")
												.replace("{0}", "2")}
										</small>
									</div>
								) : null}
								{answers &&
									answers.map((answer, index) => {
										const { text, question } = answer;
										return (
											<div
												key={index}
												className={`p-2 mt-3 light-gray-background ${isInvalidSubmit &&
														(!text ||
															!text.trim() ||
															!question ||
															!question.trim()) &&
														index < 2
														? "is-invalid error-bordered"
														: ""
													}`}>
												<div className="col-12">
													{tabKey === "short" && (
														<RichTextEditor
															value={question}
															blockClassName="my-3"
															labelValue={`${translationService.translate(
																"TR_QUESTION",
															)} *`}
															onEditorChange={event =>
																onEditorAnswerChange(event, index, "question")
															}
														/>
													)}
												</div>
												<div className="col-12">
													{tabKey === "short" && (
														<RichTextEditor
															value={text}
															blockClassName="my-3"
															labelValue={`${translationService.translate(
																"TR_ANSWER",
															)} *`}
															onEditorChange={event =>
																onEditorAnswerChange(event, index, "text")
															}
														/>
													)}
												</div>
											</div>
										);
									})}

								<div className="text-right mt-4">
									<button
										type="button"
										className="mindalay--btn-secondary mindalay--btn-small"
										onClick={addAnswers}>
										<i className="fas fa-plus"></i>
										{translationService.translate("TR_ADD_ANSWERS")} (3)
									</button>
								</div>
							</div>
							<div className="col-12 mt-4 text-right">
								<SubmitButton id={questionId} spinnerId={buttonSpinnerId} />
							</div>
						</div>
					</Tab>
					<Tab
						eventKey="long"
						title={"Advanced form"}
					// title={translationService.translate("TR_GENERAL_INFO")}
					>
						<div className="row mt-5">
							<div className="col-lg-4 col-md-6 col-12">
								<SelectOption
									value={questionsGroupId}
									name="questionsGroupId"
									label={`${translationService.translate(
										"TR_QUESTIONS_GROUP",
									)} *`}
									defaultValue=" "
									isInvalidSubmit={isInvalidSubmit}
									items={questionsGroups}
									changed={event =>
										onSelectOptionChange(event, setQuestionsGroupId)
									}
								/>
							</div>
							<div className="col-lg-4 col-md-6 col-12">
								<Input
									id="name"
									type="text"
									name="name"
									inputClassName="pr--5"
									value={name}
									fieldLength={nameMaxLength}
									labelValue={translationService.translate("TR_NAME")}
									onChange={event =>
										onTextChange(event, setName, nameMaxLength)
									}
								/>
							</div>
							<div className="col-lg-4 col-md-6 col-12">
								<Input
									type="number"
									id="mark"
									name="mark"
									value={mark}
									isInvalidSubmit={isInvalidSubmit}
									labelValue={`${translationService.translate(
										"TR_DEFAULT_MARK",
									)} *`}
									onChange={event =>
										onNumberChange(event, setMark, markMaxCount)
									}
								/>
							</div>
							{typeof text === STRING_KEY && tabKey === "long" ? (
								<div className="col-12 mb-3">
									<RichTextEditor
										value={text}
										labelValue={`${translationService.translate(
											"TR_QUESTION",
										)} *`}
										isInvalidSubmit={isInvalidSubmit}
										onEditorChange={event => onEditorChange(event, setText)}
									/>
								</div>
							) : null}

							<div className="col-12">
								<hr />
							</div>
							<div className="col-12">
								<InputCheckBox
									id="isShuffle"
									name="isShuffle"
									checked={isShuffle}
									labelValue={translationService.translate("TR_SHUFFLE")}
									onChange={event => onCheckboxChange(event, setIsShuffle)}
								/>
							</div>
							<div className="col-12">
								<div className="content-sub-title d-flex align-items-center justify-content-between">
									<h3>{translationService.translate("TR_ANSWERS")}</h3>
								</div>
								{isInvalidSubmit && filledAnswers.length < 2 ? (
									<div className="mb-2">
										<small className="fail">
											{translationService
												.translate("TR_QUESTION_ANSWERS_COUNT_REQUIRED_MSG")
												.replace("{0}", "2")}
										</small>
									</div>
								) : null}
								{answers &&
									answers.map((answer, index) => {
										const { text, question } = answer;
										return (
											<div
												key={index}
												className={`p-2 mt-3 light-gray-background ${isInvalidSubmit &&
														(!text ||
															!text.trim() ||
															!question ||
															!question.trim()) &&
														index < 2
														? "is-invalid error-bordered"
														: ""
													}`}>
												<div className="col-12">
													{tabKey === "long" && (
														<RichTextEditor
															value={question}
															blockClassName="my-3"
															labelValue={`${translationService.translate(
																"TR_QUESTION",
															)} *`}
															onEditorChange={event =>
																onEditorAnswerChange(event, index, "question")
															}
														/>
													)}
												</div>
												<div className="col-12">
													{tabKey === "long" && (
														<RichTextEditor
															value={text}
															blockClassName="my-3"
															labelValue={`${translationService.translate(
																"TR_ANSWER",
															)} *`}
															onEditorChange={event =>
																onEditorAnswerChange(event, index, "text")
															}
														/>
													)}
												</div>
											</div>
										);
									})}

								<div className="text-right mt-4">
									<button
										type="button"
										className="mindalay--btn-secondary mindalay--btn-small"
										onClick={addAnswers}>
										<i className="fas fa-plus"></i>
										{translationService.translate("TR_ADD_ANSWERS")} (3)
									</button>
								</div>
							</div>
							<div className="col-12">
								<hr />
							</div>
							<div className="col-lg-6 col-md-6 col-12">
								<SelectOption
									returnItem={true}
									hasPercentSign={true}
									name="penaltyForIncorrectAnswer"
									value={penaltyForIncorrectAnswer}
									label={translationService.translate("TR_PENALTY_PERCENT")}
									items={grades}
									changed={event =>
										onSelectOptionChange(event, setPenaltyForIncorrectAnswer)
									}
								/>
							</div>
							<div className="col-12">
								<div className="content-sub-title d-flex align-items-center justify-content-between">
									<h3>{translationService.translate("TR_HINTS")}</h3>
								</div>
							</div>
							<div className="col-12">
								<div className="row">
									{hints &&
										hints.map((hint, index) => {
											if (!hint || (hint && hint.text === undefined)) {
												return false;
											}
											const hintText = hint.text;
											return (
												<div key={index} className="col-12">
													{tabKey === "long" && (
														<RichTextEditor
															value={hintText}
															blockClassName="my-2"
															labelValue={`${translationService.translate(
																"TR_HINT",
															)} ${index + 1}`}
															onEditorChange={event =>
																onEditorHineChange(event, index, "text")
															}
														/>
													)}
												</div>
											);
										})}
								</div>
							</div>

							<div className="col-12">
								<div className="text-right mt-2">
									<button
										type="button"
										className="mindalay--btn-secondary mindalay--btn-small"
										onClick={() => addHints(1)}>
										<i className="fas fa-plus"></i>
										{translationService.translate("TR_ADD_HINT")}
									</button>
								</div>
							</div>
							<div className="col-12">
								<hr />
							</div>
							<div className="col-12">
								<div className="content-sub-title d-flex align-items-center justify-content-between">
									<h3>
										{translationService.translate("TR_COMBINED_FEEDBACK")}
									</h3>
								</div>
							</div>
							{typeof correctRespFeedback === STRING_KEY &&
								tabKey === "long" ? (
								<div className="col-12">
									<RichTextEditor
										value={correctRespFeedback}
										blockClassName="my-3"
										labelValue={translationService.translate(
											"TR_CORRECT_RESP_FEEDBACK",
										)}
										onEditorChange={event =>
											onEditorChange(event, setCorrectRespFeedback)
										}
									/>
								</div>
							) : null}
							{typeof partCorrectRespFeedback === STRING_KEY &&
								tabKey === "long" ? (
								<div className="col-12">
									<RichTextEditor
										value={partCorrectRespFeedback}
										blockClassName="my-3"
										labelValue={translationService.translate(
											"TR_PART_CORRECT_RESP_FEEDBACK",
										)}
										onEditorChange={event =>
											onEditorChange(event, setPartCorrectRespFeedback)
										}
									/>
								</div>
							) : null}
							{typeof inCorrectRespFeedback === STRING_KEY &&
								tabKey === "long" ? (
								<div className="col-12">
									<RichTextEditor
										value={inCorrectRespFeedback}
										blockClassName="my-3"
										labelValue={translationService.translate(
											"TR_INCORRECT_RESP_FEEDBACK",
										)}
										onEditorChange={event =>
											onEditorChange(event, setInCorrectRespFeedback)
										}
									/>
								</div>
							) : null}
							<div className="col-12 mt-4 text-right">
								<SubmitButton id={questionId} spinnerId={buttonSpinnerId} />
							</div>
						</div>
					</Tab>
				</Tabs>
			</div>
		</form>
	) : null;
};

export default withRouter(MatchingAnswer);
