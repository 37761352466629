import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import {
	BALANCE_LOGS_KEY,
	LOGS_KEY,
	MINDALAY_ADMIN,
	PAYMENT_LOGS_KEY,
} from "../../../../Constants/urlKeys";
import TranslationService from "../../../../Services/translationService";
import { showOrHideGeneralDashboardLeftMenu } from "../../../../Store/Actions/generalLeftMenu";

const OrganizationsSubMenu = props => {
	const dispatch = useDispatch();
	const language = useSelector(state => state.language.language);
	const translations = useSelector(state => state.translation.translations);
	const [translationService, setTranslationService] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const showOrHideLeftMenu = bool => {
		dispatch(showOrHideGeneralDashboardLeftMenu(bool));
	};

	return translationService ? (
		<aside className="side-left-submenu">
			<div className="side-left-submenu-container">
				<ul className="side-left-submenu-list">
					<li className="side-left-submenu-item">
						<NavLink
							to={`/${language}/mindalay-admin/organizations`}
							className={
								props.location.pathname.includes(`/organization`)
									? "active-text"
									: ""
							}
							onClick={() => showOrHideLeftMenu(false)}>
							{translationService.translate("TR_ORGANIZATIONS")}
						</NavLink>
					</li>
				</ul>
			</div>
		</aside>
	) : null;
};

export default withRouter(OrganizationsSubMenu);
