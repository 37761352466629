import React from "react";
import { withRouter } from "react-router-dom";
import Auxiliary from "../../hoc/auxiliary/auxiliary";
import AdminFooter from "../LayoutSections/Footer/adminFooter";
import AdminHeader from "../LayoutSections/Header/adminHeader";

import MetDepartmentLeftMenu from "../LayoutSections/LeftMenu/MetDepartmentLeftMenu";
import MetDepartmentRouet from "../../Routing/metDepartmentRouet";
import OrganizationUserHeader from "../LayoutSections/Header/organizationUserHeader";

const MetDepartmentLayout = props => {
	return (
		<Auxiliary>
			<OrganizationUserHeader />
			{props.location.pathname.includes("/help") ? null : (
				<MetDepartmentLeftMenu />
			)}
			<section
				className={`${
					props.location.pathname.includes("/help")
						? ""
						: "instructior-dashboard-body--large"
				} `}>
				<div className="top-section wrapper pb-5">
					<MetDepartmentRouet />
				</div>
				<AdminFooter />
			</section>
		</Auxiliary>
	);
};

export default withRouter(MetDepartmentLayout);
