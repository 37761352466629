import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route, withRouter } from "react-router-dom";
import BaseLayout from "./Layouts/BaseLayout/baseLayout";
import UserLayout from "./Layouts/UserLayout/userLayout";
import InstructorDashboardLayout from "./Layouts/InstructorDashboardLayout/instructorDashboardLayout";
import CourseDashboardLayout from "./Layouts/CourseDashboardLayout/courseDashboardLayout";
import PureLayout from "./Layouts/PureLayout/pureLayout";
import Auxiliary from "./hoc/auxiliary/auxiliary";
import PassCourseLayout from "./Layouts/PassCourseLayout/passCourseLayout";
import ConsultantDashboardLayout from "./Layouts/ConsultantDashboardLayout/consultantDashboardLayout";
import OrganizationUserLayout from "./Layouts/OrganizationUserLayout/organizationUserLayout";
import OrganizationAdminLayout from "./Layouts/OrganizationAdminLayout/organizationAdminLayout";
import AdminLayout from "./Layouts/AdminLayout/adminLayout";
import ContentManagerLayout from "./Layouts/ContentManagerLayout/contentManagerLayout";
import UnderMaintenancePage from "./Pages/UnderMaintenancePage/underMaintenancePage";
import MainService from "./Services/mainService";
import SpecialDomainLayout from "./Layouts/SpecialDomainLayout/specialDomainLayout";
import {
	ALL_CONSULTATIONS_KEY,
	BECOME_CONSULTANT_KEY,
	CHECKOUT_KEY,
	CONSULTANT_FEED_KEY,
	CONSULTANT_KEY,
	COURSE_DASHBOARD_KEY,
	INSTRUCTOR_KEY,
	LOGIN_KEY,
	NOTIFICATIONS_KEY,
	PASS_COURSE_KEY,
	PROFILE_KEY,
	PURE_KEY,
	QUIZ_ATTEMPT_KEY,
	STUDENT_CONSULTATION_DASHBOARD_KEY,
	CREATE_ONE_TIME_WEBINAR_KEY,
	HELP_KEY,
	VIDEO_CONFERENCE_ROOM_KEY,
} from "./Constants/urlKeys";
import {
	INSTRUCTOR_USER_TYPE,
	DEFAULT_LANGUAGE_KEY,
	CONSULTANT_USER_TYPE,
	RETURN_URL_KEY,
	ADMIN_USER_TYPE,
	CONTENT_MANAGER_USER_TYPE,
	USER_KEY,
	DEVELOPER_USER_TYPE,
	MET_DEPARTMENT_USER_TYPE,
	ROOT_USER_TYPE,
} from "./Constants/mainKeys";
import DeveloperLayout from "./Layouts/DeveloperLayout/developerLayout";
import RedirectComponent from "./Components/Redirect/redirect";
import MetDepartmentLayout from "./Layouts/MetDepartmentLayout/metDepartmentLayout";

const Routing = props => {
	const { location } = props;
	const { language } = useSelector(state => state.language);
	const { user, availablePages } = useSelector(state => state.user);

	const isShowOrganizationUserLayout =
		(user &&
			user.isOrganizationUser &&
			!user.isAdmin &&
			user.userTypeId !== ADMIN_USER_TYPE &&
			user.userTypeId !== MET_DEPARTMENT_USER_TYPE &&
			user.userTypeId !== DEVELOPER_USER_TYPE &&
			!location.pathname.includes(`/${PASS_COURSE_KEY}`) &&
			!location.pathname.includes(`/${QUIZ_ATTEMPT_KEY}`)) ||
		(user &&
			user.isOrganizationUser &&
			user.isAdmin &&
			availablePages.length &&
			user.userTypeId !== ADMIN_USER_TYPE &&
			user.userTypeId !== MET_DEPARTMENT_USER_TYPE &&
			user.userTypeId !== DEVELOPER_USER_TYPE)
			? true
			: false;

	const isShowDeveloperUserLayout =
		user && user.userTypeId === DEVELOPER_USER_TYPE;

	const isShowMetDepartmentUserLayout =
		user && user.userTypeId === MET_DEPARTMENT_USER_TYPE;

	const isShowOrganizationAdminLayout =
		user &&
		user.isOrganizationUser &&
		user.isAdmin &&
		user.userTypeId === ADMIN_USER_TYPE;

	// location.pathname.includes(`${language}/developer`);
	const isShowInstructorLayout =
		location.pathname.includes(`${language}/${INSTRUCTOR_KEY}`) &&
		user &&
		(user.userTypeId === INSTRUCTOR_USER_TYPE ||
			user.userTypeId === CONSULTANT_USER_TYPE);

	const isShowCourseDashboardLayout =
		location.pathname.includes(`${language}/${COURSE_DASHBOARD_KEY}`) &&
		user &&
		(user.userTypeId === INSTRUCTOR_USER_TYPE ||
			user.userTypeId === CONSULTANT_USER_TYPE);

	const isShowPureLayout =
		location.pathname.includes(`${language}/${PURE_KEY}`) && user;

	const isShowPassCourseLayout =
		location.pathname.includes(`${language}/${PASS_COURSE_KEY}`) && user;

	const isShowConsultantLayout =
		location.pathname.includes(`${language}/${CONSULTANT_KEY}/`) &&
		user &&
		user.userTypeId === CONSULTANT_USER_TYPE;

	const isShowAdminLayout =
		user && user.userTypeId === ROOT_USER_TYPE && !user.isOrganizationUser;

	const isShowContentManagerLayout =
		user && user.userTypeId === CONTENT_MANAGER_USER_TYPE;

	const isShowBaseLayout =
		!isShowInstructorLayout &&
		!isShowCourseDashboardLayout &&
		!isShowPureLayout &&
		!isShowPassCourseLayout &&
		!isShowConsultantLayout &&
		!isShowAdminLayout &&
		!isShowOrganizationUserLayout &&
		!isShowOrganizationAdminLayout &&
		!isShowContentManagerLayout &&
		!isShowDeveloperUserLayout &&
		!isShowMetDepartmentUserLayout &&
		user;

	if (
		!user &&
		language &&
		((location.pathname.includes(CONSULTANT_KEY) &&
			!location.pathname.includes(BECOME_CONSULTANT_KEY) &&
			!location.pathname.includes(RETURN_URL_KEY) &&
			!location.pathname.includes(HELP_KEY)) ||
			(location.pathname.includes(INSTRUCTOR_KEY) &&
				!location.pathname.includes(RETURN_URL_KEY) &&
				!location.pathname.includes(HELP_KEY)) ||
			(location.pathname.includes(PROFILE_KEY) &&
				!location.pathname.includes(`${PROFILE_KEY}/`)) ||
			location.pathname.includes(PASS_COURSE_KEY) ||
			location.pathname.includes(ALL_CONSULTATIONS_KEY) ||
			(location.pathname.includes(CREATE_ONE_TIME_WEBINAR_KEY) &&
				!location.pathname.includes(VIDEO_CONFERENCE_ROOM_KEY) &&
				!location.pathname.includes(RETURN_URL_KEY) &&
				!location.pathname.includes(HELP_KEY)) ||
			location.pathname.includes(STUDENT_CONSULTATION_DASHBOARD_KEY) ||
			location.pathname.includes(CONSULTANT_FEED_KEY) ||
			location.pathname.includes(NOTIFICATIONS_KEY) ||
			location.pathname.includes(CHECKOUT_KEY))
	) {
		return <Redirect to={`/${language}/${LOGIN_KEY}`} />;
	}

	return (
		<Auxiliary>
			<Route path={`/${language ? language : DEFAULT_LANGUAGE_KEY}`}>
				{!window.location.href.includes("under-maintenance") ? (
					localStorage.getItem(USER_KEY) ? (
						<Auxiliary>
							{isShowConsultantLayout ? <ConsultantDashboardLayout /> : null}
							{isShowInstructorLayout ? <InstructorDashboardLayout /> : null}
							{isShowCourseDashboardLayout ? <CourseDashboardLayout /> : null}
							{isShowPureLayout ? <PureLayout /> : null}
							{isShowPassCourseLayout ? <PassCourseLayout /> : null}
							{isShowAdminLayout ? <AdminLayout /> : null}
							{isShowOrganizationUserLayout ? <OrganizationUserLayout /> : null}
							{isShowOrganizationAdminLayout ? (
								<OrganizationAdminLayout />
							) : null}
							{isShowBaseLayout ? <BaseLayout /> : null}
							{isShowContentManagerLayout ? <ContentManagerLayout /> : null}
							{isShowDeveloperUserLayout ? <DeveloperLayout /> : null}
							{isShowMetDepartmentUserLayout ? <MetDepartmentLayout /> : null}
						</Auxiliary>
					) : MainService.isShowOnlyB2B() ? (
						<SpecialDomainLayout />
					) : (
						<UserLayout />
					)
				) : null}
			</Route>
			<Route path="/" exact>
				<Redirect to={`/${language ? language : DEFAULT_LANGUAGE_KEY}`} />
			</Route>
			<Route
				path="/:language/under-maintenance"
				exact
				component={UnderMaintenancePage}
			/>
			{/* <Route path="*" component={RedirectComponent} />
			<Redirect to={`/${language ? language : DEFAULT_LANGUAGE_KEY}`} /> */}
		</Auxiliary>
	);
};
export default withRouter(Routing);
