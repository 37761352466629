import React from 'react'
import Parser from "html-react-parser";

export default function MarqueeComponent({ text }) {
  return (
    <div className="border py-3" style={{ overflow: "hidden" }}>
      <div className="marquee">{Parser(text)}</div>
    </div>
  )
}
