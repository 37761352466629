import React, { Component } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import uuid from "react-uuid";
import UploadButton from "../../Components/UploadButton/uploadButton";
import MainService from "../../Services/mainService";
import AlertService from "../../Services/alertService";
import ApiService from "../../Services/apiService";
import TranslationService from "../../Services/translationService";
import Textarea from "../../Components/Inputs/textArea";
import SelectOption from "../../Components/SelectOption/selectOption";
import ModalComponent from "../../Components/ModalComponent/modalComponent";
import Input from "../../Components/Inputs/input";
import CloseSvg from "../../Components/Svg/closeSvg";
import {
	addButtonSpinner,
	removeButtonSpinner,
	addPageSpinner,
	removePageSpinner,
	addModalSpinner,
	removeModalSpinner,
} from "../../Store/Actions/spinner";
import {
	TR_CREATE_MESSAGE_KEY,
	TR_UPDATE_MESSAGE_KEY,
} from "../../Constants/translationKeys";
import RichTextEditor from "./../Questions/Components/RichTextEditor";
import {
	BOOLEAN_KEY,
	ERROR_KEY,
	ID_KEY,
	NUMBER_KEY,
	SUCCESS_KEY,
	VISITIBILTY_DATA,
	COMPLETION_DATA,
	STRING_KEY,
} from "../../Constants/mainKeys";
import ActionButton from "../../Components/ActionButton/actionButton";
import AddFromLibraryModal from "./Modals/addFromLibraryModal";
import AddFileLibraryModal from "./Modals/addFileLibraryModal";
import Auxiliary from "./../../hoc/auxiliary/auxiliary";
import ReactPlayer from "react-player";
import ParserService from "../../Services/parserService";

class TopicForm extends Component {
	constructor(props) {
		super(props);
		this.video_link = React.createRef();
		this.state = {
			form: {
				courseId: +this.props.courseId,
				courseSectionId: +this.props.sectionId,
				name: "",
				description: null,
				topicImage: "",
				presentationFile: "",
				visibilityRule: 0,
				passRule: 0,
				toBeCompletedTopicId: 0,
				toBeCompletedQuizId: 0,
				toBeCompletedAssignmentId: 0,
				videoDuration: null,
				imageFileId: null,
				videoFileId: null,
				videoLink: "",
			},
			isInvalidSubmit: false,
			spinnerId: uuid(),
			translationService: null,
			failedFields: null,
			currentUploadedImage: null,
			currentUploadedVideo: null,
			topicQuizzes: [],
			quizzesAssignments: [],
			isDisabledAddButton: false,
			isInvalidCompletion: false,
			nameMaxLength: 200,
			descriptionMaxLength: 20000,
			showSelectFromFileLibraryModal: false,
			showAddNewFileLibraryModal: false,
			fileType: null,
			selectedImageFromLibrary: null,
			selectedVideoFromLibrary: null,
			currentTopicVideo: null,
			currentTopicImage: null,
			isYouTubeLink: false,
			zoomImagePath: null,
			zoomAudioPath: null,
			zoomVideoPath: null,
		};
	}
	mainService = new MainService();

	componentDidMount() {
		this.setTranslations();
		!this.props.show && this.clearFormData();
		if (this.props.topic) {
			this.setTopicData(this.props.topic);
			this.getQuizzesAssignment(this.props.topic.id);
			this.getQuizzesByTopicId(this.props.topic.id);
		} else {
			this.setState(prevState => ({
				...prevState,
				form: {
					...prevState.form,
					description: "",
				},
			}));
		}
	}

	shouldComponentUpdate(nextProps, nextState) {
		if (
			nextProps.translations &&
			JSON.stringify(nextProps.translations) !==
				JSON.stringify(this.props.translations)
		) {
			this.setState({
				translationService: new TranslationService(nextProps.translations),
			});
		}
		if (
			!nextProps.show &&
			this.props.show &&
			nextProps.topic &&
			this.props.topic
		) {
			this.clearFormData();
		}
		return true;
	}

	setTranslations = () => {
		if (!this.state.translationService && this.props.translations) {
			this.setState({
				translationService: new TranslationService(this.props.translations),
			});
		}
	};

	setTopicData = topic => {
		if (!topic) {
			return;
		}
		const topicData = topic;
		if (topic.toBeCompletedQuizId) {
			this.getQuizzesByTopicId(topicData.id);
		}
		this.setState(prevState => ({
			...prevState,
			currentTopicVideo: topicData.presentationFilePath
				? topicData.presentationFilePath
				: null,
			currentTopicImage: topicData.imagePath
				? // ? `${topicData.imagePath}?${uuid()}`
				  `${topicData.imagePath}`
				: null,

			form: {
				...prevState.form,
				toBeCompletedTopicId: topicData.toBeCompletedTopicId || 0,
				toBeCompletedQuizId: topicData.toBeCompletedQuizId || 0,
				toBeCompletedAssignmentId: topicData.toBeCompletedAssignmentId || 0,
				name: topicData.name || "",
				// description: topicData.description || "",
				topicImage: topicData.topicImage || "",
				presentationFile: topicData.presentationFile || "",
				visibilityRule: topicData.visibilityRule || 0,
				passRule: topicData.passRule || 0,
				videoLink: topicData.videoLink || "",
			},
		}));
		if (topicData.description) {
			ParserService.processHTML(topicData.description).then(processedHtml => {
				this.setState(prevState => ({
					...prevState,
					form: {
						...prevState.form,
						description: processedHtml,
					},
				}));
			});
		} else {
			this.setState(prevState => ({
				...prevState,
				form: {
					...prevState.form,
					description: "",
				},
			}));
		}
	};

	onChange = (event, maxLength = null) => {
		const { gracePeriodEnabled } = this.state.form;
		const failedFields = this.removeFailedFields(event.target.name);
		if (maxLength && maxLength < event.target.value.length) {
			return;
		}
		this.setState(prevState => ({
			...prevState,
			failedFields,
			isInvalidSubmit: false,
			form: {
				...prevState.form,
				[event.target.name]: event.target.value,
				gracePeriodEnabled: !gracePeriodEnabled,
			},
		}));
	};

	onEditorChange = event => {
		const { descriptionMaxLength } = this.state;
		const newRichText = event.editor.getData();
		if (newRichText.length > descriptionMaxLength) {
			this.setState({ descriptionMaxLengthError: true });
		} else this.setState({ descriptionMaxLengthError: false });
		this.setState(prevState => ({
			...prevState,
			form: {
				...prevState.form,
				description: newRichText,
			},
		}));
	};

	onNumberChange = (event, fieldName) => {
		const failedFields = this.removeFailedFields(event.target.name);
		if (
			event.target.value === "" ||
			(typeof +event.target.value === NUMBER_KEY &&
				Number(event.target.value) >= 0)
		) {
			if (event.target.name === "toBeCompletedAssignmentId") {
				this.setState(prevState => ({
					...prevState,
					failedFields,
					form: {
						...prevState.form,
						toBeCompletedQuizId: null,
						[event.target.name]: +event.target.value,
					},
				}));
			} else if (event.target.name === "toBeCompletedQuizId") {
				this.setState(prevState => ({
					...prevState,
					failedFields,
					form: {
						...prevState.form,
						[event.target.name]: +event.target.value,
						toBeCompletedAssignmentId: null,
					},
				}));
			} else {
				this.setState(prevState => ({
					...prevState,
					failedFields,
					form: {
						...prevState.form,
						[event.target.name]: +event.target.value,
						toBeCompletedAssignmentId: null,
					},
				}));
			}
		}
		if (+event.target.value === 0 || !event.target.value) {
			//Autocomplete
			this.setState({ isInvalidCompletion: false, isDisabledAddButton: false });
		}
		if (
			fieldName === "passRule" &&
			+event.target.value === 1 &&
			!this.state.topicQuizzes.length
		) {
			//Pass quiz
			this.setState({ isInvalidCompletion: true, isDisabledAddButton: true });
		}
		if (
			fieldName === "passRule" &&
			+event.target.value === 3 &&
			!this.state.quizzesAssignments.length
		) {
			this.setState({ isInvalidCompletion: true, isDisabledAddButton: true });
		}
		if (
			fieldName === "passRule" &&
			+event.target.value === 2 &&
			!this.state.selectedVideoFromLibrary &&
			!this.state.currentTopicVideo
		) {
			//Pass video
			this.setState({ isInvalidCompletion: true, isDisabledAddButton: true });
		}
		if (
			fieldName === "passRule" &&
			+event.target.value === 2 &&
			(this.state.selectedVideoFromLibrary || this.state.currentTopicVideo)
		) {
			//Pass video
			this.setState({ isInvalidCompletion: false, isDisabledAddButton: false });
		}
		if (
			fieldName === "passRule" &&
			+event.target.value === 1 &&
			this.state.topicQuizzes.length
		) {
			//Pass quiz
			this.setState(prevState => ({
				...prevState,
				isInvalidCompletion: false,
				form: {
					...prevState.form,
					toBeCompletedQuizId: this.state.topicQuizzes[0].id,
				},
			}));
		}
		if (
			fieldName === "passRule" &&
			+event.target.value === 3 &&
			this.state.quizzesAssignments.length
		) {
			//Pass quiz
			this.setState(prevState => ({
				...prevState,
				isInvalidCompletion: false,
				form: {
					...prevState.form,
					toBeCompletedAssignmentId: this.state.quizzesAssignments[0].id,
				},
			}));
		}

		if (
			fieldName === "visibilityRule" &&
			this.props.topics &&
			this.props.topics.length
		) {
			this.setState(prevState => ({
				...prevState,
				isInvalidCompletion: false,
				form: {
					...prevState.form,
					toBeCompletedTopicId: this.props.topics[0].id,
				},
			}));
		}
	};

	onCheckboxChange = event => {
		typeof event.target.checked === BOOLEAN_KEY &&
			this.setState(prevState => ({
				...prevState,
				form: {
					...prevState.form,
					[event.target.name]: event.target.checked,
				},
			}));
	};

	clearFormData = () => {
		this.setState(prevState => ({
			...prevState,
			isInvalidSubmit: false,
			uploadedImage: null,
			uploadedVideo: null,
			form: {
				...prevState.form,
				courseId: +this.props.courseId,
				courseSectionId: +this.props.sectionId,
				name: "",
				description: "",
				topicImage: "",
				presentationFile: "",
				visibilityRule: 0,
				passRule: 0,
			},
		}));
	};

	removeFailedFields = name => {
		const failedFields = this.state.failedFields && {
			...this.state.failedFields,
		};
		const fieldName = name.replace(name[0], name[0].toUpperCase());
		failedFields && delete failedFields[fieldName];
		return failedFields;
	};

	closeModal = () => {
		this.setState({
			showAddNewFileLibraryModal: false,
			showSelectFromFileLibraryModal: false,
		});
	};

	isShowAddNewFileLibraryModal = () => {
		this.setState({ showAddNewFileLibraryModal: true });
	};

	attachFileLibrary = file => {
		const { fileType } = this.state;
		if (!fileType || !file) {
			return false;
		}
		if (fileType === "image") {
			this.setState(prevState => ({
				...prevState,
				showAddNewFileLibraryModal: false,
				showSelectFromFileLibraryModal: false,
				selectedImageFromLibrary: file,
				form: {
					...prevState.form,
					imageFileId: file.id,
				},
			}));
		}
		if (fileType === "video") {
			this.setState(
				prevState => ({
					...prevState,
					showAddNewFileLibraryModal: false,
					showSelectFromFileLibraryModal: false,
					selectedVideoFromLibrary: file,
					currentUploadedVideo: file,
					form: {
						...prevState.form,
						videoFileId: file.id,
						videoLink: "",
					},
				}),
				() => {
					this.getVideoDuration();
				},
			);
			if (this.state.form.passRule === 2) {
				this.setState({
					isInvalidCompletion: false,
					isDisabledAddButton: false,
				});
			}
		}
	};

	getVideoDuration = () => {
		var video = document.getElementById("video");
		video.onloadedmetadata = function () {
			getDuration(Math.floor(this.duration));
		};
		var getDuration = duration => {
			this.setState(prevState => ({
				...prevState,
				form: {
					...prevState.form,
					videoDuration: duration,
				},
			}));
		};
	};

	removeImg = () => {
		this.setState(prevState => ({
			...prevState,
			selectedImageFromLibrary: null,
			form: {
				...prevState.form,
				imageFileId: null,
			},
		}));
	};

	removeVideo = () => {
		this.setState(
			prevState => ({
				...prevState,
				selectedVideoFromLibrary: null,
				isYouTubeLink: false,
				form: {
					...prevState.form,
					videoFileId: null,
					videoLink: "",
				},
			}),
			() => {
				if (
					this.state.form.passRule === 2 &&
					!this.state.selectedVideoFromLibrary &&
					!this.state.currentTopicVideo
				) {
					this.setState({
						isInvalidCompletion: true,
						isDisabledAddButton: true,
					});
				}
			},
		);
	};

	getQuizzesByTopicId = topicId => {
		ApiService.getQuizzesByTopicId(topicId)
			.then(response => {
				this.setState({ topicQuizzes: response.data });
			})
			.catch(error => this.getFail(error));
	};

	onSubmit = event => {
		event && event.preventDefault();
		const { topic, addButtonSpinner, submitted, cancel, user, buttonSpinners } =
			this.props;
		const {
			translationService,
			spinnerId,
			isInvalidCompletion,
			isInvalidSubmit,
		} = this.state;
		if (buttonSpinners.length) {
			return false;
		}
		const form = { ...this.state.form };
		form.visibilityRule =
			form.visibilityRule && form.toBeCompletedTopicId
				? form.visibilityRule
				: 0;
		form.toBeCompletedTopicId = form.visibilityRule
			? form.toBeCompletedTopicId
			: 0;
		form.toBeCompletedQuizId =
			form.passRule === 1 ? form.toBeCompletedQuizId : null;

		form.description = form.description ? form.description.trim() : "";
		form.name = form.name ? form.name.trim() : "";
		// form.videoDuration = form.presentationFile ? form.videoDuration : null;
		const { name, courseId, courseSectionId } = form;
		if (
			name &&
			courseId &&
			courseSectionId &&
			!isInvalidCompletion &&
			!isInvalidSubmit
		) {
			this.setState({ isDisabledAddButton: true });
			addButtonSpinner(spinnerId);
			const formData = new FormData();
			for (let i in form) {
				!form[i] && i !== "passRule" && i !== "visibilityRule"
					? delete form[i]
					: formData.append(`${i}`, form[i]);
				if (form[i] === null) delete form[i];
			}
			topic && topic.id && formData.append(ID_KEY, +topic.id);
			if (user.isOrganizationUser) {
				(topic
					? ApiService.topicUpdateByOrgUser(formData)
					: ApiService.topicCreateByOrgUser(formData)
				)
					.then(() => {
						AlertService.alert(
							SUCCESS_KEY,
							translationService.translate(
								`${topic ? TR_UPDATE_MESSAGE_KEY : TR_CREATE_MESSAGE_KEY}`,
							),
						);
						this.setState({ isDisabledAddButton: false });
						this.props.removeButtonSpinner(spinnerId);
						submitted();
						cancel && cancel();
					})
					.catch(error => this.getFail(error, spinnerId));
			} else {
				(topic
					? ApiService.topicUpdate(formData)
					: ApiService.topicCreate(formData)
				)
					.then(() => {
						AlertService.alert(
							SUCCESS_KEY,
							translationService.translate(
								`${topic ? TR_UPDATE_MESSAGE_KEY : TR_CREATE_MESSAGE_KEY}`,
							),
						);
						this.setState({ isDisabledAddButton: false });
						this.props.removeButtonSpinner(spinnerId);
						submitted();
						cancel && cancel();
					})
					.catch(error => this.getFail(error, spinnerId));
			}
		} else {
			this.setState({ isInvalidSubmit: true });
		}
	};

	getFail = (error, spinnerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinnerId && this.props.removePageSpinner(spinnerId);
		spinnerId && this.props.removeButtonSpinner(spinnerId);
		this.setState({ isDisabledAddButton: false });
	};
	onChangeVideoLink = event => {
		this.setState(prevState => ({
			...prevState,
			isInvalidSubmit: false,
			selectedVideoFromLibrary: null,
			form: {
				...prevState.form,
				videoFileId: null,
				[event.target.name]: event.target.value,
			},
		}));
	};
	getQuizzesAssignment = topicId => {
		if (!topicId) {
			return;
		}
		ApiService.getQuizzesAssignment(topicId)
			.then(response => {
				this.setState({ quizzesAssignments: response.data });
			})
			.catch(error => this.getFail(error));
	};

	zoomImage = imagePath => {
		if (!imagePath) {
			return;
		}
		this.setState({ zoomImagePath: imagePath });
	};

	zoomVideo = videoPath => {
		if (!videoPath) {
			return;
		}
		this.setState({ zoomVideoPath: videoPath });
	};

	zoomAudio = audioPath => {
		if (!audioPath) {
			return;
		}
		this.setState({ zoomAudioPath: audioPath });
	};

	hideZoomImageVideo = () => {
		this.setState({
			zoomImagePath: null,
			zoomVideoPath: null,
			zoomAudioPath: null,
		});
	};

	render() {
		const {
			isInvalidSubmit,
			translationService,
			failedFields,
			topicQuizzes,
			quizzesAssignments,
			isDisabledAddButton,
			isInvalidCompletion,
			nameMaxLength,
			spinnerId,
			showSelectFromFileLibraryModal,
			showAddNewFileLibraryModal,
			fileType,
			selectedImageFromLibrary,
			selectedVideoFromLibrary,
			currentTopicVideo,
			currentTopicImage,
			isYouTubeLink,
			zoomImagePath,
			zoomVideoPath,
			zoomAudioPath,
		} = this.state;

		const {
			name,
			description,
			toBeCompletedTopicId,
			toBeCompletedQuizId,
			toBeCompletedAssignmentId,
			visibilityRule,
			passRule,
			videoLink,
		} = this.state.form;

		const { cancel, topic } = this.props;

		if (this.props.topics && topic) {
			var topicsWithOutcurrentTopic = this.props.topics.filter(
				topicElem => topicElem.id !== topic.id,
			);
		} else {
			topicsWithOutcurrentTopic = this.props.topics;
		}

		return translationService ? (
			<div className="row">
				<div className="col-12 d-flex align-items-center">
					<h2 className="content-title p-0">
						{translationService.translate(
							topic ? "TR_UPDATE_TOPIC" : "TR_CREATE_TOPIC",
						)}
					</h2>
					<div className="close-block">
						<div className="close-svg" onClick={cancel}>
							<CloseSvg />
						</div>
					</div>
				</div>
				{zoomImagePath ? (
					<div
						className="zoom-image-container"
						onClick={this.hideZoomImageVideo}>
						<div className="close-svg">
							<CloseSvg />
						</div>
						<img
							src={zoomImagePath}
							alt="/"
							onClick={event => event.stopPropagation()}
						/>
					</div>
				) : null}
				{zoomVideoPath ? (
					<div
						className="zoom-image-container"
						onClick={this.hideZoomImageVideo}>
						<div className="zoom-image-block">
							<div className="close-svg">
								<CloseSvg />
							</div>
							<video controls={true} onClick={event => event.stopPropagation()}>
								<source src={zoomVideoPath} />
							</video>
						</div>
					</div>
				) : null}

				<div className="col-12">
					<form onSubmit={this.onSubmit}>
						<div className="row">
							<div className="col-12">
								<div className="row">
									<div className="col-12">
										<Input
											type="text"
											id="name"
											name="name"
											inputClassName="pr--5"
											value={name}
											fieldLength={nameMaxLength}
											isInvalidSubmit={isInvalidSubmit}
											isInvalidField={isInvalidSubmit && !name.trim()}
											labelValue={`${translationService.translate("TR_NAME")}*`}
											onChange={event => this.onChange(event, nameMaxLength)}
											failedFields={failedFields}
										/>
									</div>
								</div>
								<div className="row">
									{typeof description === STRING_KEY && (
										<div className="col-12 mb-3">
											<RichTextEditor
												value={description}
												labelValue={`${translationService.translate(
													"TR_DESCRIPTION",
												)}`}
												onEditorChange={event => this.onEditorChange(event)}
											/>
										</div>
									)}
								</div>

								<div className="row">
									{topicsWithOutcurrentTopic?.length ? (
										<div className="col-sm-6 col-12 d-flex flex-column">
											<SelectOption
												name="visibilityRule"
												value={visibilityRule}
												label={translationService.translate("TR_VISIBILITY")}
												items={VISITIBILTY_DATA}
												changed={event =>
													this.onNumberChange(event, "visibilityRule")
												}
											/>
											{visibilityRule ? (
												<SelectOption
													name="toBeCompletedTopicId"
													label={translationService.translate(
														"TR_CHOOSE_TOPIC",
													)}
													// value={toBeCompletedTopicId ? toBeCompletedTopicId : topicsWithOutcurrentTopic[0].id}
													value={toBeCompletedTopicId}
													items={topicsWithOutcurrentTopic}
													defaultValue=" "
													changed={this.onNumberChange}
												/>
											) : null}
										</div>
									) : null}
									{
										<div className="col-sm-6 col-12 d-flex flex-column">
											<SelectOption
												name="passRule"
												value={passRule}
												label={translationService.translate("TR_COMPLETION")}
												items={COMPLETION_DATA}
												isInvalidField={isInvalidCompletion}
												changed={event =>
													this.onNumberChange(event, "passRule")
												}
											/>
											{passRule === 1 && topicQuizzes.length && topic ? (
												<SelectOption
													name="toBeCompletedQuizId"
													label={translationService.translate("TR_CHOOSE_QUIZ")}
													value={toBeCompletedQuizId}
													items={topicQuizzes}
													changed={this.onNumberChange}
												/>
											) : null}
											{passRule === 3 && quizzesAssignments.length ? (
												<SelectOption
													name="toBeCompletedAssignmentId"
													label={"Choose assignment"}
													value={toBeCompletedAssignmentId}
													items={quizzesAssignments}
													changed={this.onNumberChange}
												/>
											) : null}
										</div>
									}
								</div>
								{passRule === 1 && (!topicQuizzes || !topicQuizzes.length) ? (
									<small className="red-color">
										{translationService.translate(
											"TR_YOU_DONT_HAVE_ANY_CREATED_QUIZ",
										)}
									</small>
								) : null}

								{passRule === 3 && !quizzesAssignments?.length ? (
									<small className="red-color">
										{translationService.translate(
											"TR_YOU_DONT_HAVE_ANY_CREATED_ASSIGNMENT",
										)}
									</small>
								) : null}
								{passRule === 2 &&
								!selectedVideoFromLibrary &&
								!currentTopicVideo ? (
									<small className="red-color">
										{translationService.translate(
											"TR_YOU_DONT_HAVE_UPLOADED_VIDEO",
										)}
									</small>
								) : null}
								<div className="row mt-3">
									<div className="col-lg-6 col-md-6 col-12">
										<button
											type="button"
											className={`mindalay--btn-secondary w-100 mb-2 ${
												fileType === "image"
													? "active-upload-button"
													: "border-2-transparent"
											}`}
											onClick={() =>
												this.setState({
													isYouTubeLink: false,
													fileType: "image",
												})
											}>
											{translationService.translate("TR_ATTACH_IMAGE")}
										</button>
									</div>
									<div className="col-lg-6 col-md-6 col-12">
										<button
											type="button"
											className={`mindalay--btn-secondary w-100 mb-2 ${
												fileType === "video"
													? "active-upload-button"
													: "border-2-transparent"
											}`}
											onClick={() => this.setState({ fileType: "video" })}>
											{translationService.translate("TR_ATTACH_VIDEO")}
										</button>
									</div>
								</div>
								<Auxiliary>
									{fileType ? (
										<div
											className={`row ${
												fileType === "image"
													? "justify-content-start"
													: "justify-content-end"
											}`}>
											<div className="col-lg-6 col-md-6 col-12">
												<div>
													<button
														type="button"
														className={`mindalay--btn-default w-100 mb-2`}
														onClick={() =>
															this.setState({
																showSelectFromFileLibraryModal: true,
															})
														}>
														{translationService.translate(
															"TR_ADD_FROM_LIBRARY",
														)}
													</button>
													{showSelectFromFileLibraryModal ? (
														<AddFromLibraryModal
															fileType={fileType}
															cancel={this.closeModal}
															added={this.attachFileLibrary}
															isShowAddNewFileLibraryModal={
																this.isShowAddNewFileLibraryModal
															}
														/>
													) : null}
												</div>
												<div>
													<button
														type="button"
														className={`mindalay--btn-default w-100 mb-2`}
														onClick={() =>
															this.setState({
																showAddNewFileLibraryModal: true,
															})
														}>
														{translationService.translate("TR_ADD_NEW_FILE")}
													</button>
													{showAddNewFileLibraryModal ? (
														<AddFileLibraryModal
															fileType={fileType}
															cancel={this.closeModal}
															added={this.attachFileLibrary}
															name={name}
														/>
													) : null}
												</div>
												<div>
													{!isYouTubeLink &&
														this.state.fileType === "video" && (
															<button
																type="button"
																className={`mindalay--btn-default w-100 mb-2`}
																onClick={() => {
																	this.setState(prevState => ({
																		...prevState,
																		isYouTubeLink: true,
																	}));
																	if (this.video_link.current) {
																		// this.video_link.current.focus();
																		setTimeout(() => {
																			this.video_link.current.focus();
																		}, 300);
																	}
																}}>
																{translationService.translate(
																	"TR_ATTACH_VIDEO_LINK",
																)}
															</button>
														)}
													<Input
														blockClassName={`${
															isYouTubeLink && this.state.fileType === "video"
																? "d-block"
																: "d-none"
														}`}
														ref={this.video_link}
														type="text"
														id="youtube_video"
														name="videoLink"
														value={videoLink}
														isInvalidSubmit={isInvalidSubmit}
														inputClassName="pr--5"
														isInvalidField={
															isInvalidSubmit && videoLink.trim().length < 3
																? true
																: false
														}
														labelValue={translationService.translate(
															"TR_YOUTUBE_VIDEO_LINK",
														)}
														onChange={this.onChangeVideoLink}
														placeholder="Ex. https://www.youtube.com/watch?"
													/>
												</div>
											</div>
										</div>
									) : null}

									{/* Selected image-video block */}
									<div className="row">
										<div className="col-lg-6 col-md-6 col-sm-6 col-12">
											{selectedImageFromLibrary || currentTopicImage ? (
												<div className="course-media-wrapper">
													<div className="upload-file-content">
														{selectedImageFromLibrary ? (
															<div onClick={() => this.removeImg()}>
																<CloseSvg />
															</div>
														) : null}
														<div
															className="img cursor-pointer"
															onClick={() => {
																this.zoomImage(
																	selectedImageFromLibrary &&
																		selectedImageFromLibrary.filePath
																		? selectedImageFromLibrary.filePath
																		: currentTopicImage
																		? currentTopicImage
																		: null,
																);
															}}
															style={{
																backgroundImage: `url(${
																	selectedImageFromLibrary &&
																	selectedImageFromLibrary.filePath
																		? selectedImageFromLibrary.filePath
																		: currentTopicImage
																		? currentTopicImage
																		: null
																})`,
															}}
															alt="course-image"
														/>
													</div>
												</div>
											) : null}
										</div>
										<div className="col-lg-6 col-md-6 col-sm-6 col-12">
											{selectedVideoFromLibrary ||
											videoLink ||
											currentTopicVideo ? (
												<div className="course-media-wrapper h-100 p-1">
													<div className="upload-file-content">
														{selectedVideoFromLibrary || videoLink ? (
															<div onClick={() => this.removeVideo()}>
																<CloseSvg controls={true} />
															</div>
														) : null}
														{videoLink ? (
															<ReactPlayer
																className="course-video-link"
																url={videoLink}
																config={{
																	youtube: {
																		playerVars: { showinfo: 0, controls: 1 },
																	},
																	facebook: {
																		appId: "12345",
																	},
																}}
															/>
														) : selectedVideoFromLibrary ||
														  currentTopicVideo ? (
															<video
																id="video"
																src={
																	selectedVideoFromLibrary &&
																	selectedVideoFromLibrary.filePath
																		? selectedVideoFromLibrary.filePath
																		: currentTopicVideo
																		? currentTopicVideo
																		: null
																}
																alt="course-image"
																controls
															/>
														) : null}
													</div>
												</div>
											) : null}
										</div>

										{/* <div className="col-lg-6 col-md-6 col-sm-6 col-12">
											{selectedVideoFromLibrary || currentTopicVideo ? (
												<div className="course-media-wrapper">
													<div className="upload-file-content">
														{selectedVideoFromLibrary ? (
															<div onClick={() => this.removeVideo()}>
																<CloseSvg controls={true} />
															</div>
														) : null}
														<video
															id="video"
															src={
																selectedVideoFromLibrary &&
																selectedVideoFromLibrary.filePath
																	? selectedVideoFromLibrary.filePath
																	: currentTopicVideo
																	? currentTopicVideo
																	: null
															}
															alt="course-image"
															controls
														/>
													</div>
												</div>
											) : null}
										</div> */}
									</div>
								</Auxiliary>
							</div>
						</div>
						<div className="row mt-3">
							<div className="col-12">
								{fileType ? <hr /> : null}
								<ActionButton
									type="button"
									spinnerId={spinnerId}
									disabled={isDisabledAddButton || isInvalidCompletion}
									name={translationService.translate(
										topic ? "TR_UPDATE" : "TR_CREATE",
									)}
									className="btn mindalay--btn-default float-right mb-4 px-5"
									clicked={this.onSubmit}
								/>
							</div>
						</div>
					</form>
				</div>
			</div>
		) : null;
	}
}

const mapStateToProps = state => ({
	buttonSpinners: state.spinner.buttonSpinners,
	translations: state.translation.translations,
	language: state.language.language,
	user: state.user.user,
});

const mapDispatchToProps = {
	addButtonSpinner,
	removeButtonSpinner,
	addPageSpinner,
	removePageSpinner,
	addModalSpinner,
	removeModalSpinner,
};

export default compose(
	connect(mapStateToProps, mapDispatchToProps),
	withRouter,
)(TopicForm);
