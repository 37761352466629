import React from "react";

function HomeWorkSvg(props) {
	return (
		<svg
			fill="#000000"
			height="800px"
			width="800px"
			version="1.1"
			id="Capa_1"
			xmlns="http://www.w3.org/2000/svg"
			xmlnsXlink="http://www.w3.org/1999/xlink"
			viewBox="0 0 470 470"
			xmlSpace="preserve">
			<g>
				<path
					d="M192.465,294.786h-12.5v-25.895c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v25.895H148.31v-55.895
		c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v55.895h-16.654v-25.895c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v25.895H85
		v-55.895c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v63.395c0,4.142,3.357,7.5,7.5,7.5h114.965c4.143,0,7.5-3.358,7.5-7.5
		S196.607,294.786,192.465,294.786z"
				/>
				<path
					d="M192.465,53.251H77.5c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h114.965c4.143,0,7.5-3.358,7.5-7.5
		S196.607,53.251,192.465,53.251z"
				/>
				<path
					d="M192.465,88.879H77.5c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h114.965c4.143,0,7.5-3.358,7.5-7.5
		S196.607,88.879,192.465,88.879z"
				/>
				<path
					d="M77.5,139.507h57.482c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5H77.5c-4.143,0-7.5,3.358-7.5,7.5
		S73.357,139.507,77.5,139.507z"
				/>
				<path
					d="M192.465,160.135H77.5c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h114.965c4.143,0,7.5-3.358,7.5-7.5
		S196.607,160.135,192.465,160.135z"
				/>
				<path
					d="M192.465,195.763H77.5c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h114.965c4.143,0,7.5-3.358,7.5-7.5
		S196.607,195.763,192.465,195.763z"
				/>
				<path
					d="M235,58.675c-4.143,0-7.5,3.358-7.5,7.5v252.848c0,4.142,3.357,7.5,7.5,7.5s7.5-3.358,7.5-7.5V66.175
		C242.5,62.032,239.143,58.675,235,58.675z"
				/>
				<path
					d="M462.5,73.251H440v-52.5c0-4.142-3.357-7.5-7.5-7.5H263.732c-10.816,0-21.035,4.135-28.732,11.373
		c-7.698-7.238-17.917-11.373-28.732-11.373H37.5c-4.143,0-7.5,3.358-7.5,7.5v52.5H7.5c-4.143,0-7.5,3.358-7.5,7.5v321.535
		c0,4.142,3.357,7.5,7.5,7.5h197.337l8.396,9.793c1.425,1.662,3.505,2.618,5.694,2.618l32.152-0.003c2.189,0,4.27-0.957,5.694-2.62
		l8.389-9.789h41.087v27.463c0,10.752,8.748,19.5,19.502,19.5h16c10.751,0,19.498-8.748,19.498-19.5v-27.463H462.5
		c4.143,0,7.5-3.358,7.5-7.5V80.751C470,76.609,466.643,73.251,462.5,73.251z M346.25,126.249v252.5h-25v-252.5H346.25z
		 M321.25,393.749h25v15h-25V393.749z M325,111.249l8.751-21l8.749,21H325z M341.752,441.749h-16c-2.44,0-4.502-2.061-4.502-4.5
		v-13.5h25v13.5C346.25,439.688,344.19,441.749,341.752,441.749z M455,394.786h-93.75V118.749c0-0.25-0.013-0.499-0.038-0.747
		c-0.021-0.207-0.054-0.41-0.091-0.612c-0.007-0.038-0.01-0.077-0.018-0.115c-0.103-0.515-0.258-1.011-0.461-1.483l-19.968-47.927
		c-1.164-2.795-3.895-4.616-6.923-4.616c-3.027,0-5.759,1.82-6.923,4.615l-19.974,47.932c-0.201,0.47-0.356,0.964-0.459,1.478
		c-0.008,0.042-0.011,0.084-0.019,0.126c-0.036,0.198-0.07,0.398-0.09,0.601c-0.025,0.248-0.038,0.497-0.038,0.747v276.037h-42.518
		c-3.839,0-7.414,1.824-9.675,4.907l-6.429,7.502l-25.254,0.002l-6.433-7.503c-2.264-3.084-5.84-4.908-9.675-4.908H15V88.251h15
		v254.035c0,4.142,3.357,7.5,7.5,7.5h168.768c8.344,0,16.128,3.834,21.232,10.343v10.42c-6.34-3.718-13.637-5.763-21.232-5.763H37.5
		c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h168.768c9.057,0,17.463,4.51,22.488,12.063c0.165,0.246,0.339,0.473,0.525,0.692
		c0.049,0.058,0.102,0.112,0.153,0.168c0.139,0.154,0.282,0.302,0.433,0.444c0.067,0.063,0.134,0.124,0.203,0.184
		c0.158,0.138,0.323,0.269,0.492,0.394c0.056,0.041,0.11,0.085,0.167,0.125c0.234,0.163,0.477,0.314,0.73,0.45
		c0.248,0.133,0.505,0.248,0.767,0.353c0.062,0.025,0.126,0.046,0.189,0.069c0.205,0.075,0.412,0.142,0.625,0.199
		c0.077,0.021,0.155,0.042,0.233,0.06c0.209,0.049,0.421,0.088,0.636,0.12c0.075,0.011,0.149,0.025,0.225,0.034
		c0.285,0.033,0.574,0.054,0.868,0.054c0.297,0,0.583-0.021,0.867-0.054c0.079-0.009,0.156-0.024,0.234-0.036
		c0.211-0.031,0.42-0.07,0.626-0.118c0.081-0.019,0.16-0.04,0.24-0.062c0.209-0.057,0.414-0.123,0.615-0.197
		c0.066-0.024,0.132-0.046,0.197-0.072c0.258-0.103,0.51-0.217,0.753-0.346c0.265-0.141,0.506-0.292,0.739-0.454
		c0.06-0.041,0.116-0.087,0.175-0.131c0.167-0.123,0.328-0.251,0.484-0.387c0.07-0.061,0.139-0.124,0.207-0.188
		c0.149-0.14,0.291-0.286,0.427-0.438c0.053-0.058,0.107-0.114,0.158-0.174c0.185-0.218,0.358-0.444,0.517-0.682
		c5.029-7.561,13.435-12.071,22.494-12.071h20.018c4.143,0,7.5-3.358,7.5-7.5s-3.357-7.5-7.5-7.5h-20.018
		c-7.596,0-14.892,2.044-21.232,5.762v-10.419c5.102-6.508,12.887-10.343,21.232-10.343h20.018c4.143,0,7.5-3.358,7.5-7.5
		s-3.357-7.5-7.5-7.5h-20.018c-10.817,0-21.035,4.131-28.733,11.364c-7.698-7.232-17.916-11.364-28.731-11.364H45V28.251h161.268
		c9.056,0,17.461,4.514,22.486,12.075c1.39,2.092,3.734,3.349,6.246,3.349s4.856-1.257,6.247-3.349
		c5.023-7.561,13.43-12.074,22.485-12.074H425v306.535h-41.25c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h48.75
		c4.143,0,7.5-3.358,7.5-7.5V88.251h15V394.786z"
				/>
				<path
					d="M432.5,364.786h-48.75c-4.143,0-7.5,3.358-7.5,7.5s3.357,7.5,7.5,7.5h48.75c4.143,0,7.5-3.358,7.5-7.5
		S436.643,364.786,432.5,364.786z"
				/>
			</g>
		</svg>
	);
}

export default HomeWorkSvg;
