import React, { Component } from "react";
import { connect } from "react-redux";
import TranslationService from "../../Services/translationService";
import ButtonSpinner from "../Spinners/buttonSpinner";

class SubmitButton extends Component {
	state = {
		translationService: null,
	};

	componentDidMount() {
		this.setTranslations();
	}

	setTranslations = () => {
		if (!this.state.translationService && this.props.translations) {
			this.setState({
				translationService: new TranslationService(this.props.translations),
			});
		}
	};

	shouldComponentUpdate(nextProps, nextState) {
		if (
			nextProps.translations &&
			JSON.stringify(nextProps.translations) !==
				JSON.stringify(this.props.translations)
		) {
			this.setState({
				translationService: new TranslationService(nextProps.translations),
			});
		}
		return true;
	}

	componentDidUpdate() {
		this.setTranslations();
	}

	render() {
		const { translationService } = this.state;
		const { id, buttonSpinners, spinnerId, className, type } = this.props;
		const currentSpinner = buttonSpinners.find(
			spinner => spinner === spinnerId,
		);

		return translationService ? (
			<div className="text-right">
				<button
					disabled={currentSpinner}
					className={`position-relative mindalay--btn-default ${
						className ? className : ""
					}`}
					type={type}
					onClick={this.props.clicked}>
					{id
						? translationService.translate("TR_SAVE")
						: translationService.translate("TR_CREATE")}
					{currentSpinner ? <ButtonSpinner spinner={currentSpinner} /> : null}
				</button>
			</div>
		) : null;
	}
}

const mapStateToProps = state => ({
	buttonSpinners: state.spinner.buttonSpinners,
	translations: state.translation.translations,
});

export default connect(mapStateToProps)(SubmitButton);
