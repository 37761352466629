import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import cookieImg from "./../../assets/images/cookies.jpg"
import TranslationService from './../../Services/translationService';
import { useSelector } from 'react-redux';
import GuestApiService from '../../Services/guestApiService';
import { Link } from "react-router-dom"

export default function CookiesModal() {

  let acceptedCookies = localStorage.getItem("acceptedCookies");
  const { language } = useSelector(state => state.language);
  const { translations } = useSelector(state => state.translation);
  const [translationService, setTranslationService] = useState(null);
  const [isShowCookiesModal, setIsShowCookiesModal] = useState(false);
  const [cookiesPolicyUrl, setCookiesPolicyUrl] = useState(false);
  const [privacyPolicyUrl, setPrivacyPolicyUrl] = useState(false);

  const [menu, setMenu] = useState([]);

  useEffect(() => {
    setCookiesPolicyUrl(findPageUrl(menu, "cookies-policy"));
    setPrivacyPolicyUrl(findPageUrl(menu, "privacy-policy"));
  }, [menu])

  useEffect(() => {
    GuestApiService.getFrontBottomPages().then(response => {
      setMenu(response.data);
    });
  }, []);

  useEffect(() => {
    setTranslationService(new TranslationService(translations));
  }, [translations]);

  useEffect(() => {

    if (!localStorage.getItem("acceptedCookies") && cookiesPolicyUrl && privacyPolicyUrl) {
      setIsShowCookiesModal(true);
    } else {
      setIsShowCookiesModal(false);
    }
  }, [acceptedCookies, cookiesPolicyUrl, privacyPolicyUrl]);

  const findPageUrl = (pages, pageName) => {
    for (let page of pages) {
      if (page.uri === pageName) {
        return `${page.uri}/${page.id}`;
      }
      if (page.children && page.children.length > 0) {
        const result = findPageUrl(page.children, pageName);
        if (result) {
          return result;
        }
      }
    }
    return null;
  }

  const hideModal = () => {
    setIsShowCookiesModal(false);
    localStorage.setItem("acceptedCookies", true);
  }

  if (window.location.pathname.includes("/privacy-policy") || window.location.pathname.includes("/cookies-policy")) {
    return false;
  }

  return (translationService ? <Modal
    show={isShowCookiesModal}
    centered
  >
    <Modal.Body>
      <div className='d-flex justify-content-center mb-2'>
        <img src={cookieImg} height={100} />
      </div>
      <p className='text-center mb-4'>
        <span>
          {translationService.translate("TR_COOKIES_MODAL_DESCRIPTION")}
        </span>
        <Link to={`/${language}/${cookiesPolicyUrl}`}>
          &nbsp;
          {translationService.translate("TR_COOKIES_POLICY")}
        </Link>
        &nbsp;&&nbsp;
        <Link to={`/${language}/${privacyPolicyUrl}`}>
          {translationService.translate("TR_PRIVACY_POLICY")}
          .
        </Link>
      </p>

      <div className='d-flex justify-content-center my-3'>
        <button
          type="button"
          className={`mindalay--btn-dark mindalay--btn-link mindalay--btn-small`}
          onClick={hideModal}
        >
          {translationService.translate("TR_I_UNDERSTAND")}
        </button>
      </div>
    </Modal.Body>

  </Modal> : null
  )
}
