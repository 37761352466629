import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import MindalaySvg from "../../../Components/Svg/midalaySvg";
import TranslationService from "../../../Services/translationService";
import { showOrHideGeneralDashboardLeftMenu } from "../../../Store/Actions/generalLeftMenu";
import Collapse from "react-bootstrap/Collapse";
import Auxiliary from "../../../hoc/auxiliary/auxiliary";
import { STUDENT_USER_TYPE } from "../../../Constants/mainKeys";

const OrganizationUserLeftMenu = props => {
	const dispatch = useDispatch();
	const { isShowB2BWebinarNotification } = useSelector(state => state.main);
	const { language } = useSelector(state => state.language);
	const { availablePages } = useSelector(state => state.user);
	const { user } = useSelector(state => state.user);
	const { translations } = useSelector(state => state.translation);
	const { showOrHideGeneralLeftMenu } = useSelector(
		state => state.generalLeftMenu,
	);
	const { settings } = useSelector(state => state.settings);
	const { unreadMessgesCount } = useSelector(state => state.signalR);
	const [translationService, setTranslationService] = useState(null);
	const [availablePageId, setAvailablePageId] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	const showOrHideSubPages = (pageId, uri, subAvailablePages) => {
		if (pageId === availablePageId) {
			setAvailablePageId(null);
		} else {
			setAvailablePageId(pageId);
		}
		checkUriAndRedirect(uri, subAvailablePages);
	};

	const checkUriAndRedirect = (uri, subAvailablePages) => {
		if (subAvailablePages && !subAvailablePages.length) {
			props.history.push(`/${language}/${uri}`);
			showOrHideLeftMenu(false);
		}
	};

	const showOrHideLeftMenu = bool => {
		dispatch(showOrHideGeneralDashboardLeftMenu(bool));
	};

	const setMenuClass = field => {
		if (typeof field === "string" && props.location.pathname.includes(field)) {
			return "active-side-left-menu-item";
		}
		if (
			props.location.pathname.includes(`/${field.uri}`) &&
			field.uri !== "/"
		) {
			return "active-side-left-menu-item";
		}
		if (field.uri === "/" && field.children && field.children.length) {
			let isExist = field.children.find(item =>
				props.location.pathname.includes(item.uri),
			);
			if (isExist) {
				return "active-side-left-menu-item";
			}
		}
		return "";
	};

	const setSubMenuClass = field => {
		if (typeof field === "string" && props.location.pathname.includes(field)) {
			return "ml-2";
		}
		return "";
	};

	return translationService && user ? (
		<section
			className={`side-menu-container ${showOrHideGeneralLeftMenu ? "show-general-left-menu" : ""
				}`}>
			<div
				className={showOrHideGeneralLeftMenu ? "layer" : ""}
				onClick={() => showOrHideLeftMenu(false)}
			/>
			<aside
				className={`side-left instructor-header-zindex w-fix-left-menu-organization side-left-organization`}>
				<div className="side-left-container">
					<div className="side-left-inner-wrapper">
						<ul className="side-left-list-wrapper">
							<li
								className={`side-left-menu-item has-submenu d-inline-flex w-100`}>
								<Link
									to={`/${language}`}
									title={translationService.translate("TR_HOME")}
									className="p-0 d-flex justify-content-between"
									onClick={() => showOrHideLeftMenu(false)}>
									{settings && settings.mainLogoPath ? (
										<img
											src={settings.mainLogoPath}
											alt="/"
											style={{
												width: "120px",
												maxWidth: "150px",
												maxHeight: "150px",
												display: "block",
												margin: "0 auto",
											}}
										/>
									) : (
										<MindalaySvg />
									)}
								</Link>
							</li>
							{settings && settings.headerOrganizationName ? (
								<li
									className={`side-left-menu-item w-100 px-3`}
									style={{ pointerEvents: "none" }}>
									<h6
										className="text-center w-100 word-break-break-word"
										title={settings.headerOrganizationName}
										style={{ lineHeight: "25px" }}>
										{settings.headerOrganizationName}
									</h6>
								</li>
							) : null}
							<br />
							<ul>
								<Auxiliary>
									{availablePages && availablePages.length
										? availablePages.map(availablePage => {
											const subAvailablePages = availablePage.children;
											if (availablePage) {
												if (
													availablePage.name === "TR_CONTAINER_SIX_PAGE" &&
													!user.studentHavePractice
												) {
													return false;
												} else {
													return (
														<li
															key={availablePage.id}
															className={`
                        side-left-menu-item d-block has-submenu w-100 p-0 mb-0
                        ${setMenuClass(availablePage)} 
                         `}>
															<Link
																to="#"
																title={translationService.translate(
																	availablePage.name,
																)}
																className={` nav-link d-block 
                              ${subAvailablePages && subAvailablePages.length
																		? "dropdown-toggle dropdown-toggle-organization"
																		: ""
																	}
                              `}
																onClick={() =>
																	showOrHideSubPages(
																		availablePage.id,
																		availablePage.uri,
																		subAvailablePages,
																	)
																}>
																{translationService.translate(
																	availablePage.name,
																)}
																{availablePage.uri.includes("message") &&
																	unreadMessgesCount ? (
																	<span className="unread-messages-count-org">
																		{unreadMessgesCount}
																	</span>
																) : null}

																{availablePage.uri === "ongoing-webinars" &&
																	isShowB2BWebinarNotification ? (
																	<div className="circle-impulse" />
																) : null}
															</Link>
															{subAvailablePages &&
																subAvailablePages.length ? (
																<ul className="organization-dropdown-sub-pages mt-1 ml-4 d-block">
																	{subAvailablePages.map(subAvailablePage => {
																		return (
																			<li
																				key={subAvailablePage.id}
																				className={`nav-item`}>
																				<Collapse
																					in={
																						availablePageId ===
																						availablePage.id
																					}>
																					<div className=" content-block">
																						<Link
																							to="#"
																							title={translationService.translate(
																								subAvailablePage.name,
																							)}
																							className={`nav-link left-submenu-item pl-2 ${setSubMenuClass(
																								subAvailablePage.uri,
																							)}`}
																							onClick={() => {
																								checkUriAndRedirect(
																									subAvailablePage.uri,
																									subAvailablePage.children,
																								);
																							}}>
																							{translationService.translate(
																								subAvailablePage.name,
																							)}
																						</Link>
																					</div>
																				</Collapse>
																			</li>
																		);
																	})}
																</ul>
															) : null}
														</li>
													);
												}
											}
										})
										: null}
								</Auxiliary>
								<li
									title={translationService.translate("TR_HELP")}
									className={`side-left-menu-item d-block has-submenu w-100 p-0 d-block d-md-none`}>
									<Link
										to={`/${language}/hlep`}
										className={`nav-link d-block`}
										onClick={() => showOrHideLeftMenu(false)}>
										{translationService.translate("TR_HELP")}
									</Link>
								</li>
							</ul>
						</ul>
					</div>
				</div>
			</aside>
		</section>
	) : null;
};
export default withRouter(OrganizationUserLeftMenu);
