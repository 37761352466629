import React, { useState, useEffect } from "react";
import Collapse from "react-bootstrap/Collapse";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SettingsSvg from "../../../Components/Svg/settingsSvg";
import EditSvg from "../../../Components/Svg/editSvg";
import DeleteSvg from "../../../Components/Svg/deleteSvg";
import TranslationService from "../../../Services/translationService";
import {
	COURSES_KEY,
	FILE_LIBRARY_CATEGORY_KEY,
	FORM_KEY,
	INSTRUCTOR_KEY,
} from "../../../Constants/urlKeys";
import { ACTIVE_TAB_KEY } from "../../../Constants/mainKeys";
import CustomTitle from "../../../Components/CustomTitle/customTitle";

const CategoriesCollapse = ({ items, deleteCategory }) => {
	const [displayChildren, setDisplayChildren] = useState({});
	const language = useSelector(state => state.language.language);
	const user = useSelector(state => state.user.user);
	const translations = useSelector(state => state.translation.translations);

	const [translationService, setTranslationService] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	return translationService ? (
		<div className="accordion-wrapper mt-3">
			{items &&
				items.map((item, itemIndex) => {
					return (
						<div key={item.id} className="card mindalay-card">
							<div className="dropleft setting-dropdown d-flex d-md-none">
								<div
									className="setting-dropdown-btn"
									data-toggle="dropdown"
									aria-haspopup="true"
									aria-expanded="false">
									<SettingsSvg />
								</div>
								<div className="dropdown-menu">
									<Link
										to={
											user.isOrganizationUser
												? `/${language}/${FILE_LIBRARY_CATEGORY_KEY}/${FORM_KEY}/${item.id}`
												: `/${language}/${INSTRUCTOR_KEY}/${COURSES_KEY}/${FILE_LIBRARY_CATEGORY_KEY}/${FORM_KEY}/${item.id}`
										}
										onClick={() => {
											localStorage.setItem(
												ACTIVE_TAB_KEY,
												"file-library-folder",
											);
										}}
										className="dropdown-item">
										{translationService.translate("TR_EDIT")}
									</Link>
									<Link
										to="#"
										className="dropdown-item"
										onClick={() => deleteCategory(item)}>
										{translationService.translate("TR_DELETE")}
									</Link>
								</div>
							</div>
							<div
								className="card-header"
								aria-controls={`file_category_${item.id}`}
								aria-expanded={
									displayChildren[item.id] &&
									item.children &&
									item.children.length
								}
								onClick={() =>
									setDisplayChildren({
										...displayChildren,
										[item.id]: !displayChildren[item.id],
									})
								}>
								<div className="d-flex justify-content-between algn-items-center w-100">
									<h5 className="mb-0 text-elipsis">
										<span>{itemIndex + 1}</span>
										{item.name}
									</h5>
									<div className="d-none d-md-flex">
										<CustomTitle
											title={translationService.translate("TR_EDIT")}>
											<div className="course-wrapper--instructor ml-1">
												<Link
													to={
														user.isOrganizationUser
															? `/${language}/${FILE_LIBRARY_CATEGORY_KEY}/${FORM_KEY}/${item.id}`
															: `/${language}/${INSTRUCTOR_KEY}/${COURSES_KEY}/${FILE_LIBRARY_CATEGORY_KEY}/${FORM_KEY}/${item.id}`
													}
													onClick={() => {
														localStorage.setItem(
															ACTIVE_TAB_KEY,
															"file-library-folder",
														);
													}}
													className="course-edit-button mt-0">
													<EditSvg
														className="mr-0"
														style={{
															width: "15px",
															minWidth: "auto",
														}}
													/>
												</Link>
											</div>
										</CustomTitle>
										<CustomTitle
											title={translationService.translate("TR_DELETE")}>
											<div
												className="course-wrapper--instructor ml-1 cursor-pointer"
												onClick={event => {
													deleteCategory(item);
													event.stopPropagation();
												}}>
												<span className="course-delet-button mt-0">
													<DeleteSvg
														className="mr-0"
														style={{
															width: "15px",
															minWidth: "auto",
														}}
													/>
												</span>
											</div>
										</CustomTitle>
									</div>
								</div>
							</div>
							<Collapse
								in={
									displayChildren[item.id] &&
									item.children &&
									item.children.length
										? true
										: false
								}>
								<div
									id={`file-category_${item.id}`}
									className="light-blue-background">
									<div className="card-body">
										{displayChildren[item.id] &&
										item.children &&
										item.children.length ? (
											<CategoriesCollapse
												items={item.children}
												deleteCategory={deleteCategory}
											/>
										) : null}
									</div>
								</div>
							</Collapse>
						</div>
					);
				})}
		</div>
	) : null;
};

export default CategoriesCollapse;
