import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import TranslationService from "../../../Services/translationService";
import MultiChoiseSvg from "../../../Components/Svg/multiChoiseSvg";
import MatchingSvg from "../../../Components/Svg/matchingSvg";
import ShortAnswerSvg from "../../../Components/Svg/shortAnswerSvg";
import TrueFalseSvg from "../../../Components/Svg/trueFalseSvg";
import ModalComponent from "../../../Components/ModalComponent/modalComponent";
import { QUESTION_TYPES_ARR_KEY } from "../../../Constants/mainKeys";

const SelectQuestionTypeModal = props => {
	const locationHash = props.location.hash.substring(1);
	const questionTypes = QUESTION_TYPES_ARR_KEY;
	const translations = useSelector(state => state.translation.translations);

	const [translationService, setTranslationService] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	return translationService ? (
		<ModalComponent
			title={translationService.translate("TR_SELECT_QUESTION_TYPE")}
			contentClassName="resource-modal"
			isLarge={true}
			actionButtonTitle={translationService.translate("TR_ADD")}
			onSubmit={props.added}
			cancel={props.closeModal}>
			<div className="d-flex resource-modal">
				<div className="light-gray-background">
					<ul className="nav nav-tabs" role="tablist">
						{questionTypes.map((questionType, questionTypeIndex) => {
							const linkClasses = `${
								locationHash === questionTypeIndex.toString() ? "active" : ""
							} nav-link d-flex align-items-center border-0 p-3`;
							return (
								<li key={questionType}>
									<Link to={`#${questionTypeIndex}`} className={linkClasses}>
										<div className="mr-3 modal-icon-width">
											{(() => {
												switch (questionTypeIndex) {
													case 0:
														return <MultiChoiseSvg />;
													case 1:
														return <MatchingSvg />;
													case 2:
														return <ShortAnswerSvg />;
													case 3:
														return <TrueFalseSvg />;
													default:
														break;
												}
											})()}
										</div>
										<p>{translationService.translate(questionType)}</p>
									</Link>
								</li>
							);
						})}
					</ul>
				</div>
				<div className="tab-content scroll" role="tablist">
					<div
						id="pane-image"
						className="card tab-pane fade show active border-0"
						role="tabpanel"
						aria-labelledby="tab-image">
						<div
							id="collapse-image"
							className="collapse show"
							role="tabpanel"
							aria-labelledby="heading-image">
							<div className="card-body word-break-break-word">
								{props.location.hash === "#0" ? (
									<>
										{translationService.translate("TR_MULTI_CHOICE_INFO")}
										<br />
										<br />
										{translationService.translate("TR_FILE_COPY_TEXT")}
									</>
								) : props.location.hash === "#1" ? (
									translationService.translate("TR_MATCHING_QUESTION_INFO")
								) : props.location.hash === "#2" ? (
									translationService.translate("TR_SHORT_ANSWER_INFO")
								) : props.location.hash === "#3" ? (
									translationService.translate("TR_TRUE_FALSE_INFO")
								) : (
									translationService.translate("TR_CREATE_QUESTION_INFO")
								)}
							</div>
						</div>
					</div>
				</div>
			</div>
		</ModalComponent>
	) : null;
};

export default withRouter(SelectQuestionTypeModal);
