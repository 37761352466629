import React, { useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import TranslationService from "../../../Services/translationService";
import ApiService from "../../../Services/apiService";
import NoDataImage from "../../../assets/images/illustrations/nodata.svg";
import { Link, withRouter } from "react-router-dom";
import {
	addModalSpinner,
	addPartialViewSpinner,
	removeModalSpinner,
	removePartialViewSpinner,
} from "../../../Store/Actions/spinner";
import uuid from "react-uuid";
import AlertService from "../../../Services/alertService";
import {
	ERROR_KEY,
	SUCCESS_KEY,
	TR_NO,
	TR_YES,
} from "../../../Constants/mainKeys";
import ReactPaginate from "react-paginate";
import _InfoSvg from "../../../Components/Svg/_infoSvg";
import PageInfoBlock from "../../../Components/PageInfoBlock/PageInfoBlock";
import RolesService from "../../../Services/rolesService";
import MainService from "../../../Services/mainService";
import ModalComponent from "../../../Components/ModalComponent/modalComponent";
import InputCheckBox from "../../../Components/Inputs/inputCheckBox";
import SettingsSvg from "../../../Components/Svg/settingsSvg";
import { Collapse } from "react-bootstrap";
import AddStudent from "../../../Components/Svg/addStudentsSvg";
import EditSvg from "../../../Components/Svg/editSvg";
import DeleteSvg from "../../../Components/Svg/deleteSvg";
import CreateChildSvg from "../../../Components/Svg/createChildSvg";
import CustomTitle from "../../../Components/CustomTitle/customTitle";

const Division = props => {
	const pageSize = 10;
	const dispatch = useDispatch();
	const { translations } = useSelector(state => state.translation);
	const { language } = useSelector(state => state.language);
	const [translationService, setTranslationService] = useState(null);
	const [divisions, setDivisions] = useState([]);
	const [pagination, setPagination] = useState(null);
	const [activePageNumber, setActivePageNumber] = useState(0);
	const { role, user } = useSelector(state => state.user);
	const [roleService, setRoleService] = useState(null);
	const [ids, setIds] = useState(
		localStorage.getItem("collapseIds")
			? JSON.parse(localStorage.getItem("collapseIds"))
			: [],
	);

	const [openModal, setOpenModal] = useState(null);
	const [students, setStudents] = useState([]);
	const [values, setValues] = useState([]);
	const [isActivStudents, setIsActivStudents] = useState(false);
	const [isChange, setIsChange] = useState(false);

	useEffect(() => {
		if (ids && ids.length) {
			localStorage.setItem("collapseIds", JSON.stringify(ids));
		} else {
			localStorage.removeItem("collapseIds");
		}
	}, [ids]);

	useEffect(() => {
		MainService.isJson(role) &&
			setRoleService(new RolesService(JSON.parse(role)));
	}, [role]);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getDivisions(1);
	}, []);

	const handlePageClick = event => {
		getDivisions(event.selected + 1);
		setActivePageNumber(event.selected);
	};

	const getDivisions = currentPage => {
		const spinerId = uuid();
		dispatch(addPartialViewSpinner(spinerId));
		ApiService.getDivisions(currentPage, pageSize)
			.then(response => {
				if (response && response.pagination)
					setPagination(JSON.parse(response.pagination));
				if (response && response.data) {
					setDivisions(response.data);
				}
				dispatch(removePartialViewSpinner(spinerId));
			})
			.catch(error => getFail(error, spinerId));
	};

	const deleteDivision = item => {
		if (!item) return;
		const spinerId = uuid();
		AlertService.alertConfirm(
			`${translationService.translate("TR_ARE_YOU_SURE_MODAL")}`,
			`You want to delete ${item.name} division`,
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		).then(() => {
			dispatch(addPartialViewSpinner(spinerId));
			ApiService.deleteDivision(item.id)
				.then(() => {
					setDivisions(divisions.filter(_item => _item.id !== item.id));
					dispatch(removePartialViewSpinner(spinerId));
					AlertService.alert(
						SUCCESS_KEY,
						translationService.translate("TR_DATA_SAVED"),
					);
				})
				.catch(error => getFail(error, spinerId));
		});
	};
	const onClose = (close = true) => {
		setIsChange(false);
		setValues([]);
		setStudents([]);
		setIsActivStudents(false);
		setOpenModal(false);
	};
	const onSave = item => {
		if (!item || !item?.id) {
			return;
		}
		AlertService.alertConfirm(
			`${translationService.translate("TR_ARE_YOU_SURE_MODAL")} ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		)
			.then(() => {
				const spinerID = uuid();
				dispatch(addModalSpinner(spinerID));
				const data = {
					divisionId: item.id,
					studentIds: values,
				};

				ApiService.setDivisionsStudents(data)
					.then(res => {
						AlertService.alert(
							SUCCESS_KEY,
							translationService.translate("TR_DATA_SAVED"),
						);
						onClose(false);
					})
					.catch(error => {
						AlertService.alert(
							AlertService.checkMessageType(error.respcode) || ERROR_KEY,
							error,
						);
					})
					.finally(() => {
						dispatch(removeModalSpinner(spinerID));
					});
			})
			.catch(error => {});
	};

	const getFail = (error, spinerId) => {
		error &&
			AlertService.alert(
				AlertService.checkMessageType(error.respcode) || ERROR_KEY,
				error,
			);
		spinerId && dispatch(removePartialViewSpinner(spinerId));
	};

	const getDivisionsStudents = (
		item,
		openModal = true,
		isActivStudents = true,
	) => {
		if (!item) return;
		if (openModal) setOpenModal(item);
		const spinerId = uuid();
		dispatch(addModalSpinner(spinerId));
		ApiService.getDivisionsStudents(item.id, isActivStudents)
			.then(response => {
				if (response.data && response.data.length) {
					setStudents(response.data);
					let attachedStudents = response.data
						.filter(el => el.isBelongToCurrentDivision)
						.map(el => el.id);
					setValues(attachedStudents);
				} else {
					setStudents([]);
					setValues([]);
				}
			})
			.catch(error => {
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				);
				onClose();
			})
			.finally(() => {
				dispatch(removeModalSpinner(spinerId));
			});
	};

	const onChangeIds = id => {
		if (ids.includes(id)) {
			const newIds = ids.filter(el => el !== id);
			setIds(newIds);
		} else {
			setIds([...ids, id]);
		}
	};

	return translationService ? (
		<div className="container">
			{openModal && (
				<ModalComponent
					cancel={onClose}
					onSubmit={() => {
						onSave(openModal);
					}}
					addButtonIsDisabled={!isChange}
					isLarge={true}
					title={translationService.translate("TR_ATTACH_DETACH_STUDENT")}
					actionButtonTitle={
						students.length ? translationService.translate("TR_SAVE") : ""
					}>
					{students.length ? (
						<div className="row">
							<div className="col-12">
								<div className="custom-control custom-switch">
									<div className="d-flex align-items-center">
										<input
											type="checkbox"
											id="save"
											className="custom-control-input"
											checked={isActivStudents}
											onChange={event => {
												setIsActivStudents(event.target.checked);
												getDivisionsStudents(
													openModal,
													false,
													!event.target.checked,
												);
											}}
										/>
										<label htmlFor="save" className="custom-control-label">
											{translationService.translate(
												"TR_SHOW_ONLY_GROUP_STUDENTS",
											)}
										</label>
									</div>
								</div>
								<hr />
							</div>
							{!isActivStudents && (
								<div className="col-12">
									<InputCheckBox
										id={"select_all"}
										name={"select_all"}
										checked={values.length === students.length}
										labelValue={translationService.translate("TR_SELECT_ALL")}
										onChange={event => {
											setIsChange(true);
											if (event.target.checked) {
												const selectedValue = students.map(el => el.id);
												setValues(selectedValue);
											} else {
												setValues([]);
											}
										}}
									/>
								</div>
							)}
							{students &&
								!!students.length &&
								students.map((item, i) => (
									<div className="col-12 col-md-6" key={i}>
										<InputCheckBox
											id={`students_${item.id}`}
											name={`students_${item.id}`}
											checked={values.filter(el => el === item.id).length}
											labelValue={item.studentName}
											image={item.avatarFilePath}
											onChange={event => {
												setIsChange(true);
												if (event.target.checked) {
													setValues([...values, item.id]);
												} else {
													const selectedValue = values.filter(
														el => el !== item.id,
													);
													setValues(selectedValue);
												}
											}}
										/>
									</div>
								))}
						</div>
					) : (
						<div className="no-data-container mt-3">
							<div className="no-data-wrapper">
								<img src={NoDataImage} alt="/" height={150} />
							</div>
						</div>
					)}
				</ModalComponent>
			)}
			<div className="row mt-4">
				<div className="col-12">
					<h2 className="section-title">
						{translationService.translate("TR_DIVISION_PAGE")}
					</h2>
					<PageInfoBlock
						pageTitle="Divisions"
						pageName="admin_division"
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />
			{roleService.checkRole("group_create") ? (
				<div className="row">
					<div className="col-12">
						<div className="mb-2">
							<Link
								to={`/${language}/division-form`}
								className="mindalay--btn-default">
								{translationService.translate("TR_CREATE_A_DIVISION")}
							</Link>
						</div>
					</div>
				</div>
			) : null}

			{divisions && divisions.length ? (
				<div className="row">
					<div className="col-12">
						<DivisionAcardion
							open={onChangeIds}
							ids={ids}
							data={divisions}
							translationService={translationService}
							roleService={roleService}
							getDivisionsStudents={getDivisionsStudents}
							deleteDivision={deleteDivision}
						/>
					</div>
				</div>
			) : (
				<div className="no-data-container mt-3">
					<div className="no-data-wrapper">
						<img src={NoDataImage} alt="/" />
					</div>
				</div>
			)}
			{pagination && pagination.TotalPages > 1 ? (
				<div className="row mt-5">
					<div className="col-12 mt-4">
						<div className="d-flex justify-content-end">
							<ReactPaginate
								nextLabel={translationService.translate("TR_NEXT")}
								onPageChange={handlePageClick}
								pageRangeDisplayed={3}
								marginPagesDisplayed={2}
								pageCount={pagination.TotalPages}
								previousLabel={translationService.translate("TR_PREVIOUS")}
								pageClassName="page-item"
								pageLinkClassName="page-link"
								previousClassName="page-item"
								previousLinkClassName="page-link"
								nextClassName="page-item"
								nextLinkClassName="page-link"
								breakLabel="..."
								breakClassName="page-item"
								breakLinkClassName="page-link"
								containerClassName="pagination"
								activeClassName="active"
								renderOnZeroPageCount={null}
								forcePage={activePageNumber}
							/>
						</div>
					</div>
				</div>
			) : null}
		</div>
	) : null;
};

export default withRouter(Division);

const DivisionAcardion = ({
	data,
	translationService,
	ids = [],
	open,
	background = "white-background",
	roleService,
	getDivisionsStudents,
	deleteDivision,
}) => {
	const language = useSelector(state => state.language.language);
	if (Array.isArray(data) && !data?.length) return null;

	return (
		data &&
		!!data.length && (
			<div
				className="accordion-wrapper m-0"
				style={{ position: "relative", zIndex: 0 }}>
				<div className="row" style={{ position: "relative", zIndex: 0 }}>
					{data.map((item, i) => {
						return (
							<div
								key={i}
								className={`col-12 ${item?.children ? "" : "mt-2"}`}
								style={{ position: "relative", zIndex: `${0 - i}` }}>
								<div className="card mindalay-card">
									<div
										aria-controls={`section_${item.id}`}
										aria-expanded={ids.includes(item.id)}
										onClick={() => open(item.id)}>
										<div
											className="card-header d-flex align-items-center justify-content-between"
											style={{ zIndex: "0 !important" }}>
											<div className="d-flex align-items-center">
												<h5 className="text-elipsis fz-md w-auto">
													{item.name}
												</h5>
												{item.isStudentDivision && (
													<div className="position-relative ml-3">
														<span
															className="top-line"
															style={{
																position: "absolute",
																width: 2,
																height: "100%",
																top: 0,
																left: "-8px",
																backgroundColor: "var(--mindalay--brand-color)",
															}}></span>
														<p className="text-success">
															{translationService.translate(
																"TR_STUDENT_DIVISION",
															)}
														</p>
													</div>
												)}
											</div>
											<div
												className="d-none d-md-flex align-items-center"
												style={{ gap: 5 }}>
												{!item.isStudentDivision && (
													<CustomTitle
														title={translationService.translate(
															"TR_CREATE_CHILD",
														)}>
														<div className="course-wrapper--instructor ml-1">
															<Link
																to={`/${language}/division-form`}
																onClick={() => {
																	localStorage.setItem(
																		"parent",
																		JSON.stringify({
																			parentId: item.id,
																			ownerUserId: item.ownerUserId,
																		}),
																	);
																}}
																className="course-success-button mt-0">
																<CreateChildSvg
																	className="mr-0"
																	style={{ width: "15px", minWidth: "auto" }}
																/>
															</Link>
														</div>
													</CustomTitle>
												)}
												{item.isStudentDivision &&
													!item.childDivisions.length && (
														<CustomTitle
															title={translationService.translate(
																"TR_ATTACH_DETACH_STUDENT",
															)}>
															<div
																className="course-wrapper--instructor ml-1"
																onClick={event => {
																	// openModal(item);
																	event.stopPropagation();
																	getDivisionsStudents(item);
																}}>
																<span className="course-success-button mt-0">
																	<AddStudent
																		className="mr-0"
																		style={{ width: "15px", minWidth: "auto" }}
																	/>
																</span>
															</div>
														</CustomTitle>
													)}
												{!!roleService.checkRole("group_update") && (
													<CustomTitle
														title={translationService.translate("TR_EDIT")}>
														<div className="course-wrapper--instructor ml-1">
															<Link
																to={{
																	pathname: `/${language}/division-form/${item.id}`,
																	state: { item: item.childDivisions },
																}}
																state={item?.childDivisions}
																className="course-edit-button mt-0">
																<EditSvg
																	className="mr-0"
																	style={{
																		width: "15px",
																		minWidth: "auto",
																	}}
																/>
															</Link>
														</div>
													</CustomTitle>
												)}
												{!!roleService.checkRole("group_delete") && (
													<CustomTitle
														title={translationService.translate("TR_DELETE")}>
														<div
															className="course-wrapper--instructor ml-1 cursor-pointer"
															onClick={event => {
																deleteDivision(item);
																event.stopPropagation();
															}}>
															<span className="course-delet-button mt-0">
																<DeleteSvg
																	className="mr-0"
																	style={{
																		width: "15px",
																		minWidth: "auto",
																	}}
																/>
															</span>
														</div>
													</CustomTitle>
												)}
											</div>

											<div className="dropleft setting-dropdown d-block d-md-none">
												<div
													className="setting-dropdown-btn"
													id={`demo-${item.id}`}
													data-toggle="dropdown"
													aria-haspopup="true"
													aria-expanded="false"
													onClick={e => {
														e.preventDefault();
													}}>
													<SettingsSvg />
												</div>
												<div
													id={`demo-${item.id}`}
													className="dropdown-menu collapse">
													{!item.isStudentDivision && (
														<Link
															to={`/${language}/division-form`}
															className="dropdown-item"
															onClick={() => {
																localStorage.setItem(
																	"parent",
																	JSON.stringify({
																		parentId: item.id,
																		ownerUserId: item.ownerUserId,
																	}),
																);
															}}>
															{translationService.translate("TR_CREATE_CHILD")}
														</Link>
													)}

													{item.isStudentDivision &&
														!item.childDivisions.length && (
															<Link
																to="#"
																className="dropdown-item"
																onClick={e => {
																	e.stopPropagation();
																	e.preventDefault();
																	getDivisionsStudents(item);
																}}>
																{translationService.translate(
																	"TR_ATTACH_DETACH_STUDENT",
																)}
															</Link>
														)}

													{!!roleService.checkRole("group_update") && (
														<Link
															to={{
																pathname: `/${language}/division-form/${item.id}`,
																state: { item: item.childDivisions },
															}}
															state={item?.childDivisions}
															className="dropdown-item"
															onClick={() => {}}>
															{translationService.translate("TR_EDIT")}
														</Link>
													)}
													{!!roleService.checkRole("group_delete") && (
														<Link
															to="#"
															className="dropdown-item"
															onClick={() => {
																deleteDivision(item);
															}}>
															{translationService.translate("TR_DELETE")}
														</Link>
													)}
												</div>
											</div>
										</div>
									</div>

									<Collapse in={ids.includes(item.id)}>
										<div
											id={`topic_${item.id}`}
											className={`pl-3 pr-2 ${
												background === "white-background"
													? "white-background"
													: "light-blue-background"
											} content-block m-0`}>
											{!!item.description && (
												<div className="mt-3 text-muted">
													{item.description}
												</div>
											)}

											{!!item.ownerName && (
												<div
													className={`d-flex ${
														!!item.description ? "my-2" : "my-3"
													}`}>
													<p>
														{translationService.translate("TR_CURATOR")}:&nbsp;
													</p>
													<span>{item.ownerName}</span>
												</div>
											)}
											{item.childDivisions && !!item.childDivisions.length && (
												<DivisionAcardion
													open={open}
													ids={ids}
													data={item.childDivisions}
													translationService={translationService}
													background={
														background === "white-background"
															? "light-blue-background"
															: "white-background"
													}
													roleService={roleService}
													getDivisionsStudents={getDivisionsStudents}
													deleteDivision={deleteDivision}
												/>
											)}
										</div>
									</Collapse>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		)
	);
};
