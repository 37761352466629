import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import TranslationService from "../../Services/translationService";
import { withRouter, Link } from "react-router-dom/cjs/react-router-dom.min";
import PageInfoBlock from "../../Components/PageInfoBlock/PageInfoBlock";
import EditSvg from "../../Components/Svg/editSvg";
import NoDataImage from "../../assets/images/illustrations/nodata.svg";
import ReactPaginate from "react-paginate";
import {
	addModalSpinner,
	addPartialViewSpinner,
	removeModalSpinner,
	removePartialViewSpinner,
} from "../../Store/Actions/spinner";
import AlertService from "../../Services/alertService";
import {
	ERROR_KEY,
	SUCCESS_KEY,
	TR_NO,
	TR_YES,
} from "../../Constants/mainKeys";
import uuid from "react-uuid";
import ApiService from "../../Services/apiService";
import ReactSelectOption from "../../Components/SelectOption/reactSelectOption";
import ModalComponent from "../../Components/ModalComponent/modalComponent";

function Users(props) {
	const pageSize = 10;
	const dispatch = useDispatch();
	const translations = useSelector(state => state.translation.translations);
	const [translationService, setTranslationService] = useState(null);
	const { language } = useSelector(state => state.language);
	const { user } = useSelector(state => state.user);
	const [users, setUsers] = useState([]);
	const [pagination, setPagination] = useState(null);
	const [activePageNumber, setActivePageNumber] = useState(0);
	const [userTypes, setUserTypes] = useState([]);
	const [usertypeId, setUsertypeId] = useState(null);
	const [openModal, setOpenModal] = useState(false);
	const [userData, setUserData] = useState(null);

	useEffect(() => {
		setTranslationService(new TranslationService(translations));
	}, [translations]);

	useEffect(() => {
		getUsers(1, null);
		getUsertypes();
	}, []);

	const getUsers = (currentPage, usertypeId = null) => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getUsers(currentPage, usertypeId)
			.then(response => {
				if (response && response.pagination)
					setPagination(JSON.parse(response.pagination));
				if (response && response.data) {
					setUsers(response.data);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const getUsertypes = () => {
		const spinnerId = uuid();
		dispatch(addPartialViewSpinner(spinnerId));
		ApiService.getUsertypes(null)
			.then(response => {
				if (response && response.data && response.data.length) {
					const userTyps = [...response.data];
					setUserTypes(userTyps);
				}
			})
			.catch(error =>
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				),
			)
			.finally(() => {
				dispatch(removePartialViewSpinner(spinnerId));
			});
	};

	const changeUserstatus = user => {
		if (!user) return null;
		AlertService.alertConfirm(
			`${translationService.translate("TR_ARE_YOU_SURE_MODAL")} ?`,
			"",
			translationService.translate(TR_YES),
			translationService.translate(TR_NO),
		)
			.then(() => {
				const spinnerId = uuid();
				dispatch(addPartialViewSpinner(spinnerId));
				ApiService.user_activate(user.id, !user.isActive)
					.then(response => {
						AlertService.alert(
							SUCCESS_KEY,
							translationService.translate("TR_DATA_SAVED"),
						);
						const newUsers = users.map(el => {
							if (el.id === user.id) {
								return { ...el, isActive: !el.isActive };
							}
							return el;
						});
						setUsers(newUsers);
					})
					.catch(error =>
						AlertService.alert(
							AlertService.checkMessageType(error.respcode) || ERROR_KEY,
							error,
						),
					)
					.finally(() => {
						dispatch(removePartialViewSpinner(spinnerId));
					});
			})
			.catch(() => {});
	};

	const onSelectOptionChange = item => {
		setUsertypeId(item.id);
		getUsers(1, item.id);
		setActivePageNumber(0);
	};

	const handlePageClick = event => {
		getUsers(event.selected + 1, usertypeId);
		setActivePageNumber(event.selected);
	};

	const getUser = user => {
		if (!user) return;
		setOpenModal(true);
		const spinerId = uuid();
		dispatch(addModalSpinner(spinerId));
		ApiService.getUser(user.id)
			.then(response => {
				if (response.data) {
					setUserData(response.data);
				}
			})
			.catch(error => {
				AlertService.alert(
					AlertService.checkMessageType(error.respcode) || ERROR_KEY,
					error,
				);
				onClose();
			})
			.finally(() => {
				dispatch(removeModalSpinner(spinerId));
			});
	};

	const onClose = (close = true) => {
		setOpenModal(false);
		setUserData(null);
	};

	return translationService ? (
		<div className="container mt-4">
			{openModal && (
				<ModalComponent
					cancel={onClose}
					cancelButtonTitle="TR_CLOSE"
					title={userData?.fullName}>
					{userData ? (
						<div className="row">
							<div className="col-12">
								<div className="d-flex justify-content-center border-bottom pb-2">
									<div className="org-student-info-block">
										<img src={userData.avatarFilePath} alt="/" />
									</div>
								</div>
								<div className="d-flex justify-content-between py-2 border-bottom">
									<b className="text-capitalize">{`${translationService.translate(
										"TR_ORGANIZATION_NAME",
									)}:`}</b>
									<span>{userData.organizationName}</span>
								</div>
								<div className="d-flex justify-content-between py-2 border-bottom">
									<b className="text-capitalize">{`${translationService.translate(
										"TR_USERTYPE",
									)}:`}</b>
									<span>
										{translationService.translate(userData.userTypeDisplayName)}
									</span>
								</div>
								<div className="d-flex justify-content-between py-2 border-bottom">
									<b className="text-capitalize">{`${translationService.translate(
										"TR_EMAIL",
									)}:`}</b>
									<span>{userData.email}</span>
								</div>
								<div className="d-flex justify-content-between py-2 border-bottom">
									<b className="text-capitalize">{`${translationService.translate(
										"TR_ENTITY_USER_NAME",
									)}:`}</b>
									<span>{userData.username}</span>
								</div>
								<div className="d-flex justify-content-between py-2 border-bottom">
									<b className="text-capitalize">{`${translationService.translate(
										"TR_FIRSTNAME",
									)}:`}</b>
									<span>{userData.firstname}</span>
								</div>
								<div className="d-flex justify-content-between py-2 border-bottom">
									<b className="text-capitalize">{`${translationService.translate(
										"TR_LASTNAME",
									)}:`}</b>
									<span>{userData.lastname}</span>
								</div>
								<div className="d-flex justify-content-between py-2 border-bottom">
									<b className="text-capitalize">{`${translationService.translate(
										"TR_MIDDLENAME",
									)}:`}</b>
									<span>{userData.middlename}</span>
								</div>
								<div className="d-flex justify-content-between py-2 border-bottom">
									<b className="text-capitalize">{`${translationService.translate(
										"TR_MOBILEPHONE",
									)}:`}</b>
									<span>{userData.mobilePhone}</span>
								</div>
								<div className="d-flex justify-content-between py-2 border-bottom">
									<b className="text-capitalize">{`${translationService.translate(
										"TR_PHONE",
									)}:`}</b>
									<span>{userData.phone}</span>
								</div>
								{/* <div className="d-flex justify-content-between py-2 border-bottom">
									<b className="text-capitalize">{`${translationService.translate(
										"TR_PASSWORD",
									)}:`}</b>
									<span>{atob(userData.password)}</span>
								</div> */}
								<div className="d-flex justify-content-between py-2 border-bottom">
									<b className="text-capitalize">{`${translationService.translate(
										"TR_SEND_NOTIFICATION",
									)}:`}</b>
									<span>
										{userData.sendNotification ? (
											<div className="payment-modal-status-icon-block">
												<i className="fas fa-check transaction-successful fs-30"></i>
											</div>
										) : (
											<div className="payment-modal-status-icon-block">
												<i className="fas fa-times transaction-unsuccessful fs-30"></i>
											</div>
										)}
									</span>
								</div>
								<div className="d-flex justify-content-between py-2 ">
									<b className="text-capitalize">{`${translationService.translate(
										"TR_SEND_EMAIL",
									)}:`}</b>
									<span>
										{userData.sendEmail ? (
											<div className="payment-modal-status-icon-block">
												<i className="fas fa-check transaction-successful fs-30"></i>
											</div>
										) : (
											<div className="payment-modal-status-icon-block">
												<i className="fas fa-times transaction-unsuccessful fs-30"></i>
											</div>
										)}
									</span>
								</div>
							</div>
						</div>
					) : (
						<div className="no-data-container mt-3">
							<div className="no-data-wrapper">
								<img src={NoDataImage} alt="/" height={150} />
							</div>
						</div>
					)}
				</ModalComponent>
			)}
			<div className="row">
				<div className="col-12">
					<h2 className="section-title">
						{translationService.translate("TR_USERS")}
					</h2>
					<PageInfoBlock
						pageTitle={translationService.translate("TR_USERS")}
						pageName="admin_users"
						translationService={translationService}
					/>
				</div>
			</div>
			<hr />
			{userTypes && !!userTypes.length && (
				<div className="row align-items-end">
					<div className="col-12 col-md-6 mb-2">
						<ReactSelectOption
							value={usertypeId}
							selectedValue={(() => {
								const selectedItem = {
									...[
										{ name: translationService.translate("TR_ALL"), id: null },
										...userTypes,
									].find(data => data.id === usertypeId),
								};
								if (Object.keys(selectedItem).length) {
									selectedItem.label = translationService.translate(
										selectedItem.displayName,
									)
										? translationService.translate(selectedItem.displayName)
										: selectedItem.name;
									selectedItem.value = selectedItem.id;
									return selectedItem;
								} else {
									return {
										value: null,
										label: translationService.translate("TR_CHOOSE"),
									};
								}
							})()}
							items={[
								{ name: translationService.translate("TR_ALL"), id: null },
								...userTypes,
							]
								.filter(item => item.id !== usertypeId)
								.map(data => ({
									...data,
									label: translationService.translate(data.displayName)
										? translationService.translate(data.displayName)
										: data.name,
									value: data.id,
								}))}
							onChange={item => onSelectOptionChange(item, "parentId")}
						/>
					</div>
					<div className="col-12 col-md-6 mb-2">
						<div className="d-flex justify-content-end">
							<Link
								to={`/${language}/user-form`}
								className="mindalay--btn-default">
								{translationService.translate("TR_CREATEUSER")}
							</Link>
						</div>
					</div>
				</div>
			)}
			{users && users.length ? (
				<div className="row mt-5">
					<div className="col-12">
						<div className="mindayal-admin-table table-responsive">
							<table className="table">
								<thead>
									<tr>
										<th className="text-nowrap">
											{translationService.translate("TR_AVATAR")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_USERNAME")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_FULLNAME")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_EMAIL")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_USERTYPE")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_IS_ACTIVE")}
										</th>
										<th className="text-nowrap">
											{translationService.translate("TR_ACTIONS")}
										</th>
									</tr>
								</thead>
								<tbody>
									{users.map((item, index) => {
										return (
											<tr
												key={index}
												onClick={() => {
													getUser(item);
												}}>
												<td>
													<div className="mindalay-admin-table-td-item">
														<div
															className="navbar-profile-image"
															style={{
																backgroundImage: `url(${item.avatarFilePath})`,
															}}></div>
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item">
														{item.username}
													</div>
												</td>

												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														{item.fullName}
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														{item.email}
													</div>
												</td>
												<td>
													<div className="mindalay-admin-table-td-item text-capitalize">
														{translationService.translate(
															item.userTypeDisplayName,
														)}
													</div>
												</td>
												<td>
													{item.isActive ? (
														<div className="payment-modal-status-icon-block">
															<i className="fas fa-check transaction-successful fs-30"></i>
														</div>
													) : (
														<div className="payment-modal-status-icon-block">
															<i className="fas fa-times transaction-unsuccessful fs-30"></i>
														</div>
													)}
												</td>
												<td>
													<div className="table-action-btn-group d-flex">
														<Link
															to={`/${language}/user-form/${item.id}`}
															title={translationService.translate("TR_EDIT")}
															className="table-action-btn edit-btn">
															<EditSvg />
														</Link>
														{user.id !== item.id && (
															<button
																title={translationService.translate(
																	"TR_DEACTIVATE",
																)}
																className={`mindalay--btn mindalay--btn-small mindalay--btn-default mr-1`}
																onClick={e => {
																	e.preventDefault();
																	e.stopPropagation();
																	changeUserstatus(item);
																}}>
																{!item.isActive
																	? translationService.translate("TR_ACTIVATE")
																	: translationService.translate(
																			"TR_DEACTIVATE",
																	  )}
															</button>
														)}
													</div>
												</td>
											</tr>
										);
									})}
								</tbody>
							</table>
						</div>
					</div>
				</div>
			) : (
				<div className="no-data-container mt-3">
					<div className="no-data-wrapper">
						<img src={NoDataImage} alt="/" />
					</div>
				</div>
			)}
			{pagination && pagination.TotalPages > 1 ? (
				<div className="row mt-3">
					<div className="col-12">
						<div className="d-flex justify-content-end">
							<ReactPaginate
								nextLabel={translationService.translate("TR_NEXT")}
								onPageChange={handlePageClick}
								pageRangeDisplayed={3}
								marginPagesDisplayed={2}
								pageCount={pagination.TotalPages}
								previousLabel={translationService.translate("TR_PREVIOUS")}
								pageClassName="page-item"
								pageLinkClassName="page-link"
								previousClassName="page-item"
								previousLinkClassName="page-link"
								nextClassName="page-item"
								nextLinkClassName="page-link"
								breakLabel="..."
								breakClassName="page-item"
								breakLinkClassName="page-link"
								containerClassName="pagination"
								activeClassName="active"
								renderOnZeroPageCount={true}
								forcePage={activePageNumber}
							/>
						</div>
					</div>
				</div>
			) : null}
		</div>
	) : null;
}

export default withRouter(Users);
