import "./App.scss";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";
import AlertService from "./Services/alertService";
import PageSpinner from "./Components/Spinners/pageSpinner";
import WebinarNotificationBlock from "./Components/Webinar/webinarNotificationBlock";
import ReurnUrl from "./Services/returnUrl";
import uuid from "react-uuid";
import ApiService from "./Services/apiService";
import ScrollTop from "./Components/ScrollToTop/scrollTop";
import ReactHelmet from "./Components/ReactHelmet/reactHelmet";
import { getLanguages, setLanguage } from "./Store/Actions/language";
import { addPageSpinner, removePageSpinner } from "./Store/Actions/spinner";
import { getTranslations } from "./Store/Actions/translation";
import { getCategoriesByType, setCurrentCourse } from "./Store/Actions/course";
import { showOrHideHelpAlert, removeWebinarData } from "./Store/Actions/main";
import PartialViewSpinner from "./Components/Spinners/partialViewSpinner";
import HelpAlert from "./Components/HelpComponent/helpAlert";
import ChooseUserTypeModal from "./Components/ModalComponent/chooseUserTypeModal";
import MainService from "./Services/mainService";
import {
	CONSULTANT_KEY,
	COURSE_DASHBOARD_KEY,
	CREATE_CONSULTATION_KEY,
	FINANCES_KEY,
	INSTRUCTOR_KEY,
	LOGIN_KEY,
	MINDALAY_ADMIN,
	STUDENT_KEY,
	ORGANIZATION_ADMIN_KEY,
} from "./Constants/urlKeys";
import {
	COURSE_CATEGORIES_TYPE_KEY,
	ERROR_KEY,
	INSTRUCTOR_USER_TYPE,
	CONSULTANT_USER_TYPE,
	RETURN_URL_KEY,
	CURRENT_COURSE,
	STUDENT_USER_TYPE,
	CURRENCY_KEY,
	DEFAULT_CURRENCY_KEY,
	ALL_VALID_USER_TYPES,
	DEFAULT_CURRENCY_SYMBOL_KEY,
	CURRENCY_SYMBOL_KEY,
	WARNING_KEY,
	LANGUAGE_KEY,
} from "./Constants/mainKeys";
import Listeners from "./Listeners";
import Routes from "./Routes";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SettingsLayout from "./Components/SettingsLayout/settingsLayout";
import CookiesModal from "./Components/CookiesModal/CookiesModal";

// import "Css\main.less"

const App = props => {
	const url = localStorage.getItem(RETURN_URL_KEY);
	const dispatch = useDispatch();
	const { location, history } = props;
	const { language, languages } = useSelector(state => state.language);
	const { user } = useSelector(state => state.user);
	const { pageSpinners, patrialViewSpinners } = useSelector(
		state => state.spinner,
	);
	const { showWebinarScreenNotification } = useSelector(state => state.signalR);
	const { courseCategories } = useSelector(state => state.course);
	const { isShowHelpAlert, helpAlerts, currentWebinarData } = useSelector(
		state => state.main,
	);

	const [isShowChooseUserTypeModal, setIsShowChooseUserTypeModal] = useState(false);

	useEffect(() => {
		dispatch(removeWebinarData());
	}, []);

	useEffect(() => {
		dispatch(getLanguages());
	}, [user]);

	useEffect(() => {
		if (language) {
			dispatch(getTranslations());
			dispatch(setLanguage(language));
		}
	}, [language, user]);

	useEffect(() => {
		let urlArr = window.location.pathname.split("/");
		if (languages && languages.length && language && language !== urlArr[1]) {
			const newUrl = urlArr.join("/");
			if (languages.find(item => item.isocode2 === urlArr[1])) {
				dispatch(setLanguage(urlArr[1]));
				props.history.push(newUrl);
			} else {
				props.history.push("/");
			}
		}
	}, [window.location.pathname, languages, language]);

	useEffect(() => {
		window.scrollTo({ top: 0, behavior: "smooth" });
		dispatch(showOrHideHelpAlert(false));
	}, [location]);

	useEffect(() => {
		if (user) {
			if (!localStorage.getItem(CURRENCY_KEY)) {
				localStorage.setItem(CURRENCY_KEY, DEFAULT_CURRENCY_KEY);
			}
			if (!localStorage.getItem(CURRENCY_SYMBOL_KEY)) {
				localStorage.setItem(CURRENCY_SYMBOL_KEY, DEFAULT_CURRENCY_SYMBOL_KEY);
			}
			if (
				location.pathname.includes(RETURN_URL_KEY) &&
				!localStorage.getItem(CURRENT_COURSE)
			) {
				getCourseAndRedirectToGeneralInfo();
			}
			if (
				location.pathname.includes(RETURN_URL_KEY) &&
				!localStorage.getItem(CURRENT_COURSE)
			) {
				getCourseAndRedirectToGeneralInfo();
			}
			if (
				url &&
				!url.includes("pure/") &&
				!url.includes("courses/") &&
				user.isOrganizationUser
			) {
				redirectToReturnUrlPage(url);
			}

			if (location.pathname.includes(`/${FINANCES_KEY}/${RETURN_URL_KEY}`)) {
				if (user.userTypeId === STUDENT_USER_TYPE)
					return (
						<Redirect to={`/${language}/${STUDENT_KEY}/${FINANCES_KEY}`} />
					);
				if (user.userTypeId === INSTRUCTOR_USER_TYPE)
					return (
						<Redirect to={`/${language}/${INSTRUCTOR_KEY}/${FINANCES_KEY}`} />
					);
				if (user.userTypeId === CONSULTANT_USER_TYPE)
					return (
						<Redirect to={`/${language}/${CONSULTANT_KEY}/${FINANCES_KEY}`} />
					);
			}
		} else {
			//return from cabinet
			if (location.pathname.includes(`/${FINANCES_KEY}/${RETURN_URL_KEY}`)) {
				ReurnUrl.setUrl(location.pathname).then(() => {
					history.push(`/${language}/${LOGIN_KEY}`);
				});
			}
			//// Return URL
			if (location.pathname.includes(RETURN_URL_KEY)) {
				ReurnUrl.setUrl(
					location.pathname.slice(0, location.pathname.length - 10),
				).then(() => {
					history.push(`/${language}/${LOGIN_KEY}`);
				});
			}
			if (location.pathname.includes(CREATE_CONSULTATION_KEY)) {
				ReurnUrl.setUrl(location.pathname).then(() => {
					history.push(`/${language}/${LOGIN_KEY}`);
				});
			}
		}

		if (
			((user && !user.isOrganizationUser) || !user) &&
			!courseCategories.length &&
			!location.pathname.includes(MINDALAY_ADMIN) &&
			!location.pathname.includes(ORGANIZATION_ADMIN_KEY) &&
			!MainService.isShowOnlyB2B()
		) {
			dispatch(getCategoriesByType(COURSE_CATEGORIES_TYPE_KEY));
		}
	}, [user, courseCategories, location.pathname]);

	useEffect(() => {
		// if (
		// 	user &&
		// 	user.defaultUserTypeIds &&
		// 	!ALL_VALID_USER_TYPES.includes(user.userTypeId)
		// ) {
		// 	const intersection = user.defaultUserTypeIds.filter(element =>
		// 		ALL_VALID_USER_TYPES.includes(element),
		// 	);
		// 	if (intersection) setIsShowChooseUserTypeModal(true);
		// 	else setIsShowChooseUserTypeModal(false);
		// }
		if (
			user &&
			user.defaultUserTypeIds &&
			!ALL_VALID_USER_TYPES.includes(user.userTypeId)
		) {
			setIsShowChooseUserTypeModal(true);
		} else {
			setIsShowChooseUserTypeModal(false);
		}
	}, [user]);

	useEffect(() => {
		const checkInternetSpeed = () => {
			const connection =
				navigator.connection ||
				navigator.mozConnection ||
				navigator.webkitConnection;
			if (connection) {
				const effectiveType = connection.effectiveType;
				if (effectiveType === "slow-2g" || effectiveType === "2g") {
					AlertService.alert(WARNING_KEY, "Slow internet connection");
				}
			}
		};
		const intervalId = setInterval(checkInternetSpeed, 10000);
		return () => clearInterval(intervalId);
	}, []);

	const redirectToReturnUrlPage = url => {
		history.push(url);
		localStorage.removeItem(RETURN_URL_KEY);
	};

	const getCourseAndRedirectToGeneralInfo = () => {
		const spinnerId = uuid();
		var regex = /\d+/g;
		var courseId = location.pathname.match(regex);
		if (
			courseId &&
			courseId.length &&
			location.pathname.includes(COURSE_DASHBOARD_KEY) &&
			location.pathname.includes(RETURN_URL_KEY)
		) {
			dispatch(addPageSpinner(spinnerId));
			ApiService.getCourseById(+courseId[0])
				.then(response => {
					response &&
						response.data &&
						setCurrentCourseHandler(response.data, location.pathname);
				})
				.catch(error => {
					dispatch(removePageSpinner(spinnerId));
					error &&
						AlertService.alert(
							AlertService.checkMessageType(error.respcode) || ERROR_KEY,
							error,
						);
					history.push(`/${language}`);
				});
		}
	};

	const setCurrentCourseHandler = (course, url) => {
		if (!course || !url) {
			return false;
		}
		dispatch(setCurrentCourse(course));
		setTimeout(() => {
			var updatedUrl = url.slice(0, url.length - 10);
			history.push(updatedUrl);
		}, 1000);
	};


	return language ? (
		<div id="app">
			<CookiesModal />
			{
				isShowChooseUserTypeModal ?
					<ChooseUserTypeModal />
					: <Routes />
			}
			{!location.pathname.includes("/category") ? <ReactHelmet /> : null}
			<ScrollTop />
			<PageSpinner spinners={pageSpinners} />
			<PartialViewSpinner
				spinners={patrialViewSpinners}
				customClasses="partial-view-spinner-wrapper"
			/>
			<WebinarNotificationBlock
				currentWebinarData={currentWebinarData}
				isShow={showWebinarScreenNotification && user && currentWebinarData}
			/>
			{isShowHelpAlert && helpAlerts && helpAlerts.length ? (
				<HelpAlert />
			) : null}
			<div id="mindalay-alert-container" className="mindalay-alert-container" />
			<Listeners />
			<SettingsLayout />
			<ToastContainer theme="light" autoClose={5000} />
		</div>
	) : null;
};

export default withRouter(App);
