import React, { Component } from 'react';
import { addPartialViewSpinner, removePartialViewSpinner } from "../../Store/Actions/spinner";
import { getCurrentQuestionMarkData } from "../../Store/Actions/main";
import { connect } from 'react-redux';
import TranslationService from '../../Services/translationService';
import ApiService from '../../Services/apiService';
import uuid from 'react-uuid';
import AlertService from '../../Services/alertService';
import { ADMIN_USER_TYPE, CURATOR_USER_TYPE, ERROR_KEY, LECTURER_USER_TYPE, STUDENT_USER_TYPE } from '../../Constants/mainKeys';
import moment from 'moment';
import MainService from '../../Services/mainService';
import MessageSend from '../../Components/Svg/messageSend';
import NoDataImage from '../../assets/images/illustrations/nodata.svg';
import Collapse from 'react-bootstrap/Collapse';
import { Link } from 'react-router-dom';
import Auxiliary from '../../hoc/auxiliary/auxiliary';
import HelpService from './../../Services/helpService';
import InputCheckBox from '../../Components/Inputs/inputCheckBox';
import _InfoSvg from './../../Components/Svg/_infoSvg';
import PageInfoBlock from '../../Components/PageInfoBlock/PageInfoBlock';


class Messages extends Component {
  _isMounted = false;

  state = {
    translationService: null,
    messagesData: [],
    senderId: null,
    receiverId: null,
    messages: [],
    message: '',
    isInvalidSubmit: false,
    partnerName: null,
    messageMaxLength: 2000,
    categoryId: null,
    groupId: null,
    activeUserId: null,
    newMessage: null,
  }

  componentDidMount() {
    this._isMounted = true;
    this.setTranslations();
    this.getAllMessagesByOrgUser();
    // this.interval = setInterval(this.getChat, 10000);
  };

  componentDidUpdate(prevProps, prevState) {
    this.setTranslations();
    if (prevState.messages && this.state.messages && prevState.messages.length !== this.state.messages.length) {
      this.chatScrolToBottom();
    }
  };

  componentWillUnmount() {
    clearInterval(this.interval);
    this._isMounted = false;
  }

  setTranslations = () => {
    if (!this.state.translationService && this.props.translations) {
      this._isMounted && this.setState({ translationService: new TranslationService(this.props.translations) });
    };
  };

  shouldComponentUpdate(nextProps, nextState) {
    const { messages } = this.state;
    if (nextProps.translations
      && JSON.stringify(nextProps.translations) !== JSON.stringify(this.props.translations)) {
      this.setState({ translationService: new TranslationService(nextProps.translations) });
    };

    if (this.props.globalConnection && this.state.activeUserId) {
      this.props.globalConnection.on("ListenToMessage", (message) => {
        if (message.senderId === this.state.activeUserId) {
          const allMessages = [...messages];
          allMessages.push(message);
          this.setState({ messages: allMessages });
        }
      })
    }

    return true;
  };

  chatScrolToBottom = () => {
    var element = document.getElementById("mindalay--consultation-chat");
    if (element) {
      element.scrollTop = element.scrollHeight;
    }
  }

  onCheckboxChange = (event, groupId) => {
    const { user } = this.props;
    const messagesData = [...this.state.messagesData];
    var partnerName = "";
    var receiverId = [];
    const senderId = user.id;
    messagesData.forEach(messagesDataItem => {
      if (messagesDataItem.groups && messagesDataItem.groups.length) {
        messagesDataItem.groups.forEach(group => {

          if (group.id === groupId && event.target.checked) {
            group.isChecked = event.target.checked;
            partnerName = group.groupName;
            group.messages.forEach(student => {
              receiverId.push(student.userId)
            })
          }
          if (group.id === groupId && !event.target.checked) {
            group.isChecked = event.target.checked;
            partnerName = "";
            receiverId = [];
          }
          if (group.id !== groupId && event.target.checked) {
            group.isChecked = !event.target.checked;
          }
          if (group.id !== groupId && !event.target.checked) {
            group.isChecked = false;
          }
        })
      }
    })

    this.setState({ messagesData, partnerName, receiverId, senderId, messages: [], activeUserId: null });
  }

  getAllMessagesByOrgUser = () => {
    const spinnerId = uuid();
    this.props.addPartialViewSpinner(spinnerId);
    ApiService.getAllMessagesByOrgUser().then(response => {
      this.props.removePartialViewSpinner(spinnerId);
      if (this._isMounted && response.data) {
        var data = [...response.data];
        data && data.length && data.forEach((item, index) => {
          item.categoryId = index + 1;
          if (item.groups && item.groups.length) {
            item.groups.forEach(group => group.isChecked = false);
          }
        })
        this.setState({ messagesData: data })
      }
    }).catch(error => this.getFail(error, spinnerId))
  }

  getCurrentUserChat = (messageData) => {
    const { user } = this.props;
    if (!messageData || !user) { return; }
    const messagesData = [...this.state.messagesData];
    messagesData.forEach(messagesDataItem => {
      if (messagesDataItem.groups && messagesDataItem.groups.length) {
        messagesDataItem.groups.forEach(group => {
          group.isChecked = false;
        })
      }
    })
    const senderId = user.id;
    const receiverId = messageData.userId;
    const partnerName = messageData ? messageData.userName : "";
    if (messageData.unreadMessagesCount) { this.getAllMessagesByOrgUser() };
    this.setState({ senderId, receiverId, partnerName, activeUserId: messageData.userId, messages: [] }, () => {
      this.getChat(messagesData, messageData);
    });
  }

  getChat = (messagesData, messageData) => {
    var { receiverId } = this.state;
    if (!receiverId) { return; }
    receiverId && ApiService.getChat(receiverId).then(response => {
      const data = { ...response.data };
      data && this.setState({ messages: data.chat && data.chat.length ? data.chat : [] });
      if (messagesData && messagesData.length) this.resetSenderMessageCount(messagesData, messageData);
      document.getElementById("message-input")?.focus()
    }).catch(error => this.getFail(error, null));
  }

  resetSenderMessageCount = (messagesData, currentSenderData) => {
    messagesData.forEach(element => {
      if (element.userId === currentSenderData.userId) {
        element.unreadMessagesCount = 0;
      }
    });
    this.setState({ messagesData })
  }

  onChange = (event, maxLength = null) => {
    if (maxLength && maxLength < event.target.value.length) { return; }
    this.setState({ [event.target.name]: event.target.value });
  }

  sendMessage = (event) => {
    event && event.preventDefault();
    var { message, senderId, messages } = this.state;
    const receiverIds = [];
    if (!senderId || !this.state.receiverId || !message.trim()) { return; }
    if (!message) {
      this.setState({ isInvalidSubmit: true });
    } else {
      message = message.trim();
      receiverIds.push(this.state.receiverId);
      var data = {
        receiverId: this.state.receiverId && this.state.receiverId.length ? this.state.receiverId : receiverIds,
        senderId,
        message,
      };
      this.props.globalConnection.invoke("SendMessage", data).then(() => {
        const secondData = {
          receiver: true,
          time: new Date(),
          message: data.message,
          timeWithoutUtc: true
        }
        secondData && Array.isArray(messages) && messages.push(secondData);
        this.setState({
          messages,
          message: "",
        }, () => {
          this.chatScrolToBottom();
        })
      }).catch((err) => {
        this.getFail(err)
      });
      // ApiService.sendMessage(data).then(() => {
      //   this.getChat();
      //   this.setState({ message: '' });
      // }).catch(error => this.getFail(error, spinnerId));
    }
  }

  showOrHideMessages = (categoryId, isUnreadMessages) => {
    if (this.state.categoryId === categoryId) this.setState({ categoryId: null, groupId: null });
    else this.setState({ categoryId, groupId: null });
  }

  showOrHideGroupMessages = (groupId) => {
    if (this.state.groupId === groupId) this.setState({ groupId: null });
    else this.setState({ groupId });
  }

  getQuestionMarkData = (key) => {
    if (!key) { return; }
    if (HelpService.setPageName(this.props.location.pathname)) {
      this.props.getCurrentQuestionMarkData(HelpService.setPageName(this.props.location.pathname), key)
    }
  }

  getFail = (error, spinnerId) => {
    error && AlertService.alert((AlertService.checkMessageType(error.respcode) || ERROR_KEY), error);
    spinnerId && this.props.removePartialViewSpinner(spinnerId);
  }

  render() {

    const {
      translationService,
      messagesData,
      messages,
      message,
      senderId,
      partnerName,
      isInvalidSubmit,
      messageMaxLength,
      categoryId,
      groupId,
      activeUserId,
    } = this.state;

    return (
      translationService ? <Auxiliary>
        <div className="container general-messages">
          <div className="row mt-4">
            <div className="col-12">
              <h2 className="section-title">{translationService.translate("TR_MESSAGES")}</h2>
              <PageInfoBlock
                pageTitle={translationService.translate("TR_MESSAGES")}
                translationService={translationService}
                pageName="org_users_messages"
              />
            </div>
          </div>
          <hr />
          <div className="row">
            <div className="col-12">
              {
                messagesData && messagesData.length ?
                  <div className="row">
                    <div className="col-lg-6 col-md-12 col-12 messages-users-container mb-4">
                      <div className="message-users-container-height">
                        <ul className="d-block category-burger-menu">
                          {
                            messagesData.map((messageData, index) => {
                              var messages = messageData.messages;
                              var groups = messageData.groups
                              if (groups === null) {
                                return <ul key={index} className="d-block category-burger-menu background-transparent ">
                                  <li className="nav-item box-shadow-4 py-1">
                                    <div className="d-flex align-items-center position-relative mx-2">
                                      <Link
                                        to="#"
                                        className={`nav-link ${messageData.messages && messageData.messages.length ? "dropdown-toggle" : ""}`}
                                        onClick={() => this.showOrHideMessages(messageData.categoryId)}
                                      >
                                        {messageData.userTypeName}
                                      </Link>
                                      {
                                        messageData.unreadMessagesCount ?
                                          <span className="message-unread-count">{messageData.unreadMessagesCount}</span>
                                          : null
                                      }
                                    </div>
                                  </li>
                                  {
                                    messages && messages.length ?
                                      <ul className="dropdown-sub-menu background-transparent ml-3 mt-1">
                                        {
                                          messages && messages.map(message => {
                                            return <li key={message.userId} className="nav-item">
                                              <Collapse in={categoryId === messageData.categoryId}>
                                                <div className={`content-block ${activeUserId === message.userId ? "active-message-container" : ""}`}>
                                                  <div className={`messages-user-block`} onClick={() => this.getCurrentUserChat(message)}>
                                                    <div className="message-user-image" style={{ backgroundImage: `url(${message.avatarFilePath})` }} />
                                                    <h3 className="message-user-name">{message.userName}</h3>
                                                    {
                                                      message.unreadMessagesCount ?
                                                        <span className="message-unread-count brand-background">{message.unreadMessagesCount}</span>
                                                        : null
                                                    }
                                                  </div>
                                                </div>
                                              </Collapse>
                                            </li>
                                          })
                                        }
                                      </ul>
                                      : null
                                  }
                                </ul>
                              } else {
                                return <div key={index} className="d-block category-burger-menu">
                                  <li className="nav-item box-shadow-4 py-1">
                                    <div className="d-flex align-items-center position-relative px-2">
                                      <Link
                                        to="#"
                                        className={`nav-link ${messageData.groups && messageData.groups.length ? "dropdown-toggle" : ""}`}
                                        onClick={() => this.showOrHideMessages(messageData.categoryId)}>{messageData.userTypeName}</Link>
                                      {
                                        messageData.unreadMessagesCount ?
                                          <span className="message-unread-count">{messageData.unreadMessagesCount}</span>
                                          : null
                                      }
                                    </div>
                                  </li>
                                  {
                                    groups && groups.length ?
                                      <ul className="dropdown-sub-menu background-transparent ml-3 mt-1">
                                        {
                                          groups.map(group => {
                                            var groupMessages = group.messages;
                                            return <li key={group.id} className="nav-item">
                                              <Collapse in={categoryId === messageData.categoryId}>
                                                <div className="content-block">
                                                  <div className={`messages-user-block`} >
                                                    <Link
                                                      to="#"
                                                      className={`nav-link  ${group.messages && group.messages.length ? "dropdown-toggle" : ""}`}
                                                      onClick={() => this.showOrHideGroupMessages(group.id)}
                                                    >
                                                      {group.groupName}
                                                    </Link>
                                                    <InputCheckBox
                                                      id={group.id}
                                                      checked={group.isChecked}
                                                      labelValue={" "}
                                                      infoText={translationService.translate("TR_BULK_MESSAGE_SEND_INFO")}
                                                      infoTextClassName="small-question-text-left"
                                                      blockClassName="mr-2 mt-3"
                                                      onChange={(event) => this.onCheckboxChange(event, group.id)}
                                                    />
                                                    {
                                                      group.unreadMessagesCount ?
                                                        <span className="message-unread-count mr--45">{group.unreadMessagesCount}</span>
                                                        : null
                                                    }
                                                  </div>
                                                  <ul className="dropdown-sub-menu background-transparent ml-4">
                                                    {
                                                      groupMessages && groupMessages.length ?
                                                        groupMessages.map(groupMessage => {
                                                          return <li key={groupMessage.userId} className="nav-item">
                                                            <Collapse in={groupId === group.id} >
                                                              <div className={`content-block ${activeUserId === groupMessage.userId ? "active-message-container" : ""}`}>
                                                                <div className={`messages-user-block`} onClick={() => this.getCurrentUserChat(groupMessage)}>
                                                                  <div className="message-user-image" style={{ backgroundImage: `url(${groupMessage.avatarFilePath})` }} />
                                                                  <h3 className="message-user-name">{groupMessage.userName}</h3>
                                                                  {
                                                                    groupMessage.unreadMessagesCount ?
                                                                      <span className="message-unread-count brand-background">{groupMessage.unreadMessagesCount}</span>
                                                                      : null
                                                                  }

                                                                </div>
                                                              </div>
                                                            </Collapse>
                                                          </li>
                                                        })
                                                        : null
                                                    }
                                                  </ul>
                                                </div>
                                              </Collapse>
                                            </li>
                                          })
                                        }
                                      </ul>
                                      : null
                                  }
                                </div>
                              }
                            })
                          }
                        </ul>
                      </div>
                    </div>
                    {
                      // activeUserId || (partnerName && this.state.receiverId.length) ?
                      <div
                        className={`col-lg-6 col-md-12 col-12 mindalay--consultation-tab ${activeUserId || (partnerName && this.state.receiverId.length) ? "" : "disabled"}`}
                        style={{ paddingTop: 5 }}
                      >
                        <hr className="d-md-none" />
                        <div className="tab-pane " id="consultation-contract--messages-tab" aria-labelledby="consultation-contract--messages-tab">
                          {
                            partnerName ?
                              <strong className="consultation-chat-username">{`${partnerName}`}</strong>
                              : <strong className="consultation-chat-username">{`John Smith`}</strong>
                          }
                          <div className="consultation-chat-content-wrapper">
                            <div id="mindalay--consultation-chat" className="consultation-chat-message-container">
                              {
                                messages && messages.length ?
                                  messages.map((mess, index) => {
                                    return mess.receiver ?
                                      <div key={index} className="consultation-chat-message-wrapper consultation-chat-currnet-user">
                                        {/* Sender */}
                                        <div className="consultation-chat-message">
                                          <p className="consultation-chat-message-text word-wrap-break-word">{mess.message}</p>
                                          <span className="consultation-chat-message-date">
                                            {
                                              mess.timeWithoutUtc ?
                                                moment(new Date(mess.time)).format("MMM DD, H:mm") :
                                                moment(MainService.convertUTCDateToLocalDate(new Date(mess.time))).format("MMM DD, H:mm")
                                            }
                                          </span>
                                        </div>
                                      </div>
                                      :
                                      <div key={index} className="consultation-chat-message-wrapper consultant-chat-sender">
                                        {/* Reciver */}
                                        <div className="consultation-chat-message">
                                          <p className="consultation-chat-message-text word-wrap-break-word">{mess.message}</p>
                                          <span className="consultation-chat-message-date">
                                            {
                                              mess.timeWithoutUtc ?
                                                moment(new Date(mess.time)).format("MMM DD, H:mm") :
                                                moment(MainService.convertUTCDateToLocalDate(new Date(mess.time))).format("MMM DD, H:mm")
                                            }
                                          </span>
                                        </div>
                                      </div>
                                  })
                                  : null
                              }
                            </div>
                            {
                              // senderId ?
                              <form onSubmit={this.sendMessage} className={`${senderId ? "" : "disabled"}`}>
                                <div className="consultation-chat-input-wrapper">
                                  <div className="consultation-chat-send-button bg-transparent" id="send-message-button" onClick={this.sendMessage}>
                                    <button className="message-send-button" title={translationService.translate("TR_SEND")}>
                                      <MessageSend />
                                    </button>
                                  </div>
                                  <div className='send-message-input-block'>
                                    <input
                                      type="text"
                                      id="message-input"
                                      className={`form-control ${isInvalidSubmit && (!message || !message.trim()) ? "is-invalid error-bordered" : ""}`}
                                      value={message}
                                      autoComplete="off"
                                      name="message"
                                      placeholder={`${translationService.translate("TR_TYPE")}...`}
                                      onChange={(event) => this.onChange(event, messageMaxLength)}
                                    />
                                  </div>
                                </div>
                              </form>
                              // : null
                            }
                          </div>
                        </div>
                      </div>
                      // : null
                    }
                  </div>
                  : <div className="no-data-container">
                    <div className="no-data-wrapper">
                      <p>{translationService.translate("TR_NO_MESSAGES")}</p>
                      <img src={NoDataImage} alt="/" />
                    </div>
                  </div>
              }
            </div>
          </div>
        </div>
      </Auxiliary> : null
    );
  }
}

const mapStateToProps = state => ({
  translations: state.translation.translations,
  hasUser: state.user.hasUser,
  user: state.user.user,
  language: state.language.language,
  pageSpinners: state.spinner.pageSpinners,
  unreadMessgesCount: state.signalR.unreadMessgesCount,
  currentQuestionMarkData: state.main.currentQuestionMarkData,
  globalConnection: state.signalR.globalConnection
})

const mapDispatchToProps = {
  addPartialViewSpinner,
  removePartialViewSpinner,
  getCurrentQuestionMarkData
}

export default connect(mapStateToProps, mapDispatchToProps)(Messages)

